import React, { Component } from 'react';
import { Button, Col, Row, Select, Tag, Input, Tooltip, Tabs, DatePicker, message } from 'antd'
import { calculate_segment_size, emergency_impact, list_impact, update_impact } from '../Utilities/ExpertSender';
import { base64_encode, base64_decode, generateKey } from '../Utilities/Utilities'
import loading from '../../images/loading3.gif'
import loadingRecalculate from '../../images/loading.gif'
import { CalculatorOutlined, RedoOutlined } from '@ant-design/icons';
import Modal from 'react-modal'
import '../../css/Impact.css'
import moment from 'moment';

class ImpactChecker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            liste_impact: [],
            liste_impact_all: [],
            user_id: base64_decode(localStorage.getItem(base64_encode('user_id'))),
            username: base64_decode(localStorage.getItem(base64_encode('username'))),
            apikey: base64_decode(localStorage.getItem(base64_encode('apikey'))),
            stats_apikey: base64_decode(localStorage.getItem(base64_encode('stats_apikey'))),
            keywords: null,
            displayLoading: 'block',
            displayTable: 'none',
            loadingRecalculate: 'none',
            loadingRefreshImpact: false,
            show_emergency_impact: false,
            show_emergency_update: false,
            show_emergency_events: false,
            start_date: moment().add(0, 'days'),
            end_date: moment().add(0, 'days'),
            loading_emergency_impact: false,
            loading_emergency_update: false,
            loading_emergency_events: false,
            impact_id: null,
            impact_dbb: null,
            impact_file: null,
        }

    }

    componentDidMount() {
        this.getListImpact()
    }

    getListImpact() {
        Promise.resolve(list_impact(this.state.user_id, this.state.apikey)).then(res => {
            console.log(res)
            this.setState({
                liste_impact: res,
                liste_impact_all: res,
                displayLoading: 'none',
                displayTable: 'block',
                loadingRefreshImpact: false
            })
        })
    }

    onFilterDomainList(e) {
        this.setState({ keywords: e.target.value })
        let result = []
        let all = this.state.liste_impact_all
        if (e.target.value !== '') {
            this.state.liste_impact.map((base) => {
                if (base.url.indexOf(e.target.value) > -1) {
                    result.push(base)
                    this.setState({ liste_impact: result })
                }
                return null
            })

        } else {
            this.setState({
                liste_impact: all
            })
        }
    }

    onRecalculateVolume(item, index) {
        let data = this.state.liste_impact
        data[index]['calculate'] = true
        this.setState({ liste_impact: data })
        Promise.resolve(calculate_segment_size(this.state.user_id, this.state.apikey, item['id'], item['abreviation'], item['impact_file'])).then(value => {
            data[index]['calculate'] = false
            data[index]['count'] = value['counting']
            data[index]['count_date'] = value['countingDate']
            data[index]['status'] = value['status']
            this.setState({ liste_impact: data })
        })
    }

    onRefreshImpact() {
        this.setState({ displayLoading: 'block', displayTable: 'none', loadingRefreshImpact: true })
        this.getListImpact()
    }

    renderloading(item) {
        if (item['calculate'] === false) {
            return (
                <span>{item['count']}</span>
            )
        } else {
            return (
                <img src={loadingRecalculate} alt="" style={{ width: '100%' }} />
            )
        }
    }

    onUpdateImpact() {
        this.setState({ loading_emergency_update: true })
        Promise.resolve(update_impact(this.state.user_id, this.state.apikey, this.state.impact_id, this.state.impact_dbb, this.state.impact_file)).then(value => {
            message.success('Request updated has successfully launched', 3)
            window.location.reload()
        })
    }

    onEmergencyUpdateRequested() {
        this.setState({ loading_emergency_impact: true })
        Promise.resolve(emergency_impact(this.state.user_id, this.state.apikey)).then(response => {
            message.success('Request emergency updated has successfully launched', 3)
            window.location.reload()
        })
    }

    onRunEventsExports(){
        // this.setState({loading_emergency_events: true})
        var start = moment(this.state.start_date).format('YYYY-MM-DD').toString()
        var end = moment(this.state.end_date).format('YYYY-MM-DD').toString()
        
    }

    render() {
        const bg = {
            overlay: {
                backgroundColor: "rgba(0, 0, 0, .5)",
            }
        }
        const dateFormat = 'YYYY-MM-DD';
        return (
            <div style={{height: '92vh'}}>
                <div style={{ width: '100%', fontSize: 16, height: '4vh', padding: 0 }}>
                    <Row>
                        <Col span={2} style={{ textAlign: 'center', padding: 5 }}>
                            <span style={{ fontWeight: 600 }}>Search: </span>
                        </Col>
                        <Col span={6}>
                            <Input
                                placeholder='Search'
                                showSearch
                                value={this.state.keywords}
                                size="medium"
                                style={{ width: '100%', color: '#192a56', fontWeight: 700 }}
                                onChange={this.onFilterDomainList.bind(this)}
                            />
                        </Col>
                        <Col span={5} style={{ textAlign: 'center' }}>
                            <Button
                                style={{ width: '80%' }}
                                loading={this.state.loadingRefreshImpact}
                                className='btn btn-primary'
                                onClick={this.onRefreshImpact.bind(this)}
                            >
                                REFRESH
                            </Button>
                        </Col>
                        <Col span={5} style={{ textAlign: 'center' }}>
                            <Button
                                style={{ width: '80%' }}
                                className='btn btn-warning'
                                onClick={() => { this.setState({ show_emergency_events: true }) }}

                            >
                                EMERGENCY EVENTS
                            </Button>
                        </Col>
                        <Col span={5} style={{ textAlign: 'center' }}>
                            <Button
                                style={{ width: '80%' }}
                                className='btn btn-danger'
                                onClick={() => { this.setState({ show_emergency_impact: true }) }}
                            >
                                EMERGENCY IMPACT
                            </Button>
                        </Col>
                    </Row>
                </div>
                <div style={{ width: '100%', fontSize: 16, height: '65vh', marginTop: 5, textAlign: 'center', display: `${this.state.displayLoading}` }}>
                    <img src={loading} style={{ width: 380, height: 350, marginTop: '8%' }} alt="loading ..." />
                </div>
                <div style={{ width: '100%', fontSize: 16, height: '70vh', padding: 5, display: `${this.state.displayTable}`, overflow: 'scroll' }}>
                    <table className="table table-striped" style={{ fontSize: 14, fontFamily: 'Arial', height: '100%', width: '100%' }}>
                        <thead className='dark' style={{ backgroundColor: '#BDB76B' }}>
                            <tr>
                                <th style={{ width: '5%', textAlign: 'center' }}>Id</th>
                                <th style={{ width: '15%', textAlign: 'center' }}>Database</th>
                                <th style={{ width: '5%', textAlign: 'center' }}>File</th>
                                <th style={{ width: '8%', textAlign: 'center' }}>Start</th>
                                <th style={{ width: '8%', textAlign: 'center' }}>End</th>
                                <th style={{ width: '10%', textAlign: 'center' }}>Status</th>
                                <th style={{ width: '5%', textAlign: 'center' }}>SegmentSize</th>
                                <th style={{ width: '8%', textAlign: 'center' }}>CountDate</th>
                                <th style={{ width: '10%', textAlign: 'center' }}>Volume Manager</th>
                                <th style={{ width: '5%', textAlign: 'center' }}>Updated</th>
                                <th style={{ width: '5%', textAlign: 'center' }}>Rejected</th>
                                <th style={{ width: '30%', textAlign: 'center' }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.liste_impact && this.state.liste_impact.map((item, index) => {
                                    var key = generateKey()
                                    var color = item['status'] === 'Finished' ? "cyan" : "geekblue"
                                    return (
                                        <tr key={key}>
                                            <td style={{ textAlign: 'center' }}>{item['id']}</td>
                                            <td style={{ textAlign: 'center' }}>{item['url']}</td>
                                            <td style={{ textAlign: 'center' }}>{item['impact_file']}</td>
                                            <td style={{ textAlign: 'center' }}>{item['start']}</td>
                                            <td style={{ textAlign: 'center' }}>{item['end']}</td>
                                            <td style={{ textAlign: 'center' }}><Tag color={color}>{item['status']}</Tag></td>
                                            <td style={{ textAlign: 'center' }}>{this.renderloading(item)}</td>
                                            <td style={{ textAlign: 'center' }}>{item['count_date']}</td>
                                            <td style={{ textAlign: 'center' }}>{item['volume_manager']}</td>
                                            <td style={{ textAlign: 'center' }}>{item['updated']}</td>
                                            <td style={{ textAlign: 'center' }}>{item['rejected']}</td>
                                            <td style={{ textAlign: 'center' }}>
                                                <Row>
                                                    <Col span={10}>
                                                        <Tooltip title="recalculate" placement="bottom">
                                                            <button
                                                                className="btn btn-secondary-success"
                                                                style={{ border: '1px solid #E0A800', height: 25, width: 25, padding: 0, marginBottom: 10, textAlign: 'center' }}
                                                                onClick={() => {
                                                                    this.onRecalculateVolume(item, index)
                                                                }}
                                                            >
                                                                <CalculatorOutlined style={{ color: '#E0A800', fontSize: 20, marginTop: '0px', verticalAlign: 'baseline' }} />
                                                            </button>
                                                        </Tooltip>
                                                        {/* <Button
                                                            className='btn btn-success'
                                                            <RedoOutlined />
                                                        >
                                                            <CalculatorOutlined style={{ fontSize: 20 }} />
                                                        </Button> */}
                                                    </Col>
                                                    <Col span={10}>
                                                        <Tooltip title="update" placement="bottom">
                                                            <button
                                                                className="btn btn-secondary"
                                                                style={{ border: '1px solid grey', height: 25, width: 25, padding: 0, marginBottom: 10 }}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        show_emergency_update: true,
                                                                        impact_id: item['id'],
                                                                        impact_dbb: item['abreviation'],
                                                                        impact_file: item['impact_file'],
                                                                    })
                                                                }}
                                                            >
                                                                <RedoOutlined style={{ color: '#fff', fontSize: 20, marginTop: '0px', verticalAlign: 'baseline' }} />
                                                            </button>
                                                        </Tooltip>
                                                    </Col>
                                                </Row>

                                            </td>


                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>

                <Modal
                    id="emergency_impact"
                    isOpen={this.state.show_emergency_impact}
                    onRequestClose={() => { this.setState({ show_emergency_impact: false }) }}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ fontSize: '5px !important', marginTop: '0px', marginLeft: '0px', width: '100%' }}>
                        <div
                            style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%' }}
                        >
                            <h3 style={{ color: "#fff", padding: 5 }}>EMERGENCY IMPACT</h3>
                        </div>
                        <span
                            style={{ color: "#C23616", marginTop: '-40px', fontSize: 30, float: 'right', cursor: 'pointer' }} onClick={() => { this.setState({ show_emergency_impact: false }) }}
                        >
                            X
                        </span>
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', padding: 10 }}>
                        <h3>Are you sure to re-update all impact ?</h3>
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', padding: 10 }}>
                        <Row>
                            <Col span={10} style={{ textAlign: 'center' }}>
                                <Button
                                    className='btn btn-secondary'
                                    style={{ width: '80%' }}
                                    onClick={() => { this.setState({ show_emergency_impact: false }) }}
                                >
                                    CANCEL
                                </Button>
                            </Col>
                            <Col span={2}></Col>
                            <Col span={10}>
                                <Button
                                    loading={this.state.loading_emergency_impact}
                                    className='btn btn-danger'
                                    style={{ width: '80%' }}
                                    onClick={this.onEmergencyUpdateRequested.bind(this)}
                                >
                                    UPDATE
                                </Button>
                            </Col>
                        </Row>
                    </div>

                </Modal>

                <Modal
                    id="emergency_impact"
                    isOpen={this.state.show_emergency_update}
                    onRequestClose={() => { this.setState({ show_emergency_update: false }) }}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ fontSize: '5px !important', marginTop: '0px', marginLeft: '0px', width: '100%' }}>
                        <div
                            style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%' }}
                        >
                            <h3 style={{ color: "#fff", padding: 5 }}>IMPACT UPDATED</h3>
                        </div>
                        <span
                            style={{ color: "#C23616", marginTop: '-40px', fontSize: 30, float: 'right', cursor: 'pointer' }} onClick={() => { this.setState({ show_emergency_update: false }) }}
                        >
                            X
                        </span>
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', padding: 10 }}>
                        <h3>Are you sure to update this impact ?</h3>
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', padding: 10 }}>
                        <Row>
                            <Col span={10} style={{ textAlign: 'center' }}>
                                <Button
                                    className='btn btn-secondary'
                                    style={{ width: '80%' }}
                                    onClick={() => { this.setState({ show_emergency_update: false }) }}
                                >
                                    CANCEL
                                </Button>
                            </Col>
                            <Col span={2}></Col>
                            <Col span={10}>
                                <Button
                                    loading={this.state.loading_emergency_update}
                                    className='btn btn-danger'
                                    style={{ width: '80%' }}
                                    onClick={this.onUpdateImpact.bind(this)}
                                >
                                    UPDATE
                                </Button>
                            </Col>
                        </Row>
                    </div>

                </Modal>

                <Modal
                    id="emergency_impact"
                    isOpen={this.state.show_emergency_events}
                    onRequestClose={() => { this.setState({ show_emergency_events: false }) }}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ fontSize: '5px !important', marginTop: '0px', marginLeft: '0px', width: '100%' }}>
                        <div
                            style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%' }}
                        >
                            <h3 style={{ color: "#fff", padding: 5 }}>EMERGENCY EVENTS</h3>
                        </div>
                        <span
                            style={{ color: "#C23616", marginTop: '-40px', fontSize: 30, float: 'right', cursor: 'pointer' }} onClick={() => { this.setState({ show_emergency_events: false }) }}
                        >
                            X
                        </span>
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', padding: 10 }}>
                        <Row>
                            <Col span={12}>
                                <Row>
                                    <Col span={6} style={{padding: 5}}>
                                        <span>Start</span>
                                    </Col>
                                    <Col span={18}>
                                        <DatePicker
                                            format={dateFormat}
                                            style={{ margin: '0 auto', width: '100%', textAlign: 'center' }}
                                            value={this.state.start_date}
                                            onChange={(a, b) => {
                                                this.setState({
                                                    start_date: a,
                                                })
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={6} style={{padding: 5}}>
                                        <span>End</span>
                                    </Col>
                                    <Col span={18}>
                                        <DatePicker
                                            format={dateFormat}
                                            style={{ margin: '0 auto', width: '100%', textAlign: 'center' }}
    
                                            value={this.state.end_date}
                                            onChange={(a, b) => {
                                                this.setState({
                                                    end_date: a,
                                                })
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', padding: 10 }}>
                        <Row>
                            <Col span={10} style={{ textAlign: 'center' }}>
                                <Button
                                    className='btn btn-secondary'
                                    style={{ width: '80%' }}
                                    onClick={() => { this.setState({ show_emergency_events: false }) }}
                                >
                                    CANCEL
                                </Button>
                            </Col>
                            <Col span={2}></Col>
                            <Col span={10}>
                                <Button
                                    loading={this.state.loading_emergency_events}
                                    className='btn btn-danger'
                                    style={{ width: '80%' }}
                                    onClick={this.onRunEventsExports.bind(this)}
                                >
                                    RUN EVENTS EXPORTS
                                </Button>
                            </Col>
                        </Row>
                    </div>

                </Modal>

            </div>
        );
    }
}
export default ImpactChecker;