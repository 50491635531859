import React from 'react';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
    ClearOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    MailOutlined,
    LogoutOutlined,
    AppstoreAddOutlined,
    DeliveredProcedureOutlined,
    SettingOutlined
} from '@ant-design/icons';

import backgroundAvatar from '../../images/backgroundAvatar.png'
import { Button, Layout, Menu } from 'antd';
import '../../css/Home2.css'
import logo from '../../images/logo.png'
import favicon from '../../images/icon.png'
import { base64_decode, base64_encode } from '../Utilities/Utilities';
import { Row, Col, Tooltip, Dropdown } from 'antd';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Switch, Link, Route, withRouter } from 'react-router-dom'
import Task from '../Creativities/Task'
import NewCreativities from '../Creativities/NewCreativities';
import Details from '../Creativities/Details';
import Utility from '../Utility/Utitlity';
import Dashboard from '../DashBoard/Dashboard';
import Minisite from '../Minisite/Minisite';
import Templates from '../Templates/Templates';
import Overview from '../Overview/Overview';
import Cache from '../Utilities/Cache';
import Container from '../Creativities/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Minisitev2 from '../Minisite/Minisitev2';
// import NewCreativitiesv2 from '../Creativities/NewCreativitiesv2';
const { Header, Footer } = Layout

const AnimatedSwitch = withRouter(({ location }) => (
    <TransitionGroup>
        <CSSTransition key={location.key} classNames="slide" timeout={200}>
            <Switch location={location}>
                {/* <ErrorBoundary> */}
                {/* <Route path="/app/creativities/task" component={Task} /> */}
                <Route path="/app/creativities/task" component={Container} />
                <Route path="/app/creativities/new" component={NewCreativities} />
                <Route path="/app/creativities/utilities" component={Utility} />
                {/* <Route exact path="/app/creativities/details/:crea_id" component={DetailCreativities} /> */}
                <Route exact path="/app/creativities/details/:crea_id" component={Details} />

                <Route exact path="/app/creativities/dashboard" component={Dashboard} />
                <Route path="/app/minisite/dashboard" component={Minisite} />
                <Route path="/app/creativities/delivery" component={Overview} />
                <Route path="/app/creativities/templates" component={Templates} />
                <Route path="/app/creativities/cache/clear" component={Cache} />
                <Route path="/app/creativities/minisite" component={Minisitev2} />

                {/* </ErrorBoundary> */}

            </Switch>
        </CSSTransition>
    </TransitionGroup>
));

const { Sider, Content } = Layout;
const { SubMenu } = Menu;

const Home = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [displayuser, setDisplayuser] = useState('block')
    const colorBgContainer = '#eee'
    const [widthImg, setwidthImg] = useState('100px');
    const [heightImg, setheightImg] = useState('105px');
    const [paddingImg, setpaddingImg] = useState('0px');
    const [login, setlogin] = useState(true);
    const history = useHistory();
    const menu = (
        <Menu style={{ textAlign: 'center', color: '#fff', width: '100%', marginTop: '-18px' }}>
            {/* <Menu.Item key="0">
                <span style={{ fontStyle: 'bold' }}>{base64_decode(localStorage.getItem(base64_encode('username')))}</span>
            </Menu.Item> */}
            <Menu.Item key="2">
                <Button
                    style={{ width: '100%' }}
                    className='btn btn-warning'
                    onClick={() => {
                        history.push('/app/creativities/cache/clear')
                    }}
                >
                    CLEAR CACHE
                </Button>
            </Menu.Item>
            <Menu.Item key="1">
                <Button
                    style={{ width: '100%' }}
                    className='btn btn-danger'
                    onClick={() => {
                        localStorage.clear()
                        sessionStorage.clear()
                        setlogin(false)
                        history.push('/')
                    }}
                >
                    LOGOUT
                    {/* <LogoutOutlined style={{ color: '#fff' }} /> */}
                </Button>
            </Menu.Item>

        </Menu>
    );
    useEffect(() => {
        const storedLogin = sessionStorage.getItem(base64_encode('ktksession'));
        if (storedLogin === null) {
            localStorage.clear()
            sessionStorage.clear()
            setlogin(false)
            history.push('/')
        }
    }, []);
    // const {
    //     token: { colorBgContainer },
    // } = theme.useToken();

    function renderIconApp() {
        return (
            <img src={logo} style={{ width: '100%', height: '10%', marginLeft: 15 }} alt="logo" />
        )
    }

    // function logout(){
    //     localStorage.clear()
    //     setlogin(false)
    // }



    return (
        <div style={{ height: '100vh' }}>
            <Layout style={{ height: '100vh' }}>
                <Sider trigger={null} collapsible collapsed={collapsed} style={{ padding: 0 }}>
                    <div style={{ width: '100%', padding: 0, backgroundImage: `url(${backgroundAvatar})`, height: 165 }}>
                        <Row>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <img
                                    src={`data:image/jpeg;base64,${localStorage.getItem(base64_encode('avatar'))}`} alt="avatar"
                                    style={{ width: `${widthImg}`, height: `${heightImg}`, borderRadius: '100%', marginLeft: `${paddingImg}`, marginTop: 25 }}
                                />
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col span={24} style={{ textAlign: 'center', padding: 0 }}>
                                <div style={{ textAlign: 'right', marginRight: 0, padding: 0, marginTop: '-4px', width: '100%', display: 'flex' }}>
                                    <div></div>
                                    <Dropdown overlay={menu}>
                                        <div style={{ width: '100%', padding: '0px 5px' }}>
                                            <Row>
                                                <Col span={24} style={{ textAlign: 'center', display: `${displayuser}` }}>
                                                    <p style={{ color: '#fff', fontSize: 21, marginTop: 15 }}>{base64_decode(localStorage.getItem(base64_encode('username')))}</p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Dropdown>
                                </div>
                            </Col>
                        </Row> */}

                    </div>

                    <div style={{ height: '68%', background: '' }}>
                        <Menu theme="dark" defaultSelectedKeys={['sub1']} mode="inline" defaultOpenKeys={['sub1']}>
                            <SubMenu
                                key="sub1"
                                // icon={<MailOutlined />}
                                title={<span><span style={{ fontFamily: 'Gotham', fontWeight: 600 }}>CREATIVITIES</span></span>}>
                                <Menu.Item key="3">
                                    <Link
                                        to="/app/creativities/task"
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Row>
                                            <Col span={6}><MailOutlined style={{ display: 'contents', fontSize: 18 }} /></Col>
                                            <Col span={18} style={{ padding: 2 }}><span style={{ fontSize: 16 }}>Task</span></Col>
                                        </Row>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="4">
                                    <Link to="/app/creativities/utilities" style={{ textDecoration: 'none' }}>
                                        <Row>
                                            <Col span={6}><AppstoreAddOutlined style={{ display: 'contents', fontSize: 18 }} /></Col>
                                            <Col span={18} style={{ padding: 2 }}><span style={{ fontSize: 16 }}>Utilities</span></Col>
                                        </Row>
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key="5"
                                >
                                    <Link to="/app/creativities/templates" style={{ textDecoration: 'none' }}>
                                        <Row>
                                            <Col span={6}><DeliveredProcedureOutlined style={{ display: 'contents', fontSize: 18 }} /></Col>
                                            <Col span={18} style={{ padding: 2 }}><span style={{ fontSize: 16 }}>Delivery</span></Col>
                                        </Row>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="6">
                                    <Link to="/app/creativities/dashboard" style={{ textDecoration: 'none' }}>
                                        <Row>
                                            <Col span={6}><SettingOutlined style={{ display: 'contents', fontSize: 18 }} /></Col>
                                            <Col span={18} style={{ padding: 2 }}><span style={{ fontSize: 16 }}>Settings</span></Col>
                                        </Row>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="7">
                                    <Link to="/app/creativities/minisite" style={{ textDecoration: 'none' }}>
                                        <Row>
                                            <Col span={6}><SettingOutlined style={{ display: 'contents', fontSize: 18 }} /></Col>
                                            <Col span={18} style={{ padding: 2 }}><span style={{ fontSize: 16 }}>Minisite</span></Col>
                                        </Row>
                                    </Link>
                                </Menu.Item>
                            </SubMenu>
                        </Menu>
                    </div>

                    <div style={{padding: 8}}>
                        <div style={{ textAlign: 'center'}}>
                        <Button
                            style={{ width: '100%' }}
                            className='btn btn-warning'
                            onClick={() => {
                                history.push('/app/creativities/cache/clear')
                            }}
                        >
                            <Row>
                                <Col span={6} style={{textAlign: 'right'}}>
                                    <ClearOutlined style={{padding: 1, fontSize: 16}}/>
                                </Col>
                                <Col span={10} style={{textAlign: 'left'}}>
                                    <span style={{fontSize: 15}}>CLEAR CACHE</span>
                                </Col>
                            </Row>
                        </Button>
                        </div>
                        <div style={{ textAlign: 'center', paddingTop: 10, paddingBottom: 5 }}>
                        <Button
                            style={{ width: '100%' }}
                            className='btn btn-danger'
                            onClick={() => {
                                localStorage.clear()
                                sessionStorage.clear()
                                setlogin(false)
                                history.push('/')
                            }}
                        >
                            <Row>
                                <Col span={6} style={{textAlign: 'right'}}>
                                    <LogoutOutlined style={{padding: 4, fontSize: 14}}/>
                                </Col>
                                <Col span={10} style={{textAlign: 'left'}}>
                                    <span style={{fontSize: 15}}>LOGOUT</span>
                                </Col>
                            </Row>
                        </Button>
                        </div>
                        
                        
                        {renderIconApp()}
                    </div>
                    {/* <br/> */}

                </Sider>
                <Layout className="site-layout">
                    {/* <Header className="site-layout-background" style={{ padding: '5px' }}> */}
                        {/* <div className="logo" style={{ width: '100%', height: 63, marginTop: 0, padding: 0, display: 'block' }}>
                            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                style: { color: '#fff', fontSize: 20, textAlign: 'center' },
                                className: 'trigger',
                                onClick: () => {
                                    setCollapsed(!collapsed)
                                    if (collapsed) {
                                        setwidthImg('120px')
                                        setheightImg('135px')
                                        setpaddingImg('0px')
                                        setDisplayuser('block')
                                    } else {
                                        setwidthImg('40px')
                                        setheightImg('40px')
                                        setpaddingImg('0px')
                                        setDisplayuser('none')
                                    }
                                },
                            })}


                        </div> */}
                        {/* <br />
                        <div style={{ textAlign: 'right', marginLeft: '0px', padding: 0, marginTop: '-120px', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            {renderIconApp()}
                        </div> */}
                    {/* </Header>  */}
                    <Content
                        style={{
                            // margin: '24px 16px',
                            padding: 1,
                            minHeight: 280,
                            background: colorBgContainer,
                        }}
                    >
                        <AnimatedSwitch />

                    </Content>
                    {/* <Footer style={{ padding: '5px', background: '#001529' }}>
                        <div style={{ height: 35 }}>
                            <Row>
                                <Col span={4}></Col>
                                <Col span={4}></Col>
                                <Col span={4}></Col>
                                <Col span={4}></Col>
                                <Col span={4} style={{textAlign: 'right' }}>
                                <Button
                                                style={{ width: '60%' }}
                                                className='btn btn-warning'
                                                onClick={() => {
                                                    history.push('/app/creativities/cache/clear')
                                                }}
                                            >
                                                CLEAR CACHE
                                            </Button>
                                </Col>
                                <Col span={4} style={{textAlign: 'center' }}>
                                    <Button
                                        style={{ width: '60%' }}
                                        className='btn btn-danger'
                                        onClick={() => {
                                            localStorage.clear()
                                            sessionStorage.clear()
                                            setlogin(false)
                                            history.push('/')
                                        }}
                                    >
                                        LOGOUT
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Footer> */}
                </Layout>
            </Layout>
        </div>
    );
}

export default Home;
