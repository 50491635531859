import React, { Component } from 'react'
import { base64_encode, base64_decode } from '../Utilities/Utilities'
import { base_user } from '../Utilities/User'
import { all_base } from '../Utilities/Base'
import { Tabs, Upload, message, Row, Col, Select, Switch, Tooltip, Input, Checkbox, Button, Tag } from 'antd';
import { generateKey } from '../Utilities/Utilities';
import loading from '../../images/loading.gif'
import axios from 'axios';
import { SyncOutlined, InboxOutlined, SendOutlined, LoadingOutlined, CopyOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as config from '../config/config'

import Editor from '@monaco-editor/react';
import ReactSrcDocIframe from 'react-srcdoc-iframe';
import Hach from './Hach';


const { Dragger } = Upload;
const { TabPane } = Tabs;
const { Option } = Select;
const { Search } = Input;
class Utility extends Component {
	constructor(props) {
		super(props)
		this.state = {
			user_id: base64_decode(localStorage.getItem(base64_encode('user_id'))),
			username: base64_decode(localStorage.getItem(base64_encode('username'))),
			apikey: base64_decode(localStorage.getItem(base64_encode('apikey'))),
			statsapikey: base64_decode(localStorage.getItem(base64_encode('stats_apikey'))),
			roles: base64_decode(localStorage.getItem(base64_encode('roles'))),
			liste_base: [],
			def_link_tab: "5",
			base_linkshort: null,
			shorten: null,
			displayLoadingLinkshorter: 'none',
			//image upload
			liste_tracked: [],
			base_upload: [],
			displayLoadingIconDatabase: 'none',
			displayLoadingImageDatabase: 'none',
			displayLoadingUpload: 'none',
			//IMAGE DYNAMIC UPLOAD
			base_dynamic_upload: null,
			code_original: null,
			code_optimized: null,
			displayLoading: 'none',
			displaySubmit: 'block',
			//HTML UTILITIES
			loadingHtmlUtilities: false,
			html_format: false,
			css_comment: false,
			html_comment: false,
			inliner: false,
			html_center: false,
			code_original_utilities: null,
			code_optimized_utilities: null,

		}

	}

	componentDidMount() {
		if (localStorage.getItem(base64_encode('def_link_tab')) === null) {
			localStorage.setItem(base64_encode('def_link_tab'), base64_encode("1"))
			this.setState({
				def_link_tab: "1"
			})
		} else {
			this.setState({
				def_link_tab: base64_decode(localStorage.getItem(base64_encode('def_link_tab')))
			})
		}
		if(this.state.roles === 'Admin'){
			this.getAllBase()
		}else{
			this.getBaseUser()
		}
	}

	getBaseUser() {
		Promise.resolve(base_user(this.state.user_id, this.state.apikey)).then((value) => {
			this.setState({
				liste_base: value
			})
		});
	}

	getAllBase() {
		Promise.resolve(all_base(this.state.user_id, this.state.apikey)).then((value) => {
			this.setState({
				liste_base: value
			})
		});
	}

	changeTab = activeKey => {
		localStorage.setItem(base64_encode('def_link_pref'), base64_encode(activeKey))
		this.setState({
			def_link_tab: activeKey
		})
	}

	onShortsubmit(value) {
		if (value === '' || value === null || this.state.base_linkshort === null) {
			message.warning('Invalid link or missing database')
			return null
		} else {
			this.setState({ displayLoadingLinkshorter: 'block' })
			var fd = new FormData()
			fd.append('user_id', this.state.user_id)
			fd.append('apikey', this.state.apikey)
			fd.append('baseid', this.state.base_linkshort)
			fd.append('link', value)
			axios.post(config.REACT_APP_ENDPOINT_LINK_SHORTER, fd)
				.then(res => {
					this.setState({
						shorten: res.data['url'],
						displayLoadingLinkshorter: 'none'
					})
				})
		}
	}

	onreloadListdatabase() {
		this.setState({
			displayLoadingIconDatabase: 'none',
			displayLoadingImageDatabase: 'block'
		})
		localStorage.removeItem(base64_encode('all_base'))
		localStorage.removeItem(base64_encode('base_user'))
		this.getBaseUser()
		setTimeout(() => {
			this.setState({
				displayLoadingIconDatabase: 'block',
				displayLoadingImageDatabase: 'none',
			})
		}, 2000)
	}

	handleRequest(request) {
		this.setState({ displayLoadingUpload: 'block' })
		if (this.state.base_upload === null) {
			message.error('No selected database for image ' + request.file.name)
			this.setState({ displayLoadingUpload: 'none' })
			return null
		} else {
			var fd = new FormData()
			fd.append('user_id', this.state.user_id)
			fd.append('apikey', this.state.apikey)
			fd.append('baseid', JSON.stringify(this.state.base_upload))
			fd.append('file', request.file)
			fd.append('compress', this.state.compress)
			axios.post(config.REACT_APP_ENDPOINT_IMAGE_UPLOAD, fd)
				.then(res => {
					var data = this.state.liste_tracked
					res.data.map(item => {
						data.push(item)
					})
					this.setState({
						liste_tracked: data,
						displayLoadingUpload: 'none'
					})
				}
				)
		}
	}

	createLigne() {
		return this.state.liste_tracked.map(item => {
			console.log(item)
			var key = generateKey()
			return (
				<tr key={key}>
					<td style={{ textAlign: 'center', padding: '38px' }}>{item['database']}</td>
					<td style={{ textAlign: 'center' }}>
						<img src={item['url']} style={{ maxWidth: 200, maxHeight: 100, marginLeft: 'auto', marginRight: 'auto', width: '40%' }} />
					</td>
					<td style={{ textAlign: 'center', padding: '38px' }}>
						<Row>
							<Col span={20} style={{ textAlign: 'center' }}>
								<span>{item['url']}</span>
							</Col>
							<Col span={4}>
								<Tooltip title="Copy Link" placement="bottom">
									<CopyToClipboard text={item['url']}>
										<CopyOutlined
											style={{ color: 'green', fontSize: 18 }}
											onClick={() => {
												message.info('Link copied to clipboard', 1)
											}}
										/>
									</CopyToClipboard>
								</Tooltip>
							</Col>
						</Row>
					</td>
				</tr>
			)
		})
	}

	onOptimizeDynamicImages(){
        this.setState({
            displaySubmit: 'none',
            displayLoading: 'block'
        })
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        fd.append('baseid', this.state.base_dynamic_upload)
        fd.append('html', base64_encode(this.state.code_original))
        axios.post(config.REACT_APP_ENDPOINT_DYNAMIC_UPLOAD, fd)
        .then(res => {
            this.setState({
                displaySubmit: 'block',
                displayLoading: 'none',
                code_optimized: base64_decode(res.data['code'])
            })

        })
    }

	onHtmlsubmit(){
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        fd.append('html', base64_encode(this.state.code_original_utilities))
        fd.append('html_format', this.state.html_format)
        fd.append('html_comment', this.state.html_comment)
        fd.append('css_comment', this.state.css_comment)
        fd.append('inliner', this.state.inliner)
        fd.append('html_center', this.state.html_center)
        axios.post(config.REACT_APP_ENDPOINT_HTML_FORMATTER, fd)
        .then(res => {
            this.setState({
                code_optimized_utilities: base64_decode(res.data['code'])
            })
        })
    }


	render() {
		return (
			<div>
				<Tabs defaultActiveKey={this.state.def_link_tab} onChange={this.changeTab}>
					<TabPane tab={<Tag color='magenta'>LINKSHORTER</Tag>} key="1" >
						<div style={{ height: '93.2vh', background: '#002553'}}>
							<Row style={{ paddingTop: 50 }}>
								<Col span={24} style={{ textAlign: 'center' }}>
									<span style={{ color: '#fff', fontWeight: 800, fontSize: 50 }}>URL Shortener</span>
								</Col>
								<Col span={24} style={{ textAlign: 'center' }}>
									<h2 style={{ color: '#fff', fontWeight: 300, fontFamily: 'inherit', marginTop: '-1%' }}>Track your links / linkshort dynamic images </h2>
								</Col>
							</Row>
							<br />
							<Row style={{ width: '35%', height: 40, margin: '0 auto' }}>
								<Col span={16}>
									<Select
										size="medium"
										showSearch
										optionFilterProp="children"
										style={{ width: 380, height: 30, color: '#192a56', fontWeight: 700, textAlign: 'center' }}
										defaultValue={this.state.base_linkshort}
										onChange={
											(value) => {
												this.setState({
													base_linkshort: value
												})
											}
										}
									>
										{
											this.state.liste_base && this.state.liste_base.map(item => {
												var key = generateKey()
												return (
													<Option key={key} value={item.id}>{item.basename}</Option>
												)
											})
										}
									</Select>
								</Col>
								<Col span={3} style={{ paddingTop: 3, textAlign: 'center' }}>
									<Switch
										defaultChecked={false}
										onChange={
											(e) => {
												if (e) {
													this.getAllBase()
												} else {
													this.getBaseUser()
												}
											}
										}
									/>
								</Col>
								<Col span={4} style={{ paddingTop: 3, textAlign: 'center' }}>
									<p style={{ color: "#fff" }}>Show all</p>
								</Col>
							</Row>
							<br />
							<Row style={{ width: '70%', margin: '0 auto' }}>
								<Search
									placeholder="Input a link to be tracked (image / hyperlinks)"
									enterButton="Short"
									size="large"
									onSearch={this.onShortsubmit.bind(this)}
								/>
							</Row>
							<br />
							<Row style={{ width: '100%' }}>
								<Col span={24} style={{ textAlign: 'center' }}>
									<p style={{ color: '#fff', fontSize: 16 }}>{this.state.shorten}</p>
								</Col>
							</Row>
							<img
								src={loading}
								alt="loading ..."
								style={{
									width: 200,
									marginLeft: '43%',
									display: `${this.state.displayLoadingLinkshorter}`
								}}
							/>
						</div>
					</TabPane>

					<TabPane tab={<Tag color='volcano'>IMAGE UPLOAD</Tag>} key="2">
						<Row>
							<Col span={9}>
							<br />
								<Row>
									<Col span={3} style={{ padding: 3, textAlign: 'center' }}>
										<p>Compress</p>
									</Col>
									<Col span={2}>
										<Switch
											defaultChecked={this.state.compress}
											value={this.state.compress}
											onChange={
												(e) => {
													this.setState({ compress: e })
												}
											}
										/>
									</Col>
								</Row>
								<Row>
									<Col span={3} style={{ padding: 3, textAlign: 'center' }}>
										<p>Base(*)</p>
									</Col>
									<Col span={11}>
										<Select
											mode="multiple"
											showSearch
											size="medium"
											optionFilterProp="children"
											style={{ width: '100%', height: 30, color: '#192a56', fontWeight: 700, textAlign: 'center' }}
											defaultValue={this.state.base_upload}
											onChange={
												(value) => {
													this.setState({
														base_upload: value
													})
												}
											}
										>
											{
												this.state.liste_base && this.state.liste_base.map(item => {
													var key = generateKey()
													return (
														<Option key={key} value={item.id}>{item.basename}</Option>
													)
												})
											}
										</Select>
									</Col>
									<Col span={3} style={{ textAlign: 'center' }}>
										<label style={{ padding: 3 }}>MyDBs</label>
									</Col>
									<Col span={2}>
										<Switch
											defaultChecked={false}
											onChange={
												(e) => {
													if (e) {
														this.getAllBase()
													} else {
														this.getBaseUser()
													}
												}
											}
										/>
									</Col>
									<Col span={2}>
										<label style={{ padding: 3 }}>All</label>
									</Col>
									<Col span={1} style={{ textAlign: 'left' }}>
										<div style={{ display: `${this.state.displayLoadingIconDatabase}` }}>
											<Tooltip title="Refresh" placement="right">
												<SyncOutlined
													style={{ fontSize: '18px', color: '#23bcbe' }}
													onClick={
														this.onreloadListdatabase.bind(this)
													}
												/>
											</Tooltip>
										</div >

										<div style={{ display: `${this.state.displayLoadingImageDatabase}` }}>
											<img
												src={loading}
												style={{ width: 25, height: 25 }}
											/>
										</div>
									</Col>
								</Row>
								<br />
								<br />
								<br />
								<br />

								<Row>
									<div style={{ width: '100%', height: 500 }}>
										<Dragger
											showUploadList={false}
											multiple={true}
											maxCount={25}
											customRequest={this.handleRequest.bind(this)}
											name="file"
										>
											<p className="ant-upload-drag-icon" style={{ marginTop: '-80px' }}>
												<InboxOutlined style={{ fontSize: 95 }} />
											</p>
											<p className="ant-upload-text">Click or drag file to this area to upload</p>
											<p className="ant-upload-hint">
												You can upload multiple files on multiple database :)
											</p>
											<div style={{ textAlign: 'center', width: '50%', margin: '0 auto' }}>
												<img src={loading} style={{ display: `${this.state.displayLoadingUpload}` }} />
											</div>
										</Dragger>
									</div>
								</Row>
							</Col>
							<Col span={15}>
								<div style={{ height: '92vh', overflow: 'scroll', overflowX: 'hidden'}}>
									<table className="table table-bordered" border="1">
										<thead className="bg-dark" style={{ color: '#fff' }}>
											<tr>
												<th style={{ textAlign: 'center', width: '25%' }}>Database</th>
												<th style={{ textAlign: 'center', width: '20%' }}>Images</th>
												<th style={{ textAlign: 'center', width: '70%' }}>Url</th>
											</tr>
										</thead>

										<tbody>
											{
												this.createLigne()
											}
										</tbody>
									</table>
								</div>
							</Col>
						</Row>

					</TabPane>

					<TabPane tab={<Tag color='orange'>IMAGE DYNAMIC UPLOAD</Tag>} key="3">
                        <Row style={{width: '35%', height: 40, margin: '0 auto'}}>
                            <Col span={3}><span>Database</span></Col>
                            <Col span={13}>
                                <Select
                                    size="medium"
                                    showSearch
                                    optionFilterProp="children"
                                    style={{ width: '100%', height: 30, color: '#192a56', fontWeight: 700, textAlign: 'center' }}
                                    defaultValue={this.state.base_dynamic_upload}
                                    onChange={
                                        (value) => {
                                            this.setState({
                                                base_dynamic_upload: value
                                            })
                                        }
                                    }
                                >
                                    {
                                        this.state.liste_base && this.state.liste_base.map(item => {
                                            var key = generateKey()
                                            return(
                                                <Option key={key} value={item.id}>{item.basename}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col span={3} style={{paddingTop: 3, textAlign: 'center'}}>
                                <Switch
                                    defaultChecked={false}
                                    onChange={
                                        (e) => {
                                            if(e){
                                                this.getAllBase()
                                            }else{
                                                this.getBaseUser()
                                            }
                                        } 
                                    }
                                />
                            </Col>
                            <Col span={4} style={{paddingTop: 3}}>
                                <p>Show all</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={10}>
                                {/* <AceEditor
                                    mode="html"
                                    theme="eclipse"
                                    placeholder="Paste code here"
                                    style={{ width: '100%', height: '74vh', border: '1px solid gray', fontSize: 6, marginTop: '0px' }}
                                    value={this.state.code_original}
                                    onChange={(value) => {
                                        this.setState({ code_original: value })
                                    }}
                                    setOptions={{
                                        displayIndentGuides: false,
                                        enableBasicAutocompletion: true,
                                        enableLiveAutocompletion: true,
                                        enableSnippets: true,
                                        wrap: true,
                                        fontSize: 11
                                    }}
                                /> */}
								 <Editor
                                        height="88vh"
                                        defaultLanguage="html"
                                        theme="vs-dark"
                                        defaultValue={this.state.code_original}
                                        style={{ width: '100%', border: '1px solid gray', marginTop: '0px', fontSize: 6 }}
                                        onChange={(value) => {
                                            this.setState({ code_original: value })
                                        }}
                                    />

                            </Col>
                            <Col span={3}>
                                <div style={{textAlign: 'center', paddingTop: 250, display : `${this.state.displaySubmit}`}}>
                                    <Tooltip title="optimize" placement="bottom">
                                        <SendOutlined 
                                            style={{fontSize: 130, color: 'green'}}
                                            onClick={this.onOptimizeDynamicImages.bind(this)}    
                                        />
                                    </Tooltip>
                                </div>
                                <div style={{textAlign: 'center', paddingTop: 250, display : `${this.state.displayLoading}`}}>
                                        <LoadingOutlined 
                                            style={{fontSize: 130, color: 'green'}}
                                            onClick={() => {
                                                console.log('object')
                                            }}    
                                        />
                                </div>
                            </Col>
                            <Col span={11}>
                                {/* <AceEditor
                                    mode="html"
                                    theme="eclipse"
                                    placeholder="Paste code here"
                                    style={{ width: '98%', height: '74vh', border: '1px solid gray', fontSize: 6, marginTop: '0px' }}
                                    value={this.state.code_optimized}
                                    onChange={(value) => {
                                        this.setState({ code_optimized: value })
                                    }}
                                    setOptions={{
                                        displayIndentGuides: false,
                                        enableBasicAutocompletion: true,
                                        enableLiveAutocompletion: true,
                                        enableSnippets: true,
                                        wrap: true,
                                        fontSize: 11
                                    }}
                                /> */}
								<Editor
                                        height="88vh"
                                        defaultLanguage="html"
                                        theme="vs-dark"
                                        value={this.state.code_optimized}
                                        style={{ width: '100%', border: '1px solid gray', marginTop: '0px', fontSize: 6 }}
                                        onChange={(value) => {
                                            this.setState({ code_optimized: value })
                                        }}
                                    />
                            </Col>
                        </Row>
                    </TabPane>

					<TabPane tab={<Tag color='blue'>HTML UTILITIES</Tag>} key="4">
                        <Row>
                            <Col span={4} style={{textAlign: 'center', padding: 10}}>
                                <Checkbox
                                    checked={this.state.html_format}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            this.setState({ html_format: e.target.checked })
                                        } else {
                                            this.setState({ html_format: e.target.checked })
                                        }
                                    }}
                                >
                                        <span style={{ fontSize: 16, fontWeight: 400 }}>HTML FORMAT</span>
                                </Checkbox>
                            </Col>
                            <Col span={4} style={{textAlign: 'center', padding: 10}}>
                                <Checkbox
                                    checked={this.state.css_comment}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            this.setState({ css_comment: e.target.checked })
                                        } else {
                                            this.setState({ css_comment: e.target.checked })
                                        }
                                    }}
                                >
                                        <span style={{ fontSize: 16, fontWeight: 400 }}>REMOVE CSS COMMENT</span>
                                </Checkbox>
                            </Col>
                            <Col span={4} style={{textAlign: 'center', padding: 10}}>
                                <Checkbox
                                    checked={this.state.html_comment}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            this.setState({ html_comment: e.target.checked })
                                        } else {
                                            this.setState({ html_comment: e.target.checked })
                                        }
                                    }}
                                >
                                        <span style={{ fontSize: 16 , fontWeight: 400}}>REMOVE HTML COMMENT</span>
                                </Checkbox>
                            </Col>
                            <Col span={4} style={{textAlign: 'center', padding: 10}}>
                                <Checkbox
                                    checked={this.state.inliner}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            this.setState({ inliner: e.target.checked })
                                        } else {
                                            this.setState({ inliner: e.target.checked })
                                        }
                                    }}
                                >
                                        <span style={{ fontSize: 16 , fontWeight: 400}}>INLINER</span>
                                </Checkbox>
                            </Col>
                            <Col span={4} style={{textAlign: 'center', padding: 10}}>
                                <Checkbox
                                    checked={this.state.html_center}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            this.setState({ html_center: e.target.checked })
                                        } else {
                                            this.setState({ html_center: e.target.checked })
                                        }
                                    }}
                                >
                                        <span style={{ fontSize: 16, fontWeight: 400 }}>CENTER HTML</span>
                                </Checkbox>
                            </Col>
                            <Col span={4} style={{textAlign: 'center', padding: 10}}>
                                <Button
                                    loading={this.state.loadingHtmlUtilities}
                                    className="btn btn-success"
                                    style={{width: '60%', fontSize: 16}}
                                    onClick={this.onHtmlsubmit.bind(this)}
                                >
                                    SUBMIT
                                </Button>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col span={7}>
                                <Row>
                                    <Col span={23} style={{textAlign: 'center', fontSize: 24, color: '#fff', fontWeight: 600, background: '#001529'}}>
                                        <span>INPUT</span>
                                    </Col>
                                </Row>
								<div style={{width: '100%', height: '80vh', overflow: 'scroll'}}>
								<Editor
                                        height="100%"
                                        defaultLanguage="html"
                                        theme="vs-dark"
                                        value={this.state.code_original_utilities}
                                        style={{ width: '50%', border: '1px solid gray', marginTop: '0px', fontSize: 6 }}
                                        onChange={(value) => {
                                            this.setState({ code_original_utilities: value })
                                        }}
                                    />
								</div>
								
                            </Col>
                            <Col span={1}></Col>
							<Col span={7}>
                                <Row>
                                    <Col span={23} style={{textAlign: 'center', fontSize: 24, color: '#fff', fontWeight: 600, background: '#001529'}}>
                                        <span>OUTPUT</span>
                                    </Col>
                                </Row>
								<div style={{width: '100%', height: '75vh', overflow: 'scroll'}}>
								<Editor
                                        height="100%"
                                        defaultLanguage="html"
                                        theme="vs-dark"
                                        value={this.state.code_optimized_utilities}
                                        style={{border: '1px solid gray', marginTop: '0px', fontSize: 6 }}
                                        onChange={(value) => {
                                            this.setState({ code_optimized_utilities: value })
                                        }}
                                    />
								</div>
                                <Row>
                                    <Col span={23} style={{textAlign: 'center', fontSize: 24, color: '#fff', fontWeight: 600, background: '#001529'}}>
                                        <CopyToClipboard text={this.state.code_optimized_utilities}>
                                            <Button
                                                className="btn btn-primary"
                                                style={{marginTop: '0px', height: 38, width: '100%'}}
                                                onClick={ () => {
                                                    message.info('Copied', 1)
                                                }}
                                            >
                                                COPY TO CLIPBOARD
                                            </Button>
                                        </CopyToClipboard>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={1}></Col>
                            <Col span={8}>
                                <Row>
                                    <Col span={24} style={{textAlign: 'center', fontSize: 24, color: '#fff', fontWeight: 600, background: '#001529'}}>
                                        <span>PREVIEW</span>
                                    </Col>
                                </Row>
                                <ReactSrcDocIframe 
                                    className="embed-responsive-item" srcDoc={this.state.code_optimized_utilities} 
                                    style={{width: '95.8%', height: '67vh', marginTop: '-1px', marginLeft: '0px', overflow: 'scroll'}}
                                />
                            </Col>
                        </Row>
                    </TabPane>

					<TabPane tab={<Tag color='geekblue'>HASH FILES</Tag>} key="5">
                        <Hach userid={this.state.user_id} apikey={this.state.apikey}/>
                    </TabPane>



				</Tabs>
			</div>
		)
	}
}


export default Utility