import React, { Component } from 'react'
import { cancel_scheduled, message_full_preview, update_scheduled } from '../Utilities/ExpertSender'
import { base64_encode, base64_decode, generateKey } from '../Utilities/Utilities'
import axios from 'axios'
import { Button, Col, Row, Select, Tag, Input, Tooltip, Tabs, DatePicker, message } from 'antd'
import loading from '../../images/loading3.gif'
import { getMainData } from '../Utilities/GetMainData';
import { EditOutlined } from '@ant-design/icons';
import Modal from 'react-modal'
import ReactSrcDocIframe from 'react-srcdoc-iframe';
import Editor from '@monaco-editor/react';
import '../../css/Overview.css'
import { list_footer, list_header, list_segment, list_suppression, list_tags, list_subscriber, list_seedlist } from '../Utilities/Stats'
import moment from 'moment';
import workinprogress from '../../images/workinprogress.gif'
import timezone from '../Utilities/timezone.json'
import * as config from '../config/config'


const { Option } = Select
const { TabPane } = Tabs;
const dateFormat = 'YYYY-MM-DD HH:mm:ss';
const timeformat = 'HH:mm:ss'

class ScheduledOverview extends Component {
    constructor(props) {
        super(props)

        this.state = {
            liste_base: [],
            liste_tags: [],
            liste_all_tags: [],
            liste_segments: [],
            liste_suppressions: [],
            liste_subscriber: [],
            liste_header: [],
            liste_footer: [],
            user_id: base64_decode(localStorage.getItem(base64_encode('user_id'))),
            username: base64_decode(localStorage.getItem(base64_encode('username'))),
            apikey: base64_decode(localStorage.getItem(base64_encode('apikey'))),
            stats_apikey: base64_decode(localStorage.getItem(base64_encode('stats_apikey'))),
            base_display: [],
            show_modal_edit: false,
            show_modal_pending: false,
            message_html_code_preview: null,
            message_html: null,
            message_from_email: null,
            message_reply_email: null,
            message_from_name: null,
            message_reply_name: null,
            message_object: null,
            message_preheader: null,
            message_sending_date: null,
            message_type: null,
            message_tags: [],
            message_tags_id: [],
            message_base_id: null,
            message_draft_id: null,
            message_header_id: null,
            message_footer_id: null,
            message_segment_id: [],
            message_suppression_id: [],
            message_subscriber_id: [],
            message_scheduled_date: null,
            message_scheduled_time: null,
            message_delivery_method: null,
            message_delivery_value: null,
            message_throttling_type: "Manual",
            message_timezone: 'W. Europe Standard Time',
            message_crea_id: null,
            message_useTemplate: false,
            scheduled_campagn_display: [],
            scheduled_campagn: [],
            service_filter: "2",
            loading_submit: false,
            display_request: 'none',
            displayTemplateHeader: 'none',
            displayTemplateFooter: 'none',
            display_request_result: 'none',

            displayThrottlingValue: 'none',
            displayStoValue: 'none',
            keywords: null,
            loadingCancel: false,
            loadingUpdate: false
        }

    }

    componentDidMount() {
        localStorage.setItem('defaulttabcrea', '2')
        this.getTags()
        if (this.props.base_id !== null) {
            this.setState({ base_display: (this.props.base_id).split(',') })
            this.getData((this.props.base_id).split(','))
        }
        else {
            this.getData()
        }
    }

    getTags() {
        Promise.resolve(list_tags(this.state.user_id, this.state.apikey, this.state.stats_apikey)).then(res => {
            var all_tag = []
            res.map(item => {
                all_tag.push(item['tag'])
            })
            this.setState({ liste_tags: all_tag, liste_all_tags: res })
        })
    }

    getSegemnts(base_id, segname) {
        Promise.resolve(list_segment(this.state.user_id, this.state.apikey, this.state.stats_apikey, base_id)).then(res => {
            var segment_id = []
            segname.map(item => {
                if (item.length !== 0) {
                    const regex = /\[(\d+)\]/g;
                    const seg = item.match(regex);
                    const seg_id = seg.map(match => match.match(/\d+/)[0]);
                    segment_id.push(seg_id[0])
                }
            })

            this.setState({
                liste_segments: res,
                message_segment_id: segment_id
            })

        })
    }


    getSuppressions(base_id, suppname) {
        Promise.resolve(list_suppression(this.state.user_id, this.state.apikey, this.state.stats_apikey, base_id)).then(res => {
            var supp_id = []
            res.map(item => {
                suppname.map(elem => {
                    if (elem.length !== 0) {
                        var supp = elem.match(/\[\d+\] (.*)/)[1]
                        if (item['Name'] === supp) {
                            supp_id.push((item['Id']))
                        }
                    }
                })
            })
            this.setState({
                liste_suppressions: res,
                message_suppression_id: supp_id
            })
        })
    }

    getSubscriberLists(base_id, suppname) {
        Promise.resolve(list_subscriber(this.state.user_id, this.state.apikey, this.state.stats_apikey, base_id)).then(res => {
            var subscriber_id = []
            res.map(item => {
                suppname.map(elem => {
                    if (elem.length !== 0) {
                        var supp = elem.match(/\[\d+\] (.*)/)[1]
                        if (item['Name'] === supp) {
                            subscriber_id.push(item['id'])
                        }
                    }
                })
            })
            this.setState({
                liste_subscriber: res,
                message_subscriber_id: subscriber_id
            })
        })
    }

    getFooter(base_id, basename) {
        Promise.resolve(list_footer(this.state.user_id, this.state.apikey, this.state.stats_apikey, base_id, basename)).then(res => {
            this.setState({
                liste_footer: res,
            })

        })
    }

    getHeader(base_id, basename) {
        Promise.resolve(list_header(this.state.user_id, this.state.apikey, this.state.stats_apikey, base_id, basename)).then(res => {
            this.setState({
                liste_header: res,
            })

        })
    }

    getData(base_id) {
        Promise.resolve(getMainData(this.state.user_id, this.state.apikey, this.state.stats_apikey, 0, false, false, true, false, false, false, false, false, false, false)).then((value) => {
            var default_view = []
            var base_to_display = []
            value['base_user'].map(item => {
                if (item['login'] !== null && item['mdp'] !== null && item['service'] !== null && parseInt(item['router']) === 1) {
                    base_to_display.push(item)
                    default_view.push(item['id'])
                }
            })
            if (base_id === null || base_id === undefined) {
                //this.getAllCampagnedScheduled(default_view)
                this.setState({
                    liste_base: base_to_display,
                    // base_display: default_view
                })
            } else {
                this.getAllCampagnedScheduled(base_id)
                this.setState({
                    liste_base: base_to_display,
                    // base_display: base_id
                })
            }

        });
    }

    getAllCampagnedScheduled(base_id) {
        this.setState({ loading_submit: true, display_request: 'block', display_request_result: 'none' })
        var fd = new FormData()
        fd.append('user_id', base64_decode(localStorage.getItem(base64_encode('user_id'))))
        fd.append('apikey', base64_decode(localStorage.getItem(base64_encode('apikey'))))
        fd.append('base_id', JSON.stringify(base_id))
        axios.post(config.REACT_APP_ENDPOINT_SCHEDULED_OVERVIEW, fd)
            .then(res => {
                this.setState({
                    loading_submit: false,
                    scheduled_campagn: res.data,
                    scheduled_campagn_display: res.data,
                    display_request: 'none',
                    display_request_result: 'block',
                })
            })
    }

    renderCampagneType(message, color) {
        if (message['type'] === 'Newsletters') {
            return (
                <div style={{ textAlign: 'center' }}>
                    <Tag color={color}>{message['type']}</Tag><br></br>
                    <span style={{ fontWeight: 500 }}>{message['ScheduledOn']}</span>
                </div>
            )
        } else {
            return (
                <div style={{ textAlign: 'center' }}>
                    <Tag color={color}>{message['type']}</Tag>
                </div>
            )
        }
    }

    onRefreshInfoCampagn(item, i) {
        var old = this.state.scheduled_campagn
        console.log(i)
    }

    onFilterDomainList(e) {
        this.setState({ keywords: e.target.value })
        let result = []
        if (e.target.value !== '') {
            this.state.scheduled_campagn.map((base) => {
                if (base.basename.indexOf(e.target.value) > -1) {
                    result.push(base)
                    this.setState({ scheduled_campagn: result })
                }
                return null
            })
        } else {
            var data = this.state.scheduled_campagn_display
            this.setState({
                scheduled_campagn: data
            })
        }
    }

    onClickEdit(campagn) {
        var date_scheduled = null
        if (campagn['Type'] === 'Newsletters') {
            if (campagn['date_scheduled'] === undefined) {
                date_scheduled = moment(campagn['ScheduledOn'], "YYYY-MM-DD hh a").format("YYYY-MM-DD HH:mm:ss")
            } else {
                date_scheduled = moment(campagn['date_scheduled'])
            }
        }
        this.getHeader(campagn['baseid'], campagn['basename'])
        this.getFooter(campagn['baseid'], campagn['basename'])
        this.getSegemnts(campagn['baseid'], campagn['segment'])
        this.getSuppressions(campagn['baseid'], campagn['suppression'])
        this.getSubscriberLists(campagn['baseid'], campagn['subscriber'])
        this.setState({
            message_crea_id: campagn['crea_id'],
            show_modal_edit: true,
            message_from_email: campagn['fromEmail'],
            message_reply_email: campagn['ReplyEmail'],
            message_from_name: campagn['sender'],
            message_reply_name: campagn['ReplyName'],
            message_object: campagn['Subject'],
            message_preheader: campagn['Preheader'],
            message_sending_date: campagn['ScheduledOn'],
            message_tags: campagn['Tags'],
            message_type: campagn['Type'],
            message_base_id: campagn['baseid'],
            message_tags_id: parseInt(campagn['tags_id']),
            message_draft_id: campagn['Id'],
            message_scheduled_date: moment(date_scheduled),
            displayTemplateHeader: campagn['Header'] === null ? 'none' : 'block',
            displayTemplateFooter: campagn['Footer'] === null ? 'none' : 'block',
            message_header_id: campagn['Header'],
            message_footer_id: campagn['Footer'],
            message_delivery_method: campagn['deliveryMethod'],
            message_delivery_value: campagn['deliveryValue'],
            displayStoValue: campagn['deliveryMethod'] === 'SendTimeOptimization' ? 'block' : 'none',
            displayThrottlingValue: campagn['deliveryMethod'] === 'Throttling' ? 'block' : 'none',
            message_useTemplate: campagn['Header'] === null && campagn['Footer'] === null ? false : true
        })


        Promise.resolve(message_full_preview(this.state.user_id, this.state.apikey, campagn['baseid'], campagn['Id'])).then(res => {
            this.setState({
                message_html_code_preview: res['preview'],
                message_html: res['preview'],
            })
        })
    }

    onClickPending() {
        this.setState({
            show_modal_pending: true,
        })
    }

    onCancelScheduled() {
        this.setState({ loadingCancel: true })
        Promise.resolve(cancel_scheduled(this.state.user_id, this.state.apikey, this.state.message_base_id, this.state.message_draft_id, this.state.message_type, this.state.message_crea_id)).then(res => {
            message.success('Scheduled canceled at the router !!!', 2)
            localStorage.setItem('defaulttabcrea', '3')

            setTimeout(() => {
                this.setState({ loadingCancel: false })
                window.location.reload()
            }, 2000)
        })
    }

    onCloseModalEdit() {
        this.setState({
            show_modal_edit: false,
            message_html_code_preview: null,
            message_html: null,
            message_from_email: null,
            message_reply_email: null,
            message_from_name: null,
            message_reply_name: null,
            message_object: null,
            message_preheader: null,
            message_sending_date: null,
            message_type: null,
            message_tags: [],
            message_base_id: null,
            message_draft_id: null,
            message_header_id: null,
            message_footer_id: null,
            message_segment_id: [],
            message_suppression_id: [],
            message_scheduled_date: null,
            message_scheduled_time: null,
            message_delivery_method: null,
            message_delivery_value: null,
        })
    }

    onCloseModalPending() {
        this.setState({
            show_modal_pending: false
        })
    }

    handleDateChange(date, dateString) {
        this.setState({ message_scheduled_date: date })
    }

    renderBtnAction(campagn) {
        if (campagn['Type'] === 'Newsletters') {
            return (
                <Row>
                    <Col span={24}>
                        <Tooltip title="Edit">
                            <Button
                                className='btn btn-primary'
                                onClick={() => {
                                    this.onClickEdit(campagn)
                                }}
                            >
                                <EditOutlined />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            )
        } else {
            return (
                <Row>
                    <Col span={24}>
                        <Tooltip title="Edit">
                            <Button
                                className='btn btn-primary'
                                onClick={() => {
                                    this.onClickPending()
                                }}
                            >
                                <EditOutlined />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            )
        }
    }

    onUpdateScheduled() {
        this.setState({ loadingUpdate: true })
        var tagsname = ""
        this.state.liste_all_tags.map(item => {
            if (parseInt(this.state.message_tags_id) === item['id']) {
                tagsname = item['tag']
            }
        })
        var data = {
            'user_id': this.state.user_id,
            'apikey': this.state.apikey,
            'stats_apikey': this.state.stats_apikey,
            'crea_id': this.state.message_crea_id,
            'base_id': this.state.message_base_id,
            'fromName': this.state.message_from_name,
            'fromEmail': this.state.message_from_email,
            'replyname': this.state.message_reply_name,
            'replyEmail': this.state.message_reply_email,
            'subject': this.state.message_object,
            'preheader': this.state.message_preheader,
            'header': this.state.message_header_id,
            'footer': this.state.message_footer_id,
            'messageid': this.state.message_draft_id,
            'messagetype': this.state.message_type,
            'segment': this.state.message_segment_id,
            'subscriber': this.state.message_subscriber_id,
            'suppression': this.state.message_suppression_id,
            'html': this.state.message_html,
            'tag': tagsname,
            'tags_id': this.state.message_tags_id,
            'delivery_date': moment(this.state.message_scheduled_date).format(dateFormat).toString(),
            'delivery_method': this.state.message_delivery_method,
            'delivery_value': this.state.message_delivery_value,
            'useTemplateRouter': this.state.message_useTemplate === true ? 1 : 0,
            'timezone': this.state.message_timezone
        }

        Promise.resolve(update_scheduled(data)).then(res => {
            this.onCloseModalEdit()
            this.getAllCampagnedScheduled((this.state.message_base_id).split(','))
            this.setState({ loadingUpdate: false })

        })
    }

    renderDeliveryValue() {
        if (this.state.deliveryMethod === "Throttling") {
            return (
                <Input
                    type="number"
                    min="1"
                    max="24"
                    value={this.state.message_delivery_value}
                    onChange={(e) => { this.setState({ message_delivery_value: e.target.value }) }}
                    style={{ textAlign: 'center' }}
                />
            )
        } else {
            return (
                <Select
                    optionFilterProp="children"
                    showSearch
                    size="medium"
                    style={{ width: '100%', color: '#192a56' }}
                    value={this.state.message_delivery_value}
                    onChange={(value) => {
                        this.setState({
                            message_delivery_value: value
                        })
                    }}
                >
                    <Option key="24 hours" value="24h">24 hours</Option>
                    <Option key="7 days" value="7d">7 days</Option>
                </Select>
            )
        }
    }

    render() {
        const bg = {
            overlay: {
                backgroundColor: "rgba(0, 0, 0, .5)",
            }
        }

        return (
            <div style={{ height: '93vh', overflowX: 'hidden', overflowY: 'auto' }}>
                <div style={{ width: '100%', fontSize: 16, height: '4vh', padding: 5 }}>
                    <Row>
                        <Col span={1} style={{ textAlign: 'center' }}>
                            <span style={{ fontWeight: 600 }}>Search: </span>
                        </Col>
                        <Col span={4}>
                            <Input
                                placeholder='Search'
                                showSearch
                                value={this.state.keywords}
                                size="medium"
                                style={{ width: '92%', color: '#192a56', fontWeight: 700 }}
                                onChange={this.onFilterDomainList.bind(this)}
                            />
                        </Col>
                        <Col span={1}>
                            <span style={{ fontWeight: 600 }}>Database: </span>
                        </Col>
                        <Col span={15}>
                            <Select
                                mode="multiple"
                                size="medium"
                                showSearch
                                optionFilterProp="children"
                                style={{ width: '98%', color: '#192a56', textAlign: 'center' }}
                                defaultValue={this.state.base_display}
                                value={this.state.base_display}
                                onChange={
                                    (value) => {
                                        this.setState({
                                            base_display: value
                                        })
                                    }
                                }
                            >
                                {
                                    this.state.liste_base !== null && this.state.liste_base.map((item) => {
                                        var key = generateKey()
                                        return (
                                            <Option key={key} value={item.id}>{item.basename}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Col>
                        <Col span={2} style={{ textAlign: 'center' }}>
                            <Button
                                loading={this.state.loading_submit}
                                className='btn btn-success'
                                style={{ fontSize: 15, width: '100%' }}
                                onClick={() => {
                                    this.getAllCampagnedScheduled(this.state.base_display)
                                }}
                            >
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </div>
                <div style={{ width: '100%', fontSize: 16, height: '88vh', marginTop: 5, textAlign: 'center', display: `${this.state.display_request}` }}>
                    <img src={loading} style={{ width: 380, height: 350, marginTop: '8%' }} alt="loading ..." />
                </div>
                <div style={{ width: '100%', fontSize: 16, height: '88vh', marginTop: 5, textAlign: 'center', display: `${this.state.display_request_result}`, overflowY: 'auto', overflowX: 'hidden' }}>
                    <table className="table table-striped" style={{ fontSize: 14, fontFamily: 'Arial' }}>
                        <thead className='dark' style={{ backgroundColor: '#BDB76B' }}>
                            <tr>
                                <th style={{ width: '5%', textAlign: 'center' }}>Id</th>
                                <th style={{ width: '5%', textAlign: 'center' }}>Unit</th>
                                <th style={{ width: '5%', textAlign: 'center' }}>Type</th>
                                <th style={{ width: '5%', textAlign: 'center' }}>Tags</th>
                                <th style={{ width: '15%', textAlign: 'center' }}>From</th>
                                <th style={{ width: '15%', textAlign: 'center' }}>Subject</th>
                                <th style={{ width: '15%', textAlign: 'center' }}>Lists</th>
                                <th style={{ textAlign: 'center' }}>Segments</th>
                                <th style={{ width: '5%', textAlign: 'center' }}>ScheduledOn</th>
                                <th style={{ width: '15%', textAlign: 'center' }}>Suppression</th>
                                <th style={{ width: '5%', textAlign: 'center' }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.scheduled_campagn && this.state.scheduled_campagn.map(campagn => {
                                    var key = generateKey()
                                    var type_color = "#003c8f"
                                    if (campagn['Type'] === "Newsletters") { type_color = "processing" }
                                    if (campagn['Type'] === "Recurrings") { type_color = "success" }
                                    if (campagn['Type'] === "Triggers") { type_color = "warning" }
                                    return (
                                        <tr key={key}>
                                            <td style={{ textAlign: 'center' }}>{campagn['Id']}</td>
                                            <td style={{ textAlign: 'center' }}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <Tag color={campagn['color']}>{campagn['basename']}</Tag>
                                                </div>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <Tag color={type_color}>{campagn['Type']}</Tag>
                                                </div>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                {
                                                    this.state.liste_all_tags && this.state.liste_all_tags.map(elem => {
                                                        if (parseInt(campagn['tags_id']) === parseInt(elem['id'])) {
                                                            return (
                                                                <Tag key={elem['id']} color="#142E7B">{elem['tag']}</Tag>
                                                            )
                                                        }
                                                    })

                                                }
                                            </td>

                                            <td style={{ textAlign: 'center' }}>{campagn['sender']}</td>
                                            <td style={{ textAlign: 'center' }}>{campagn['Subject']}</td>
                                            <td style={{ textAlign: 'center' }}>
                                                <ul>
                                                    {
                                                        campagn['subscriber'].map(subscriber => {
                                                            var key = generateKey()
                                                            return (
                                                                <li key={key} style={{ textAlign: 'center', listStyleType: 'none' }}>
                                                                    {subscriber}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <ul>
                                                    {
                                                        campagn['segment'].map(segment => {
                                                            var key3 = generateKey()
                                                            return (
                                                                <li key={key3} style={{ textAlign: 'center', listStyleType: 'none' }}>
                                                                    {segment}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>{campagn['ScheduledOn']}</td>
                                            <td style={{ textAlign: 'center' }}>
                                                <ul>
                                                    {
                                                        campagn['suppression'].map(suppression => {
                                                            var key4 = generateKey()
                                                            return (
                                                                <li key={key4} style={{ textAlign: 'center', listStyleType: 'none' }}>
                                                                    {suppression}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                {this.renderBtnAction(campagn)}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>

                <Modal
                    id="edit"
                    isOpen={this.state.show_modal_edit}
                    onRequestClose={() => { this.onCloseModalEdit.bind(this) }}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%', marginLeft: '0px' }}                        >
                        <h3 style={{ color: "#fff", padding: 5 }}>EDIT CAMPAGN</h3>
                    </div>
                    <span style={{ color: "#C23616", marginTop: '-46px', fontSize: 35, float: 'right', cursor: 'pointer' }} onClick={this.onCloseModalEdit.bind(this)}>X</span>
                    <div>
                        <div>
                            <Row>
                                <Col span={10}>
                                    <div>
                                        <table className='table table-bordered' style={{ fontSize: 14 }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: '15%', textAlign: 'center', padding: 15 }}>Sender:</td>
                                                    <td>
                                                        <Input
                                                            size="medium"
                                                            style={{ width: '100%', margin: '0 auto', fontSize: 15 }}
                                                            value={this.state.message_from_name}
                                                            onChange={(e) => { this.setState({ message_from_name: e.target.value }) }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '15%', textAlign: 'center', padding: 15 }}>FromEmail:</td>
                                                    <td>
                                                        <Input
                                                            size="medium"
                                                            style={{ width: '100%', margin: '0 auto', fontSize: 15 }}
                                                            value={this.state.message_from_email}
                                                            onChange={(e) => { this.setState({ message_from_email: e.target.value }) }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '10%', textAlign: 'center', padding: 15 }}>Reply-To:</td>
                                                    <td>
                                                        <Input
                                                            size="medium"
                                                            style={{ width: '100%', margin: '0 auto', fontSize: 15 }}
                                                            value={this.state.message_reply_name}
                                                            onChange={(e) => { this.setState({ message_reply_name: e.target.value }) }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '10%', textAlign: 'center', padding: 15 }}>Reply-To-Emai:</td>
                                                    <td>
                                                        <Input
                                                            size="medium"
                                                            style={{ width: '100%', margin: '0 auto', fontSize: 15 }}
                                                            value={this.state.message_reply_email}
                                                            onChange={(e) => { this.setState({ message_reply_email: e.target.value }) }}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td style={{ width: '15%', textAlign: 'center' }}>Subject:</td>
                                                    <td>
                                                        <Input
                                                            size="medium"
                                                            style={{ width: '100%', margin: '0 auto', fontSize: 15 }}
                                                            value={this.state.message_object}
                                                            onChange={(e) => { this.setState({ message_object: e.target.value }) }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '15%', textAlign: 'center' }}>Preheader:</td>
                                                    <td>
                                                        <Input
                                                            size="medium"
                                                            style={{ width: '100%', margin: '0 auto', fontSize: 15 }}
                                                            value={this.state.message_preheader}
                                                            onChange={(e) => { this.setState({ message_preheader: e.target.value }) }}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td style={{ width: '15%', textAlign: 'center' }}>Tags:</td>
                                                    <td>
                                                        <Select
                                                            size="medium"
                                                            // mode="multiple"
                                                            placeholder="Select tags"
                                                            style={{ width: '100%', margin: '0 auto', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
                                                            optionFilterProp="children"
                                                            value={this.state.message_tags_id}
                                                            onChange={(value) => { this.setState({ message_tags_id: value }) }}
                                                        >
                                                            {
                                                                this.state.liste_all_tags && this.state.liste_all_tags.map(item => {
                                                                    var key = generateKey()
                                                                    return (
                                                                        <Option key={key} value={item.id}>{item.tag}</Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td style={{ width: '15%', textAlign: 'center' }}>Header:</td>
                                                    <td>
                                                        <Select
                                                            optionFilterProp="children"
                                                            showSearch
                                                            size="medium"
                                                            style={{ width: '100%', color: '#192a56', textAlign: 'center', display: `${this.state.displayTemplateHeader}` }}
                                                            value={this.state.message_header_id}
                                                            onChange={(value) => { this.setState({ message_header_id: value }) }}
                                                        >
                                                            {
                                                                this.state.liste_header && this.state.liste_header.map(item => {
                                                                    var key = generateKey()
                                                                    return (
                                                                        <Option key={key} value={parseInt(item.id)} style={{ color: '#192a56' }}>{item.id}-{item.Name}</Option>
                                                                    )
                                                                })
                                                            }

                                                        </Select>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td style={{ width: '15%', textAlign: 'center' }}>Footer:</td>
                                                    <td>
                                                        <Select
                                                            optionFilterProp="children"
                                                            showSearch
                                                            size="medium"
                                                            style={{ width: '100%', color: '#192a56', textAlign: 'center', display: `${this.state.displayTemplateFooter}` }}
                                                            value={this.state.message_footer_id}
                                                            onChange={(value) => { this.setState({ message_footer_id: value }) }}
                                                        >
                                                            {
                                                                this.state.liste_footer && this.state.liste_footer.map(item => {
                                                                    var key = generateKey()
                                                                    return (
                                                                        <Option key={key} value={parseInt(item.id)} style={{ color: '#192a56' }}>{item.id}-{item.Name}</Option>
                                                                    )
                                                                })
                                                            }

                                                        </Select>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td style={{ width: '15%', textAlign: 'center' }}>Subscribers:</td>
                                                    <td>
                                                        <Select
                                                            mode="multiple"
                                                            optionFilterProp="children"
                                                            showSearch
                                                            size="medium"
                                                            style={{ width: '100%', margin: '0 auto', color: '#192a56', fontWeight: 700 }}
                                                            value={this.state.message_subscriber_id}
                                                            onChange={(value) => {
                                                                this.setState({ message_subscriber_id: value })
                                                            }}
                                                        >
                                                            {
                                                                this.state.liste_subscriber && this.state.liste_subscriber.map(item => {
                                                                    var key = generateKey()
                                                                    return (
                                                                        <Option key={key} value={item.id}>{item.id + ' - ' + item.Name}</Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td style={{ width: '15%', textAlign: 'center' }}>Segments:</td>
                                                    <td>
                                                        <Select
                                                            mode="multiple"
                                                            optionFilterProp="children"
                                                            showSearch
                                                            size="medium"
                                                            style={{ width: '100%', margin: '0 auto', color: '#192a56', fontWeight: 700 }}
                                                            value={this.state.message_segment_id}
                                                            onChange={(value) => {
                                                                this.setState({ message_segment_id: value })
                                                            }}
                                                        >
                                                            {
                                                                this.state.liste_segments && this.state.liste_segments.map(item => {
                                                                    var key = generateKey()
                                                                    return (
                                                                        <Option key={key} value={item.id}>{item.id + ' - ' + item.name}</Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '15%', textAlign: 'center' }}>Suppressions:</td>
                                                    <td>
                                                        <Select
                                                            mode="multiple"
                                                            optionFilterProp="children"
                                                            showSearch
                                                            size="medium"
                                                            style={{ width: '100%', margin: '0 auto', color: '#192a56', fontWeight: 700 }}
                                                            value={this.state.message_suppression_id}
                                                            onChange={(value) => {
                                                                this.setState({ message_suppression_id: value })
                                                            }}
                                                        >
                                                            {
                                                                this.state.liste_suppressions && this.state.liste_suppressions.map(item => {
                                                                    var key = generateKey()
                                                                    return (
                                                                        <Option key={key} value={item.Id}>{item.Id + ' - ' + item.Name}</Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td style={{ width: '15%', textAlign: 'center' }}>Scheduled date:</td>
                                                    <td>
                                                        <Row>
                                                            <Col span={8}>
                                                                <DatePicker
                                                                    showTime
                                                                    format={dateFormat}
                                                                    style={{ width: '100%', textAlign: 'center' }}
                                                                    disabledDate={current => {
                                                                        return current && current.valueOf() < moment().subtract(1, 'days');
                                                                    }}
                                                                    value={this.state.message_scheduled_date}
                                                                    onChange={this.handleDateChange.bind(this)}
                                                                />
                                                            </Col>
                                                            <Col span={16}>
                                                                <Select
                                                                    optionFilterProp="children"
                                                                    showSearch
                                                                    size="medium"
                                                                    style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                                                    value={this.state.message_timezone}
                                                                    onChange={(value) => { this.setState({ message_timezone: value }) }}
                                                                >
                                                                    {
                                                                        timezone && timezone.map(item => {
                                                                            var key = generateKey()
                                                                            return (
                                                                                <Option key={key} value={item['Value']}>{item['Text']}</Option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Select>
                                                            </Col>

                                                        </Row>

                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td style={{ width: '15%', textAlign: 'center' }}>Delivery Method:</td>
                                                    <td>
                                                        <Row>
                                                            <Col span={12}>
                                                                <Select
                                                                    optionFilterProp="children"
                                                                    showSearch
                                                                    size="medium"
                                                                    style={{ width: '92%', color: '#192a56', textAlign: 'center' }}
                                                                    value={this.state.message_delivery_method}
                                                                    onChange={(value) => {
                                                                        if (value === "SendTimeOptimization") {
                                                                            this.setState({ message_delivery_method: value, displayStoValue: 'block', displayThrottlingValue: 'none' })
                                                                        } else {
                                                                            this.setState({ message_delivery_method: value, displayStoValue: 'none', displayThrottlingValue: 'block' })
                                                                        }
                                                                    }}
                                                                >
                                                                    <Option key="1" value="Throttling">Throttling</Option>
                                                                    <Option key="2" value="SendTimeOptimization">Send Time Optimization</Option>
                                                                </Select>
                                                            </Col>

                                                            <Col span={12} style={{ display: `${this.state.displayStoValue}` }}>
                                                                <Select
                                                                    optionFilterProp="children"
                                                                    showSearch
                                                                    size="medium"
                                                                    style={{ width: '100%', color: '#192a56' }}
                                                                    value={this.state.message_delivery_value}
                                                                    onChange={(value) => {
                                                                        this.setState({
                                                                            message_delivery_value: value
                                                                        })
                                                                    }}
                                                                >
                                                                    <Option key="24 hours" value="24h">24 hours</Option>
                                                                    <Option key="7 days" value="7d">7 days</Option>
                                                                </Select>
                                                            </Col>
                                                            <Col span={12} style={{ display: `${this.state.displayThrottlingValue}` }}>
                                                                <Input
                                                                    type="number"
                                                                    min="1"
                                                                    max="24"
                                                                    value={this.state.message_delivery_value}
                                                                    onChange={(e) => { this.setState({ message_delivery_value: e.target.value }) }}
                                                                    style={{ textAlign: 'center' }}
                                                                />
                                                            </Col>
                                                        </Row>

                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                                <Col span={14}>
                                    <div style={{ width: '89%', margin: '0 auto' }}>
                                        <Tabs defaultActiveKey="1" onChange={this.changeTab}>
                                            <TabPane tab="HTML" key="1" style={{ height: '100%' }}>
                                                <div style={{ height: '67vh' }}>
                                                    <ReactSrcDocIframe
                                                        title="preview"
                                                        className="embed-responsive-item" srcDoc={this.state.message_html_code_preview}
                                                        style={{ width: '100%', height: '100%', marginLeft: '0px', overflow: 'scroll', border: 'none' }}
                                                    />
                                                </div>
                                            </TabPane>

                                            <TabPane tab="CODE" key="2" >
                                                <div style={{ height: '67vh' }}>
                                                    <Editor
                                                        height="67vh"
                                                        defaultLanguage="html"
                                                        theme="vs-dark"
                                                        defaultValue={this.state.message_html}
                                                        style={{ width: '100%', border: '1px solid gray', marginTop: '0px' }}
                                                        onChange={(value) => {
                                                            this.setState({ message_html: value })
                                                        }}
                                                    />
                                                </div>
                                            </TabPane>

                                        </Tabs>
                                        <br />
                                        <div>
                                            <Row>
                                                <Col span={6}></Col>
                                                {/* <Col span={2}></Col> */}
                                                <Col span={6} style={{ textAlign: 'left' }}>
                                                    <Button
                                                        loading={this.state.loadingUpdate}
                                                        className='btn btn-success'
                                                        style={{ width: '80%', height: 45 }}
                                                        onClick={this.onUpdateScheduled.bind(this)}

                                                    >UPDATE</Button>
                                                </Col>
                                                <Col span={7} style={{ textAlign: 'right' }}>
                                                    <Button
                                                        className='btn btn-danger'
                                                        style={{ width: '80%', height: 45 }}
                                                        loading={this.state.loadingCancel}
                                                        onClick={this.onCancelScheduled.bind(this)}
                                                    >CANCELED SCHEDULED</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>

                            </Row>

                            {/* <div style={{ height: 60 }}>
                                    
                            </div> */}
                        </div>
                    </div>
                </Modal>
                <Modal
                    id="pending"
                    isOpen={this.state.show_modal_pending}
                    onRequestClose={() => { this.onCloseModalPending.bind(this) }}
                    shouldCloseOnOverlayClick={true}
                    ariaHideApp={false}
                    style={bg}
                >
                    <div style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%', marginLeft: '0px' }}                        >
                        <h3 style={{ color: "#fff", padding: 5 }}>EDIT CAMPAGN</h3>
                    </div>
                    <span style={{ color: "#C23616", marginTop: '-46px', fontSize: 35, float: 'right', cursor: 'pointer' }} onClick={this.onCloseModalPending.bind(this)}>X</span>
                    <div>
                        <div style={{ width: '75%', margin: '0 auto', textAlign: 'center' }}>
                            <span style={{ fontSize: 18 }}>Updating Recurring and Trigger campaigns isn't still not available, we are actually working on it. We will let you know when it's ready ;) </span>
                        </div>
                        <div style={{ width: '75%', height: 80, margin: '0 auto', textAlign: 'center' }}>
                            <img src={workinprogress} alt="Developping features in progress" style={{ width: '50%', height: 200 }} />
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default ScheduledOverview