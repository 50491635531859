import axios from 'axios'
import { base64_decode, base64_encode } from '../Utilities/Utilities';
import * as config from '../config/config';

export async function login_user(user_email, user_password) {
    try {
        var fd = new FormData() 
        fd.append('email', user_email)
        fd.append('mdp', user_password)
        let res = await axios({
            url: config.REACT_APP_ENDPOINT_AUTHENTIFICATION,
            method: 'post',
            timeout: 60000,
            data: fd
        }) 
        console.log(config.REACT_APP_ENDPOINT_AUTHENTIFICATION)
        return res.data
    }
    catch (err) {
        console.error('error authentification ' + err);
        return null
    }
}

export async function check_user(user_id, apikey) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        let res = await axios({
            url: config.REACT_APP_ENDPOINT_CHECK,
            method: 'post',
            timeout: 20000,
            data: fd
        })
        return res.data
    }
    catch (err) {
        console.error(err);
    }
}

export async function base_user(user_id, apikey) {
    try {
        console.log(localStorage.getItem(base64_encode('user_id')))
        if(localStorage.getItem(base64_encode('base_user')) !== undefined && localStorage.getItem(base64_encode('base_user')) !== null){
            console.log('here')
            return JSON.parse(base64_decode(localStorage.getItem(base64_encode('base_user'))))
        }else{
            console.log('here 2' )
            var fd = new FormData() 
            fd.append('user_id', user_id)
            fd.append('apikey', apikey)
            console.log(user_id, apikey)
            let res = await axios({
                url: config.REACT_APP_ENDPOINT_USER_BASE,
                method: 'post',
                timeout: 20000,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: fd
            })
            let field = 'basename'
            var list_base_user = (res.data).sort((a, b) => (a[field] || "").toString().localeCompare((b[field] || "").toString()));
            localStorage.setItem(base64_encode('base_user'), base64_encode(JSON.stringify(list_base_user)))
            return list_base_user
        }
    }
    catch (err) {
        console.error('eror getting user base ' + err);
        return null
    }
}

export async function crea_user(user_id, apikey) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        let res = await axios({
            url: config.REACT_APP_ENDPOINT_USER_CREA,
            method: 'post',
            timeout: 20000,
            data: fd
        })
        return res.data
    }
    catch (err) {
        console.error(err);
    }
}

export async function list_user(user_id, apikey) {
    try {
        if (localStorage.getItem(base64_encode('all_user')) !== null) {
            return JSON.parse(base64_decode(localStorage.getItem(base64_encode('all_user'))))
        }else{
            var fd = new FormData() 
            fd.append('user_id', user_id)
            fd.append('apikey', apikey)
            let res = await axios({
                url: config.REACT_APP_ENDPOINT_USER_LIST,
                method: 'post',
                timeout: 20000,
                // headers: {
                //     'Content-Type': 'application/json',
                // },
                data: fd
            })
            let field = 'username'
            var list_all_user = (res.data).sort((a, b) => (a[field] || "").toString().localeCompare((b[field] || "").toString()));
            localStorage.setItem(base64_encode('all_user'), base64_encode(JSON.stringify(list_all_user)))
            return res.data
        }
    }
    catch (err) {
        console.error(err);
        return []
    }
}



