
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { base64_encode, base64_decode, generateKey } from '../Utilities/Utilities';
import { Col, Row, Button, Select, Input, DatePicker, Radio, Alert, message, Tag } from 'antd';
import Modal from 'react-modal'
import '../../css/TaskCreativities.css'
import { getMainData } from '../Utilities/GetMainData';
import { base_user } from '../Utilities/User';
import { liste_crea } from '../Utilities/Crea';
import moment from 'moment';
import { list_model, list_seedlist } from '../Utilities/Stats';
import axios from 'axios';
import loading from '../../images/loading3.gif'
import TextArea from 'antd/lib/input/TextArea';
import * as config from '../config/config';

const { Option } = Select
const dateFormat = "YYYY-MM-DD";

class Bat extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user_id: base64_decode(localStorage.getItem(base64_encode('user_id'))),
			apikey: base64_decode(localStorage.getItem(base64_encode('apikey'))),
			stats_apikey: base64_decode(localStorage.getItem(base64_encode('stats_apikey'))),
			loadingrefresh: false,
			loadingSendBat: false,
			display_loading: 'none',
			display_bat: 'none',
			showNew: false,
			liste_all_base: [],
			liste_base_user: [],
			liste_creativities: [],
			liste_creativities_display: [],
			liste_model: [],
			liste_seedlist: [],
			liste_bat: [],
			bat_scheduled_date: null,
			bat_crea_id: null,
			bat_models: null,
			bat_volume: null,
			bat_database_id: null,
			bat_message: null,
			bat_model: null,
			bat_payout: null,
			bat_existing_seed: 1,
			bat_seedlist: [],
			bat_emails: null,
			bat_routeur_id: null,
			bat_position: 1,
		}
	}

	componentDidMount() {
		localStorage.setItem('defaulttabcrea', '4')
		this.ongetListeBat()
		this.getDatabaseUser()
		this.getListCreativities()
		this.getListModels()
	}

	ongetListeBat() {
		this.setState({ display_loading: 'block', loadingrefresh: true })
		var fd = new FormData()
		fd.append('user_id', this.state.user_id)
		fd.append('apikey', this.state.apikey)
		axios.post(config.REACT_APP_ENDPOINT_ES_BAT_LISTE, fd)
			.then(res => {
				console.log(res.data)
				this.setState({
					liste_bat: res.data,
					display_bat: 'block',
					display_loading: 'none',
					loadingrefresh: false,
					loadingSendBat: false
				})
			})
	}

	onRefreshListBat() {
		this.ongetListeBat()
	}

	getDatabaseUser() {
		Promise.resolve(base_user(this.state.user_id, this.state.apikey)).then((value) => {
			this.setState({
				liste_base_user: value,
				liste_all_base: value
			})
		});
	}

	getListCreativities() {
		Promise.resolve(liste_crea(this.state.user_id, this.state.apikey, 150, true)).then((value) => {
			this.setState({
				liste_creativities: value,
			})
		});
	}

	getListModels() {
		Promise.resolve(list_model(this.state.user_id, this.state.apikey, this.state.stats_apikey)).then((value) => {
			this.setState({
				liste_model: value,
			})
		});
	}

	onCloseNew() {
		this.setState({
			showNew: false,
		})
	}

	onDatabaseChange(value) {
		this.setState({ bat_database_id: value, bat_crea_id: null })
		var display_crea = []
		this.state.liste_creativities.map(item => {
			if (parseInt(item['baseid']) == parseInt(value)) {
				display_crea.push(item)
			}
		})
		Promise.resolve(list_seedlist(this.state.user_id, this.state.apikey, this.state.stats_apikey, value)).then((value) => {
			this.setState({
				liste_seedlist: value
			})
		});
		this.setState({ bat_database_id: value, liste_creativities_display: display_crea })
	}

	onCreativitiesChange(value) {
		this.setState({ bat_crea_id: value })
		this.state.liste_creativities_display.map(item => {
			if (parseInt(value) == parseInt(item['id'])) {
				console.log(item['router_id'])
				var models = JSON.parse(item['models'])[0]
				var date_scheduled = moment(item['scheduledon'], dateFormat)
				this.setState({
					bat_scheduled_date: date_scheduled,
					bat_models: models['modelid'],
					bat_payout: models['payout'],
					bat_routeur_id: item['router_id']
				})
			}
		})
	}

	renderFormSeedlist() {
		if (this.state.bat_existing_seed == 1) {
			return (
				<Row>
					<Col span={3} style={{ padding: 8, textAlign: 'center' }}>
						<span>Seedlist</span>
					</Col>
					<Col span={14}>
						<Select
							optionFilterProp="children"
							showSearch
							mode='multiple'
							size="large"
							style={{ width: '100%', color: '#192a56' }}
							value={this.state.bat_seedlist}
							onChange={(value) => { this.setState({ bat_seedlist: value }) }}
						>
							{
								this.state.liste_seedlist && this.state.liste_seedlist.map(item => {
									var key = generateKey()
									return (
										<Option key={key} value={item.id}>{item.id}-{item.Name}</Option>
									)
								})
							}

						</Select>
					</Col>
				</Row>
			)
		} else {
			return (
				<Row>
					<Col span={3} style={{ padding: 5, textAlign: 'center' }}>
						<span>Seedlist</span>
					</Col>
					<Col span={14}>
						<Input
							size="large"
							type="text"
							style={{ width: '97%', color: '#192a56', fontWeight: 400 }}
							value={this.state.bat_emails}
							onChange={(e) => {
								this.setState({ bat_emails: e.target.value })
							}}
						/>
					</Col>
					<Col span={6} style={{ padding: 0, textAlign: 'center' }}>
						<Alert message="Use comma (,) as separator" type="warning" />
					</Col>
				</Row>
			)
		}
	}

	sendBat() {
		this.setState({ loadingSendBat: true })
		if (this.state.bat_database_id === null || this.state.bat_crea_id === null || this.state.bat_scheduled_date === null || this.state.bat_volume === null || this.state.bat_payout === null || this.state.bat_routeur_id === null) {
			if (this.state.bat_database_id === null) { message.info('DATABASE IS MANDATORY', 1); }
			if (this.state.bat_crea_id === null) { message.info('CHOOSE ONE CREATIVITIES', 1); }
			if (this.state.bat_scheduled_date === null) { message.info('DATE OF SCHEDULED IS MANDATORY', 1); }
			if (this.state.bat_volume === null) { message.info('VOLUME  IS MANDATORY', 1); }
			if (this.state.bat_payout === null) { message.info('PAYOUT  IS MANDATORY', 1); }
			if (this.state.bat_routeur_id === null) { message.info('MESSAGE NOT PRESENT AT ROUTER', 1); }

			this.setState({ loadingSendBat: false })
			return null
		} else {
			if ((this.state.bat_existing_seed == 1 && (this.state.bat_seedlist).length == 0) || (this.state.bat_existing_seed == 0 && this.state.bat_emails === null)) {
				if (this.state.bat_existing_seed == 1 && (this.state.bat_seedlist).length == 0) { message.info('CHOOSE AT LEAST ONE SEEDLIST', 1); }
				if (this.state.bat_existing_seed == 0 && this.state.bat_emails === null) { message.info('NO EMAILS SET', 1); }
				this.setState({ loadingSendBat: false })
				return null
			} else {
				var fd = new FormData()
				var advertiser = null
				var modelname = null
				this.state.liste_creativities_display.map(elem => {
					if (parseInt(elem['id']) == parseInt(this.state.bat_crea_id)) {
						advertiser = elem['creativity']
					}
				})

				this.state.liste_model.map(elem => {
					if (parseInt(elem['id']) == parseInt(this.state.bat_models)) {
						modelname = elem['name']
					}
				})


				if (advertiser === null || advertiser.includes('null') || modelname === null) {
					message.info('ADVERTISER NOT FOUND', 1);
					return null
				} else {
					fd.append('user_id', this.state.user_id)
					fd.append('apikey', this.state.apikey)
					fd.append('base_id', this.state.bat_database_id)
					fd.append('crea_id', this.state.bat_crea_id)
					fd.append('scheduled_date', moment(this.state.bat_scheduled_date).format('YYYY-MM-DD').toString())
					fd.append('model', modelname)
					fd.append('payout', this.state.bat_payout)
					fd.append('volume', this.state.bat_volume)
					fd.append('isSeed', this.state.bat_existing_seed)
					fd.append('seedlist', JSON.stringify(this.state.bat_seedlist))
					fd.append('emaillist', this.state.bat_emails)
					fd.append('router_id', this.state.bat_routeur_id)
					fd.append('advertiser', advertiser)
					fd.append('isSeed', this.state.bat_existing_seed)
					fd.append('emaillist', this.state.bat_emails)
					fd.append('position', this.state.bat_position)
					fd.append('message', this.state.bat_message)

					axios.post(config.REACT_APP_ENDPOINT_ES_BAT_VALIDATION, fd)
						.then(res => {
							setTimeout(() => {
								window.location.reload()
							}, 2000);

						})
				}

			}
		}
	}

	renderStatusProcess(status) {
		var background = ''
		switch (status) {
			case 'Finished':
				background = '#157347'
				break
			case 'Pending':
				background = '#FFCA2C'
				break
			default:
				background = '#FFCA2C'
				break
		}
		return (
			<td style={{ textAlign: 'center' }}>
				<Tag color={background}>{status}</Tag>
			</td>
		)
	}

	renderStatusValidation(status) {
		var background = ''
		switch (status) {
			case 'validate':
				background = '#157347'
				break
			case 'invalidate':
				background = '#BB2D3B'
				break
			case 'Pending':
				background = '#FFCA2C'
				break
			default:
				background = '#FFCA2C'
				break
		}
		return (
			<td style={{ textAlign: 'center' }}>
				<Tag color={background}>{status}</Tag>
			</td>
		)
	}

	render() {
		const bg = {
			overlay: {
				backgroundColor: "rgba(0, 0, 0, .5)",
			}
		}

		return (
			<div style={{ marginTop: '-10px', height: '94vh' }}>
				<Row>
					<Col span={2} style={{ padding: 5 }}>
						<Button
							loading={this.state.loadingrefresh}
							className="btn btn-primary"
							onClick={this.onRefreshListBat.bind(this)}
							style={{ width: '100%', height: 40 }}
						>
							REFRESH
						</Button>
					</Col>
					<Col span={2} style={{ padding: 5 }}>
						<Button
							loading={this.state.loadingrefresh}
							className="btn btn-success"
							style={{ width: '100%', height: 40 }}
							onClick={() => {
								this.setState({
									showNew: true
								})
							}}
						>
							NEW
						</Button>
					</Col>
				</Row>
				<div style={{ textAlign: 'center', margin: '0 auto', width: '100%', height: '80.9vh', overflow: 'scroll', overflowX: 'hidden', overflowY: 'auto', display: `${this.state.display_loading}` }}>
					<img src={loading} style={{ width: 380, height: 350, marginTop: '8%' }} alt="loading ..." />
				</div>
				<div style={{ width: '100%', display: `${this.state.display_bat}` }}>
					<Row style={{ color: '#fff', fontFamily: 'Lato', height: '88vh', overflow: 'scroll', overflowX: 'hidden' }}>
						<table className="table" style={{ fontSize: 14, fontFamily: 'Arial' }}>
							<thead className='bg-dark' style={{ zIndex: 100, color: '#fff' }}>
								<tr>
									<th style={{ textAlign: 'center', width: '5%' }}>Id</th>
									<th style={{ textAlign: 'center' }}>Sent  date</th>
									<th style={{ textAlign: 'center' }}>Creativity</th>
									<th style={{ textAlign: 'center' }}>Message id</th>
									<th style={{ textAlign: 'center' }}>Database</th>
									<th style={{ textAlign: 'center' }}>Process</th>
									<th style={{ textAlign: 'center' }}>Validation</th>


								</tr>
							</thead>
							<tbody>
								{
									this.state.liste_bat && this.state.liste_bat.map(item => {
										var background = '#FFFFCC'
										var title = ""
										if (item['status_validation'] === 'invalidate') {
											background = '#FFCCCC'
											title = item.description
										} else {
											if (item['status_validation'] === 'validate') {
												background = '#CCFFCC'
											}
										}
										return (
											<tr key={item['id']} style={{ backgroundColor: `${background}` }} title={title}>
												<td style={{ textAlign: 'center' }} >{item.id}</td>
												<td style={{ textAlign: 'center' }}>{item.date}</td>
												<td style={{ textAlign: 'center' }}>{item.creativity}</td>
												<td style={{ textAlign: 'center' }}>{item.message_id}</td>
												<td style={{ textAlign: 'center' }}>
													{
														this.state.liste_base_user && this.state.liste_base_user.map(base => {
															if (parseInt(base['id']) == parseInt(item['base_id'])) {
																return (
																	<Tag>{base['basename']}</Tag>
																)
															}
														})
													}
												</td>
												{this.renderStatusProcess(item.status_process)}
												{this.renderStatusValidation(item.status_validation)}
											</tr>
										)
									})
								}
							</tbody>
						</table>
					</Row>
				</div>

				<Modal
					id="newbat"
					isOpen={this.state.showNew}
					onRequestClose={this.onCloseNew.bind(this)}
					shouldCloseOnOverlayClick={true}
					style={bg}
					ariaHideApp={false}
				>
					<div style={{ fontSize: '5px !important', marginTop: '0px', marginLeft: '0px', width: '100%' }}>
						<div
							style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%' }}
						>
							<h3 style={{ color: "#fff", padding: 5 }}>SEND BAT</h3>
						</div>
						<span
							style={{ color: "#C23616", marginTop: '-40px', fontSize: 30, float: 'right', cursor: 'pointer' }} onClick={this.onCloseNew.bind(this)}
						>
							X
						</span>
					</div>
					<div style={{ padding: 15 }}>
						<Row>
							<Col span={24}>
								<Row>
									<Col span={3} style={{ padding: 5, textAlign: 'center' }}>
										<span>Database</span>
									</Col>
									<Col span={6}>
										<Select
											size="medium"
											optionFilterProp="children"
											showSearch
											style={{ width: '100%', margin: '0 auto', color: '#192a56', fontWeight: 700 }}
											value={this.state.bat_database_id}
											onChange={(value) => {
												this.onDatabaseChange(value)
											}}
										>
											{
												this.state.liste_base_user && this.state.liste_base_user.map(item => {
													var key = generateKey()
													return (
														<Option key={key} value={item.id}>{item['basename']}</Option>
													)
												})
											}
										</Select>
									</Col>
									<Col span={3} style={{ padding: 5, textAlign: 'center' }}>
										<span>Scheduled at</span>
									</Col>
									<Col span={5}>
										<DatePicker
											format={dateFormat}
											style={{ margin: '0 auto', width: '100%', textAlign: 'center' }}
											value={this.state.bat_scheduled_date}
											// disabledDate={current => {
											// 	return current && current <= moment().subtract(1, 'days');
											// }}
											onChange={(a, b) => {
												this.setState({
													bat_scheduled_date: a
												})
											}}
										/>
									</Col>
									<Col span={3} style={{ padding: 5, textAlign: 'center' }}>
										<span>Volume</span>
									</Col>
									<Col span={4}>
										<Input
											size="medium"
											type="text"
											style={{ width: '97%', color: '#192a56', fontWeight: 700 }}
											value={this.state.bat_volume}
											onChange={(e) => { this.setState({ bat_volume: e.target.value }) }}
										/>
									</Col>
								</Row>
								<br />
								<Row>
									<Col span={3} style={{ padding: 5, textAlign: 'center' }}>
										<span>Creativities</span>
									</Col>
									<Col span={6}>
										<Select
											size="medium"
											optionFilterProp="children"
											showSearch
											style={{ width: '100%', margin: '0 auto', color: '#192a56', fontWeight: 700 }}
											value={this.state.bat_crea_id}
											onChange={(value) => { this.onCreativitiesChange(value) }}
										>
											{
												this.state.liste_creativities_display && this.state.liste_creativities_display.map(item => {
													return (
														<Option key={item.id} value={item.id}>{item['id'] + ' - ' + item['creativity']}</Option>
													)
												})
											}
										</Select>
									</Col>
									<Col span={3} style={{ padding: 5, textAlign: 'center' }}>
										<span>Model</span>
									</Col>
									<Col span={5}>
										<Select
											size="medium"
											optionFilterProp="children"
											showSearch
											style={{ width: '100%', margin: '0 auto', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
											value={this.state.bat_models}
											onChange={(value) => {
												this.setState({ bat_models: value })
											}}
										>
											{
												this.state.liste_model && this.state.liste_model.map(item => {
													var key = generateKey()
													return (
														<Option key={key} value={item.id}>{item['name']}</Option>
													)
												})
											}
										</Select>
									</Col>
									<Col span={3} style={{ padding: 5, textAlign: 'center' }}>
										<span>Payout</span>
									</Col>
									<Col span={4}>
										<Input
											size="medium"
											type="text"
											style={{ width: '97%', color: '#192a56', fontWeight: 700 }}
											value={this.state.bat_payout}
											onChange={(e) => {
												this.setState({ bat_payout: e.target.value })
											}}
										/>
									</Col>
								</Row>
								<br />
								<Row>
									<Col span={3} style={{ padding: 5, textAlign: 'center' }}>
										<span>Recipients</span>
									</Col>
									<Col span={6} style={{ padding: 4 }}>
										<Radio.Group
											name="radiogroup"
											value={this.state.bat_existing_seed}
											onChange={(e) => { this.setState({ bat_existing_seed: e.target.value }) }}
										>
											<Radio value={1}>Existing</Radio>
											<Radio value={0}>New</Radio>
										</Radio.Group>
									</Col>
									<Col span={3} style={{ padding: 5, textAlign: 'center' }}>
										<span>Position</span>
									</Col>
									<Col span={6} style={{ padding: 4 }}>
										<Radio.Group
											name="radiogroup"
											value={this.state.bat_position}
											onChange={(e) => { this.setState({ bat_position: e.target.value }) }}
										>
											<Radio value={1}>Header</Radio>
											<Radio value={0}>Footer</Radio>
										</Radio.Group>
									</Col>
								</Row>
								<br />
								{
									this.renderFormSeedlist()
								}
								<br />
								<Row>
									<Col span={3} style={{ padding: 15, textAlign: 'center' }}>
										<span>Message</span>
									</Col>
									<Col span={21} style={{ padding: 4 }}>
										<TextArea
											style={{ width: '100%', resize: 'none', color: '#192a56', height: 80}}
											value={this.state.bat_message}
											defaultValue={this.state.bat_message}
											onChange={
												(e) => {
													this.setState({
														bat_message: e.target.value
													})
												}
											}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
						<br /><br />
						<Row>
							<Col span={12} style={{ textAlign: 'center' }}>
								<Button
									className='btn btn-secondary'
									style={{ width: '50%', height: 50 }}
									onClick={this.onCloseNew.bind(this)}
								>
									CANCEL
								</Button>
							</Col>

							<Col span={12} style={{ textAlign: 'center' }}>
								<Button
									loading={this.state.loadingSendBat}
									className='btn btn-success'
									style={{ width: '50%', height: 50 }}
									onClick={this.sendBat.bind(this)}
								>
									SEND BAT
								</Button>
							</Col>
						</Row>
					</div>

				</Modal>
			</div>
		);
	}
}

Bat.propTypes = {

};

export default Bat;