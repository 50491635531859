import axios from 'axios'
import { base64_decode, base64_encode } from './Utilities';
import * as config from '../config/config';

// var user_id = base64_decode(localStorage.getItem(base64_encode('user_id')))
// var apikey = base64_decode(localStorage.getItem(base64_encode('apikey')))

export async function liste_minisite(user_id, apikey) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_MINISITE_LISTE,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error getting liste of minisites ' + err);
        return null
    }
}

export async function check_bimi(user_id, apikey,url) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('domain', url)

        let res = await axios({
            url: config.REACT_APP_ENDPOINT_BIMI_CHECK,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error getting liste of minisites ' + err);
        return null
    }
}

export async function create_minisite(user_id, apikey,data) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('minisite_url', data['m_url'])
        fd.append('minisite_title', data['m_title'])
        fd.append('minisite_header_title', data['m_title'])
        fd.append('minisite_description', data['m_description'])
        fd.append('minisite_analytics', data['m_analytics'])
        fd.append('minisite_cookies', data['m_cookies'])
        fd.append('minisite_sub', data['m_sub_email'])
        fd.append('minisite_unsub', data['m_unsub_email'])
        fd.append('minisite_ftp_host', data['m_host'])
        fd.append('minisite_ftp_user', data['m_user'])
        fd.append('minisite_ftp_mdp', data['m_password'])
        fd.append('minisite_base_id', data['m_base_id'])
        fd.append('minisite_country', data['m_country'])
        fd.append('minisite_catch', data['m_catch'])
        fd.append('minisite_offer_catch', data['m_offer_catch'])
        fd.append('minisite_adding_traffic', data['m_status_adding_traffic'])

        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_MINISITE_CREATE,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error getting liste of minisites ' + err);
        return null
    }
}

export async function update_minisite(user_id, apikey,data) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        
        fd.append('minisite_url', data['m_url'])
        fd.append('minisite_title', data['m_title'])
        fd.append('minisite_header_title', data['m_title'])
        fd.append('minisite_description', data['m_description'])
        fd.append('minisite_analytics', data['m_analytics'])
        fd.append('minisite_cookies', data['m_cookies'])
        fd.append('minisite_sub', data['m_sub_email'])
        fd.append('minisite_unsub', data['m_unsub_email'])
        fd.append('minisite_ftp_host', data['m_host'])
        fd.append('minisite_ftp_user', data['m_user'])
        fd.append('minisite_ftp_mdp', data['m_password'])
        fd.append('minisite_base_id', data['m_base_id'])
        fd.append('minisite_country', data['m_country'])
        fd.append('minisite_catch', data['m_catch'])
        fd.append('minisite_offer_catch', data['m_offer_catch'])
        fd.append('minisite_adding_traffic', data['m_status_adding_traffic'])

        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_MINISITE_UPDATE,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error getting liste of minisites ' + err);
        return null
    }
}

export async function clone_minisite(user_id, apikey,data) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('minisite_url', data['m_url'])
        fd.append('minisite_title', data['m_title'])
        fd.append('minisite_header_title', data['m_title'])
        fd.append('minisite_description', data['m_description'])
        fd.append('minisite_analytics', data['m_analytics'])
        fd.append('minisite_cookies', data['m_cookies'])
        fd.append('minisite_sub', data['m_sub_email'])
        fd.append('minisite_unsub', data['m_unsub_email'])
        fd.append('file_logo', data['m_logo'])
        fd.append('file_banner', data['m_banner'])
        fd.append('file_legal', data['m_legal'])
        fd.append('file_cgu', data['m_cgu'])
        fd.append('file_privacy', data['m_privacy'])
        fd.append('file_cookies', data['m_cookies_policy'])
        fd.append('minisite_ftp_host', data['m_host'])
        fd.append('minisite_ftp_user', data['m_user'])
        fd.append('minisite_ftp_mdp', data['m_password'])
        fd.append('minisite_base_id', data['m_base_id'])
        fd.append('minisite_country', data['m_country'])
        fd.append('minisite_catch', data['m_catch'])
        fd.append('minisite_offer_catch', data['m_offer_catch'])
        fd.append('minisite_clone_base_id', data['m_clone_base_id'])
        fd.append('minisite_adding_legal', data['m_status_adding_legal'])
        fd.append('minisite_adding_cgu', data['m_status_adding_cgu'])
        fd.append('minisite_adding_privacy', data['m_status_adding_privacy'])
        fd.append('minisite_adding_cookies', data['m_status_adding_cookies'])
        fd.append('minisite_adding_traffic', data['m_status_adding_traffic'])

        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_MINISITE_CLONE,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error getting liste of minisites ' + err);
        return null
    }
}

export async function updating_minisite_images(user_id, apikey,data) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('logo', data['logo'])
        fd.append('banner', data['banner'])
        fd.append('bimi', data['bimi'])
        fd.append('base_id', data['base_id'])

        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_MINISITE_UPDATE_IMAGES,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error updating images of  minisites ' + err);
        return null
    }
}

export async function updating_minisite_rgpd(user_id, apikey,data) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('legal', data['legal'])
        fd.append('cgu', data['cgu'])
        fd.append('privacy', data['privacy'])
        fd.append('cookies_policy', data['cookies_policy'])
        fd.append('base_id', data['base_id'])
        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_MINISITE_UPDATE_RGPD,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error updating images of  minisites ' + err);
        return null
    }
}