import React, { Component } from 'react';
import { Col, Row, Select, Tag } from 'antd';
import { Button, message } from 'antd';
import axios from 'axios';
import loading from '../../images/loading.gif'
import {DownloadOutlined} from '@ant-design/icons';
import * as config from '../config/config'


const { Option } = Select
const props = {
    name: 'file',
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    headers: {
        authorization: 'authorization-text',
    },
    onChange(info) {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    progress: {
        strokeColor: {
            '0%': '#108ee9',
            '100%': '#87d068',
        },
        strokeWidth: 3,
        format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
};

class Hach extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_id: props.userid,
            apikey: props.apikey,
            selectedFile: null,
            n_hash: "md5",
            loadingHash: false,
            displayLoading: 'none',
            statusbtn: true,
            liste_hash_files: []
        }
    }

    componentDidMount() {
        this.getAllcryptedFiles()
    }

    getAllcryptedFiles(){
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        axios.post(config.REACT_APP_ENDPOINT_HASH_FILES_LISTE, fd)
        .then(res => {
            this.setState({liste_hash_files: res.data})
        })
    }

    onFileChange = (event) => {
        this.setState({displayLoading: 'block'})
        const file = event.target.files[0];
        this.setState({ selectedFile: file, displayLoading: 'block' });
        setTimeout(() => {
            this.setState({ statusbtn: false, displayLoading: 'none' });
        }, 3000)
    };

    onHashFiles(e){
        e.preventDefault()
        this.setState({loadingHash: true})
        if(this.state.selectedFile === null){
            message.warning('No file selected !!!', 2)
            this.setState({loadingHash: false})
            return null
        }else{
            var fd = new FormData()
            fd.append('user_id', this.state.user_id)
            fd.append('apikey', this.state.apikey)
            fd.append('hash', this.state.n_hash)
            fd.append('selectedfile', this.state.selectedFile)
            axios.post(config.REACT_APP_ENDPOINT_HASH_FILES, fd)
            .then(res => {
                if(res.data['status'] === 'success'){
                    message.success(res.data['description'], 2) 
                }else{
                    message.warning(res.data['description'], 2) 

                }
                this.setState({loadingHash: false, liste_hash_files: []})
                this.getAllcryptedFiles()
            })
        }
    }

    render() {

        return (
            <div style={{ height: '93vh', overflowY: 'auto', padding: 15 }}>
                <Row>
                    <Col span={24} style={{ padding: 5 }}>
                        <div style={{ height: 110, padding: 0}}>
                            <div style={{ width: '100%', height: 40, textAlign: 'center', background: "#001529", color: '#fff', padding: 5 }}>
                                <span style={{ fontSize: 16 }}><strong>MD5 Conversion File</strong></span>
                            </div>
                            <br />
                            <Row>
                                <Col span={2} style={{ textAlign: 'center', padding: 5 }}>
                                    <span>Encoding</span>
                                </Col>
                                <Col span={2}>
                                    <Select
                                        style={{ width: '100%', textAlign: 'center' }}
                                        value={this.state.n_hash}
                                        onChange={(value) => {this.setState({n_hash: value})}}
                                    >
                                        <Option key="1" value="md5">md5</Option>
                                        <Option key="2" value="sha256">sha256</Option>

                                    </Select>
                                </Col>
                            {/* </Row>
                            <br />
                            <Row> */}
                                <Col span={2} style={{ textAlign: 'center', padding: 5 }}>
                                    <span>File</span>
                                </Col>
                                <Col span={4}>
                                    <input
                                        type="file"
                                        accept=".txt,.csv"
                                        onChange={this.onFileChange.bind(this)}
                                    />
                                </Col>
                                <Col span={1} style={{textAlign: 'center'}}>
                                    <img src={loading} alt='' style={{width: 30, height: 20, display: `${this.state.displayLoading}`}}/>
                                </Col>
                            {/* </Row>
                            <br />
                            <Row> */}
                                <Col span={6} style={{ textAlign: 'center' }}>
                                    <Button
                                        disabled={this.state.statusbtn}
                                        loading={this.state.loadingHash}
                                        className='btn btn-success'
                                        style={{ width: '40%' }}
                                        onClick={this.onHashFiles.bind(this)}
                                    >
                                        UPLOAD
                                    </Button>
                                </Col>
                            </Row>
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ padding: 5 }}>
                        <div style={{border: '1px solid #001529', height: '75vh', overflowY: 'auto'}}>
                            <table className='table table-bordered'>
                                <thead style={{background: '#001529', color: "#fff", textAlign: 'center'}}>
                                    <tr>
                                        <th>Id</th>
                                        <th>Creation at</th>
                                        <th>Encoding</th>
                                        <th>File</th>
                                        <th>Total lines	</th>
                                        <th>Converted lines</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.liste_hash_files && this.state.liste_hash_files.map(item => {
                                            var tag_encoding = item['encoding'] === 'md5' ? "green" : "gold"
                                            var tag_status = item['status'] === 'Pending' ? "geekblue" : "cyan"
                                            return(
                                                <tr key={item['id']}>
                                                    <td style={{textAlign: 'center'}}>{item['id']}</td>
                                                    <td style={{textAlign: 'center'}}>{item['creation_date']}</td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <Tag color={tag_encoding}>{item['encoding']}</Tag>
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>{item['filename']}</td>
                                                    <td style={{textAlign: 'center'}}>{item['nb_lines']}</td>
                                                    <td style={{textAlign: 'center'}}>{item['converted']}</td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <Tag color={tag_status}>{item['status']}</Tag>
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <DownloadOutlined 
                                                            className='btn btn-success' 
                                                            style={{fontSize: 14}}
                                                            onClick={() => {
							                                    window.open(item['url'], '_blank')
                                                            }}
                                                        />
                                                    </td>

                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        
                    </Col>
                </Row>
            </div>
        );
    }
}


export default Hach;