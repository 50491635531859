import axios from 'axios'
import { base64_decode, base64_encode } from './Utilities';
import * as config from '../config/config'

export async function get_segments_dwh(user_id,apikey, dwh_id, crea_id, status) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('dwh_id', dwh_id)
        fd.append('crea_id', crea_id)
        fd.append('status', status)
        let res = await axios({
            url: config.REACT_APP_ENDPOINT_EMAILING_GET_SEGMENT,
            method: 'post',
            timeout: 30000,
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error authentification ' + err);
        return null
    }
}

export async function validate_segments_dwh(user_id,apikey, dwh_id, crea_id) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('dwh_id', dwh_id)
        fd.append('crea_id', crea_id)
        let res = await axios({
            url: config.REACT_APP_ENDPOINT_EMAILING_VALID_SEGMENT,
            method: 'post',
            timeout: 30000,
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error authentification ' + err);
        return null
    }
}

export async function invalidate_segments_dwh(user_id,apikey, dwh_id, crea_id) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('dwh_id', dwh_id)
        fd.append('crea_id', crea_id)
        let res = await axios({
            url: config.REACT_APP_ENDPOINT_EMAILING_INVALID_SEGMENT,
            method: 'post',
            timeout: 30000,
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error authentification ' + err);
        return null
    }
}

export async function request_segment(user_id,apikey, data) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('creaid', data['creaid'])
        fd.append('revenue', data['revenue'])
        fd.append('stats_campaign_id', data['stats_campaign_id'])
        fd.append('stats_scheduled_date', data['shoot_date'])
        fd.append('stats_base_id', data['stats_base_id'])
        fd.append('tags', data['tags'])
        fd.append('stats_country', data['country'])
        fd.append('stats_currency', data['currency'])
        fd.append('max_volumes', data['volume'])
        fd.append('age', data['dwh_age_range'])
        fd.append('sexe', data['dwh_gender'])
        fd.append('csp', data['dwh_csp'])
        fd.append('sol', data['dwh_landlords'])
        fd.append('soih', data['dwh_individual_house'])
        fd.append('soth', data['dwh_tax_house'])
        fd.append('sp', data['dwh_poverty'])
        fd.append('smi', data['dwh_median'])
        fd.append('domain_family', data['dwh_domain_family'])
        fd.append('status_zipcode', null)
        fd.append('zipcode', data['dwh_zipcode'])
        fd.append('dwh_optin_telemarketing', data['dwh_optin_telemarketing'])
        fd.append('dwh_optin_sms', data['dwh_optin_sms'])
        fd.append('dwh_fed_now', data['dwh_fed_now'])

        let res = await axios({
            url: config.REACT_APP_ENDPOINT_EMAILING_REQUEST_SEGMENT,
            method: 'post',
            timeout: 30000,
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error authentification ' + err);
        return null
    }
}