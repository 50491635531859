import React, { Component } from 'react'
import { Tabs, Tag} from 'antd';
import {base64_encode, base64_decode} from '../Utilities/Utilities'
import Task from './Task';
import InprogressOverview from '../Overview/InprogressOverview';
import ScheduledOverview from '../Overview/ScheduledOverview';
import Bat from './Bat';
import { check_user } from '../Utilities/User';
import { Redirect } from 'react-router-dom'

const { TabPane } = Tabs;
class Container extends Component {
    constructor(props) {
        super(props)

        this.state = {
			isLogin: true,
            def_link_tab: localStorage.getItem('defaulttabcrea'),
			roles: base64_decode(localStorage.getItem(base64_encode('roles'))),
        }
    }

	componentDidMount(){
		this.checkStatusLogging()
	}

	checkStatusLogging(){
        var user_id = localStorage.getItem(base64_encode('user_id'))
        var apikey = localStorage.getItem(base64_encode('apikey'))
        if(user_id !== null && apikey !== null){
        	Promise.resolve(check_user(base64_decode(user_id), base64_decode(apikey))).then((value) => {
               if(value.status != "success"){
					localStorage.clear()
					sessionStorage.clear()
					this.setState({
						isLogin: false
					})
			   }
            })
		}
	}
       
    render() {
		if (this.state.isLogin === false) {
            localStorage.clear()
            return (<Redirect to={{
                pathname: '/'
            }} />)
        }else{
			return (
				<Tabs defaultActiveKey={this.state.def_link_tab} style={{height: '99.8vh',background: '#eee'}}>
					<TabPane 
						tab={<Tag color="cyan" style={{fontSize: 12}}>IN PROGRESS</Tag>}
						key="1"
					>
						<InprogressOverview/>
					</TabPane>
					{/* <TabPane 
						tab="SENT" key="2" >
					</TabPane> */}
					<TabPane 
						tab={<Tag color="lime" style={{fontSize: 12}}>SCHEDULED</Tag>}
						key="2" 
					>
						<ScheduledOverview base_id={this.props.location.state ? this.props.location.state.base_id : null}/>
					</TabPane>
					<TabPane 
						tab={<Tag color="geekblue" style={{fontSize: 12}}>DRAFT</Tag>}
						key="3" 
						style={{ height: '93vh'}}
					>
						<Task/>
					</TabPane>
					<TabPane 
						tab={<Tag color="purple" style={{fontSize: 12}}>BAT</Tag>}
						key="4" 
					>
						<Bat/>
					</TabPane>
				</Tabs>
			)
		}
       
    }
}

export default Container