import React, { Component } from 'react';
import { base64_decode, base64_encode } from '../../Utilities/Utilities';
import { Row, Col, Tag, Select, Collapse, Button, message, Checkbox } from 'antd';
import ReactSrcDocIframe from 'react-srcdoc-iframe';
import { WarningOutlined } from '@ant-design/icons';
import Editor from '@monaco-editor/react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Modal from 'react-modal';
import { reupload_crea, update_crea } from '../../Utilities/Crea';
import '../../../css/details/html/html.css'
import { Redirect } from 'react-router-dom'

const bg = {
    overlay: {
        backgroundColor: "rgba(0, 0, 0, .5)"
    },
}

const { Panel } = Collapse;
const { Option } = Select
class Html extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_id: base64_decode(localStorage.getItem(base64_encode('user_id'))),
            username: base64_decode(localStorage.getItem(base64_encode('username'))),
            apikey: base64_decode(localStorage.getItem(base64_encode('apikey'))),
            stats_apikey: base64_decode(localStorage.getItem(base64_encode('stats_apikey'))),
            //liste utilisty:
            liste_users: [],
            liste_tags : [],
            //crea links and images
            goBack: false,
            crea_links_count: 0,
            crea_images_count: 0,
            crea_images_size: 0,
            crea_html_size: 0,
            crea_total_size: 0,
            //creativities html
            crea_id: null,
            crea_html_optimized: null,
            crea_html_original: null,
            crea_html_broken: null,

            crea_base_id: null,
            crea_user_id: null,
            crea_username: null,
            crea_tags: null,
            crea_router_message_type: null,
            crea_router_message_id: null,
            //creativities preview
            show_reoptimize: false,
            showDesktop: 'block',
            showOriginal: 'none',
            showImageDisable: 'none',
            showMobile: 'none',
            crea_preview_type: 'desktop',
            showGa: 'none',
            blacklist_check: null,
            // reoptimize button
            loadingReoptimize: false,
            show_reoptimize: false,
            crea_reoptimize_pixels: false,
            crea_reoptimize_img: false,
            crea_reoptimize_compress: false,
            crea_reoptimize_link: false,
            crea_reoptimize_jumping: false,
            crea_reoptimize_comment: false,

        }

    }

    componentDidMount() {
        var crea = this.props.creativities
        console.log(crea)
        var optimized = this.generateBrokenPreview(crea)
        var blacklist_check = crea['domain_list'] !== 'null' || crea['domain_list'] !== null ? base64_decode(crea['domain_list']) : null
        this.setState({
            //liste utility
            liste_users: this.props.liste_user,
            liste_tags: this.props.liste_tags,
            crea_id: this.props.crea_id,
            crea_links_count: crea['total_href'] != null ? crea['total_href'] : 0,
            crea_images_count: crea['total_images'] != null ? crea['total_images'] : 0,
            crea_images_size: parseInt(crea['images_size'] / Math.pow(1024, 1)),
            crea_html_size: parseInt(crea['html_size'] / Math.pow(1024, 1)),
            crea_total_size: parseInt(crea['images_size'] / Math.pow(1024, 1)) + parseInt(crea['html_size'] / Math.pow(1024, 1)),
            crea_html_optimized: base64_decode(crea['optimized']),
            crea_html_original: base64_decode(crea['original']),
            crea_html_broken: optimized,
            blacklist_check: blacklist_check,
            crea_reoptimize_pixels: crea['pixel'] === "0" ? false : true,
            crea_reoptimize_img: crea['img'] === "0" ? false : true,
            crea_reoptimize_link: crea['link'] === "0" ? false : true,
            crea_reoptimize_jumping: crea['redirect'] === "0" ? false : true,
            crea_reoptimize_comment: crea['comment'] === "0" ? false : true,

            crea_user_id: crea['user_id'],
            crea_base_id: crea['base_id'],
            crea_tags: crea['tags_id'],
            crea_router_message_type: crea['router_message'],
            crea_router_message_id: crea['router_id'],
        })
    }

    renderHtmlSizeWithoutImages() {
        if (this.state.crea_html_size >= 102) {
            return (
                <Col span={6}>
                    <Row>
                        <Col span={4} style={{ backgroundColor: 'red', textAlign: 'center' }}>
                            <WarningOutlined style={{ padding: 0, fontSize: 35, color: 'yellow' }} />
                        </Col>
                        <Col span={19} style={{ textAlign: 'center' }}>
                            <div style={{ width: '100%', height: 'auto', margin: '0 auto' }}>
                                <Tag color="#f50" style={{ height: 35, padding: 3, width: '100%' }}>
                                    <span style={{ textDecoration: 'underline', fontSize: 16 }}> Html Size</span>: &nbsp;&nbsp;<span><strong style={{ fontSize: '18px' }}>{this.state.crea_html_size}Ko</strong></span>
                                    <span style={{ fontSize: '20px !important' }}><strong>&nbsp;&nbsp;NEED TO BE COMPRESSED</strong></span>
                                </Tag>
                                <br />

                            </div>
                        </Col>
                    </Row>
                </Col>
            )
        } else {
            return (
                <Col span={3}>
                    <Row>
                        <Col span={24} style={{}}>
                            <div style={{ width: '100%', height: 'auto', margin: '0 auto' }}>
                                <Tag color="#F0B311" style={{ height: 30, padding: 3, width: '100%' }}>
                                    <span style={{ textDecoration: 'underline', fontSize: 16 }}> Html Size</span>: &nbsp;&nbsp;<span><strong style={{ fontSize: '18px' }}>{this.state.crea_html_size}Ko</strong></span><br />
                                </Tag>
                                <br />
                            </div>
                        </Col>
                    </Row>
                </Col>
            )
        }
    }

    renderHtmlSizeWithImages() {
        if (this.state.crea_total_size >= 1500) {
            return (
                <Col span={6}>
                    <Row>
                        <Col span={4} style={{ backgroundColor: 'red', textAlign: 'center' }}>
                            <WarningOutlined style={{ padding: 0, fontSize: 35, color: 'yellow' }} />
                        </Col>
                        <Col span={20} style={{ textAlign: 'center' }}>
                            <div style={{ width: '100%', height: 'auto', margin: '0 auto' }}>
                                <Tag color="#f50" style={{ height: 35, padding: 3, width: '100%' }}>
                                    <span style={{ textDecoration: 'underline', fontSize: 16 }}> Total Size</span>: &nbsp;&nbsp;<span><strong style={{ fontSize: '18px' }}>{this.state.crea_total_size}Ko</strong></span>
                                    <span style={{ fontSize: '18px !important' }}><strong>&nbsp;&nbsp;NEED TO BE COMPRESSED</strong></span>
                                </Tag>
                                <br />

                            </div>
                        </Col>
                    </Row>
                </Col>
            )
        } else {
            return (
                <Col span={3}>
                    <Row>
                        <Col span={24} >
                            <div style={{ width: '80%', height: 'auto', margin: '0 auto' }}>
                                <Tag color="#004A0E" style={{ height: 30, padding: 3, width: '100%' }}>
                                    <span style={{ textDecoration: 'underline', fontSize: 16 }}> Total Size</span>: &nbsp;&nbsp;<span><strong style={{ fontSize: '18px' }}>{this.state.crea_total_size}Ko</strong></span><br />
                                </Tag>
                                <br />
                            </div>
                        </Col>
                    </Row>
                </Col>
            )
        }
    }

    onPreviewChange(value) {
        switch (value) {
            case 'desktop':
                this.setState({
                    crea_preview_type: value,
                    showDesktop: 'block',
                    showOriginal: 'none',
                    showImageDisable: 'none',
                    showMobile: 'none',
                    showGa: 'none',
                })
                break;
            case 'mobile':
                this.setState({
                    crea_preview_type: value,
                    showDesktop: 'none',
                    showOriginal: 'none',
                    showImageDisable: 'none',
                    showMobile: 'block',
                    showGa: 'none',
                })
                break;
            case 'ga':
                this.getPromotionPreview()
                this.setState({
                    crea_preview_type: value,
                    showDesktop: 'none',
                    showOriginal: 'none',
                    showImageDisable: 'none',
                    showMobile: 'none',
                    showGa: 'block',
                })
                break;
            case 'imagedisable':
                this.setState({
                    crea_preview_type: value,
                    showDesktop: 'none',
                    showOriginal: 'none',
                    showImageDisable: 'block',
                    showMobile: 'none',
                    showGa: 'none',
                })
                break;
            case 'original':
                this.setState({
                    crea_preview_type: value,
                    showDesktop: 'none',
                    showOriginal: 'block',
                    showImageDisable: 'none',
                    showMobile: 'none',
                    showGa: 'none',
                })
                break;
            default:
                this.setState({
                    crea_preview_type: value,
                    showDesktop: 'block',
                    showOriginal: 'none',
                    showImageDisable: 'none',
                    showMobile: 'none',
                    showGa: 'none',
                })
                break;
        }
    }

    generateBrokenPreview(crea) {
        const imgRex = /<img.*?src="(.*?)"[^>]+>/g;
        const images = [];
        let img;
        var optimized = base64_decode(crea['optimized'])
        while ((img = imgRex.exec(optimized))) {
            images.push(img[1]);
        }
        images.map(item => {
            optimized = optimized.replace(item, '#')
            return null
        })
        return optimized
    }

    renderDomainBlacklistCheck() {
        if (this.state.blacklist_check !== null) {
            return (
                <div
                    style={{ margin: '0 auto', width: '100%', fontSize: 14, padding: 0, height: '100%', overflow: 'scroll' }}
                    dangerouslySetInnerHTML={{ __html: this.state.blacklist_check }}
                />

            )
        }
    }

    onReoptimizingRequests() {
		this.setState({ loadingReoptimize: true })
		var tagsname = null
        var crea_username = null
        console.log(this.state.liste_tags)
		this.state.liste_tags.map(item => {
			if (parseInt(this.state.crea_tags) === parseInt(item['id'])) {
				tagsname = item['tag']
			}
			return null
		})

        this.state.liste_users.map(item => {
            if (parseInt(this.state.crea_user_id) === parseInt(item['id'])) {
				crea_username = item['username']
			}
			return null
        })

		Promise.resolve(reupload_crea(
            this.state.user_id, 
            this.state.apikey, 
            tagsname, 
            this.state.crea_reoptimize_pixels,this.state.crea_reoptimize_img, 
            this.state.crea_reoptimize_link,this.state.crea_reoptimize_jumping,this.state.crea_reoptimize_comment, 
            this.state.crea_reoptimize_compress,this.state.crea_id,this.state.crea_base_id,this.state.crea_user_id,crea_username, 
            false)).then((value) => {
                setTimeout(() => {
                    this.setState({
                        goBack: true
                    })
                }, 2000)
		});
	}

    UpdateCode(status) {
		try {
			var html_code = null
			switch (status) {
				case 'optimized':
					html_code = base64_encode(this.state.crea_html_optimized)
					this.setState({ loadingUpdateOptimized: true })
					break;
				case 'original':
					html_code = base64_encode(this.state.crea_html_original)
					this.setState({ loadingUpdateOriginal: true })
					break;
				default:
					html_code = base64_encode(this.state.crea_html_optimized)
					break;
			}
			Promise.resolve(update_crea(
                this.state.user_id, this.state.apikey, this.state.crea_base_id, 
                this.state.crea_id,  html_code, status, this.state.crea_router_message_type, 
                this.state.crea_user_id, this.state.crea_router_message_id)).then((value) => {
                message.success('Code successfully update', 2)
				setTimeout(() => {
                    window.location.reload()
				}, 1000)
			});
		}
		catch (err) {
			console.error('error authentification ' + err);
			return null
		}
	}

    render() {
        if (this.state.goBack === true) {
            return (<Redirect to={{
                pathname: '/app/creativities/task'
            }} />)
        }
        return (
            <div>
                <Row>
                    <Col span={3}>
                        <Row>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <div style={{ width: '80%', height: 'auto', margin: '0 auto' }}>
                                    <Tag color="#2db7f5" style={{ height: 30, padding: 5, width: '100%' }}>
                                        <span style={{ textDecoration: 'underline', fontSize: 16 }}> Hyperlinks </span>: &nbsp;&nbsp;<span><strong style={{ fontSize: '16px' }}>{this.state.crea_links_count}</strong></span>
                                    </Tag>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={3}>
                        <Row>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <div style={{ width: '80%', height: 'auto', margin: '0 auto' }}>
                                    <Tag color="#87d068" style={{ height: 30, padding: 5, width: '100%' }}>
                                        <span style={{ textDecoration: 'underline', fontSize: 16 }}> Images </span>: &nbsp;&nbsp;<span><strong style={{ fontSize: '16px' }}>{this.state.crea_images_count}</strong></span>
                                    </Tag>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={3}>
                        <Row>
                            <Col span={24} style={{ textAlign: 'left' }}>
                                <div style={{ width: '80%', height: 'auto', margin: '0 auto' }}>
                                    <Tag color="#87d068" style={{ height: 30, padding: 5, width: '100%' }}>
                                        <span style={{ textDecoration: 'underline', fontSize: 16 }}> Images Size</span>: &nbsp;&nbsp;<span><strong style={{ fontSize: '16px' }}>{this.state.crea_images_size}Ko</strong></span>
                                    </Tag>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    {this.renderHtmlSizeWithoutImages()}
                    {this.renderHtmlSizeWithImages()}
                </Row>
                <br />
                <Row>
                    <Col span={10}>
                        <Row>
                            <Col span={4} style={{ textAlign: 'center', padding: 0, fontSize: 18, fontFamily: 'Lato !important' }}>
                                <span>Preview type</span>
                            </Col>
                            <Col span={7}>
                                <Select
                                    size="medium"
                                    style={{ width: '90%', textAlign: 'center', fontWeight: 600, fontSize: 16 }}
                                    value={this.state.crea_preview_type}
                                    onChange={(value) => {
                                        this.onPreviewChange(value)
                                    }}
                                >
                                    <Option key="11" value="desktop">Desktop</Option>
                                    <Option key="51" value="original">Original</Option>
                                    <Option key="21" value="mobile">Mobile</Option>
                                    <Option key="41" value="imagedisable">Images disable</Option>
                                    {/* <Option key="31" value="ga">Google Annotation</Option> */}
                                </Select>
                            </Col>
                            <Col span={5}>
                                <button
                                    className="btn btn-info"
                                    style={{ fontSize: 14, color: '#fff', margin: '0 auto', fontWeight: 600 }}
                                    onClick={() => {
                                        this.setState({
                                            show_reoptimize: true
                                        })

                                    }}
                                >
                                    REOPTIMIZE
                                </button>
                            </Col>
                            <Col span={4}>
                                <button
                                    className="btn btn-success"
                                    style={{ fontSize: 14, margin: '0 auto', fontWeight: 600 }}
                                    onClick={() => {
                                        var new_link = process.env.REACT_APP_ENDPOINT_CREA_PREVIEW + '=' + base64_encode(this.state.crea_id)
                                        console.log(new_link)
                                        window.open(new_link, '_blank')

                                    }}
                                >
                                    VIEW TAB
                                </button>
                            </Col>
                        </Row>
                        <div style={{ height: '80vh', width: '100%', paddingTop: 15, display: `${this.state.showDesktop}` }}>
                            <ReactSrcDocIframe
                                title="preview"
                                className="embed-responsive-item" srcDoc={this.state.crea_html_optimized}
                                style={{ width: '100%', height: '100%', marginLeft: '0px', overflow: 'scroll', border: 'none' }}
                            />
                        </div>
                        <div style={{ height: '80vh', width: '100%', display: `${this.state.showOriginal}`, paddingTop: 15 }}>
                            <ReactSrcDocIframe
                                title="preview"
                                className="embed-responsive-item" srcDoc={this.state.crea_html_original}
                                style={{ width: '100%', height: '100%', marginLeft: '0px', overflow: 'scroll', border: 'none' }}
                            />
                        </div>
                        <div style={{ height: '80vh', width: '100%', display: `${this.state.showImageDisable}`, paddingTop: 15 }}>
                            <ReactSrcDocIframe
                                title="preview"
                                className="embed-responsive-item" srcDoc={this.state.crea_html_broken}
                                style={{ width: '100%', height: '100%', marginLeft: '0px', overflow: 'scroll', border: 'none' }}
                            />
                        </div>
                        <div style={{ height: '80vh', width: '100%', display: `${this.state.showMobile}`, paddingTop: 15 }}>
                            <div className="smartphone_1" style={{ textAlign: 'center', width: '100%', height: '100%' }}>
                                {/* <div className="content"> */}
                                <iframe
                                    title="preview"
                                    srcDoc={this.state.crea_html_optimized}
                                    style={{ width: 360, height: '100%', marginTop: 0, marginLeft: '0px', overflow: 'scroll' }}
                                />
                                {/* </div> */}
                            </div>
                        </div>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={13}>
                        <Collapse accordion  defaultActiveKey={['3']}>
                            <Panel header={<span style={{ fontWeight: 700 }}>HTML CODE OPTIMIZED</span>} key="1" style={{ height: '100%' }}>
                                <div style={{ height: '580px', marginTop: 5 }}>
                                    <Editor
                                        height="57vh"
                                        defaultLanguage="html"
                                        theme="vs-dark"
                                        defaultValue={this.state.crea_html_optimized}
                                        style={{ width: '100%', border: '1px solid gray', marginTop: '0px' }}
                                        onChange={(value) => {
                                            this.setState({ crea_html_optimized: value })
                                        }}
                                    />
                                    <div style={{ padding: 15 }}>
                                        <Row>
                                            <Col span={11}>
                                                <Button
                                                    loading={this.state.loadingUpdateOptimized}
                                                    className="btn btn-success"
                                                    style={{ width: "100%", margin: '0 auto', fontSize: 15 }}
                                                    onClick={() => {
                                                        this.UpdateCode('optimized')
                                                    }}
                                                >
                                                    UPDATE
                                                </Button>
                                            </Col>
                                            <Col span={2}></Col>
                                            <Col span={11}>
                                                <CopyToClipboard text={this.state.crea_html_optimized}>
                                                    <Button
                                                        className="btn btn-primary"
                                                        style={{ width: "100%", margin: '0 auto', fontSize: 15 }}
                                                        onClick={() => {
                                                            message.info('Copied', 1)
                                                        }}
                                                    >
                                                        COPY
                                                    </Button>
                                                </CopyToClipboard>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Panel>
                            <Panel header={<span style={{ fontWeight: 700 }}>HTML CODE ORIGINAL</span>} key="2" style={{ height: '100%' }}>
                                <div style={{ height: '580px' }}>
                                    <Editor
                                        height="57vh"
                                        defaultLanguage="html"
                                        theme="vs-dark"
                                        defaultValue={this.state.crea_html_original}
                                        style={{ width: '100%', border: '1px solid gray', marginTop: '0px' }}
                                        onChange={(value) => {
                                            this.setState({ crea_html_original: value })
                                        }}
                                    />
                                    <div style={{ padding: 15 }}>
                                        <Row>
                                            <Col span={11}>
                                                <Button
                                                    loading={this.state.loadingUpdateOriginal}
                                                    className="btn btn-success"
                                                    style={{ width: "100%", margin: '0 auto', fontSize: 15 }}
                                                    onClick={() => {
                                                        this.UpdateCode('original')
                                                    }}
                                                >
                                                    UPDATE
                                                </Button>
                                            </Col>
                                            <Col span={2}></Col>
                                            <Col span={11}>
                                                <CopyToClipboard text={this.state.crea_html_original}>
                                                    <Button
                                                        className="btn btn-primary"
                                                        style={{ width: "100%", margin: '0 auto', fontSize: 15 }}
                                                        onClick={() => {
                                                            message.info('Copied', 1)
                                                        }}
                                                    >
                                                        COPY
                                                    </Button>
                                                </CopyToClipboard>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Panel>
                            <Panel header={<span style={{ fontWeight: 700 }}>BLACKLIST CHECK</span>} key="3" style={{ height: '100%' }}>
                                <div style={{ height: '580px' }}>
                                    {this.renderDomainBlacklistCheck()}
                                </div>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
                <Modal
					id="newreo"
					isOpen={this.state.show_reoptimize}
					onRequestClose={() => { this.setState({ show_reoptimize: false }) }}
					shouldCloseOnOverlayClick={true}
					ariaHideApp={false}
					style={bg}
				>
					<div style={{ fontSize: '5px !important', marginTop: '0px', marginLeft: '0px', width: '100%' }}>
						<div
							style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%', marginLeft: '0px', height: '100%' }}
						>
							<h3 style={{ color: "#fff", padding: 5 }}>REOPTIMIZE CREATIVITY</h3>
						</div>
						<span
							style={{ color: "#C23616", marginTop: '-42px', fontSize: 29, float: 'right', cursor: 'pointer' }} onClick={() => { this.setState({ show_reoptimize: false }) }}
						>
							X
						</span>
					</div>
					<div style={{ width: '95%', padding: 0 }}>
						<Row>
							<Col span={6} style={{ textAlign: 'center' }}>
								<Checkbox
									checked={this.state.crea_reoptimize_pixels}
									onChange={(e) => {
										if (e.target.checked) {
											this.setState({ crea_reoptimize_pixels: e.target.checked })
										} else {
											this.setState({ crea_reoptimize_pixels: e.target.checked })
										}
									}}
								>
									<span style={{ fontSize: 14 }}>Pixels</span>
								</Checkbox>
							</Col>
							<Col span={6} style={{ textAlign: 'center' }}>
								<Checkbox
									checked={this.state.crea_reoptimize_img}
									onChange={(e) => {
										if (e.target.checked) {
											this.setState({ crea_reoptimize_img: e.target.checked })
										} else {
											this.setState({ crea_reoptimize_img: e.target.checked })
										}
									}}
								>
									<span style={{ fontSize: 14 }}>Image</span>
								</Checkbox>
							</Col>
							<Col span={6} style={{ textAlign: 'center' }}>
								<Checkbox
									checked={this.state.crea_reoptimize_compress}
									onChange={(e) => {
										if (e.target.checked) {
											this.setState({ crea_reoptimize_compress: e.target.checked })
										} else {
											this.setState({ crea_reoptimize_compress: e.target.checked })
										}
									}}
								>
									<span style={{ fontSize: 14 }}>Compress</span>
								</Checkbox>
							</Col>
							<Col span={6} style={{ textAlign: 'center' }}>
								<Checkbox
									checked={this.state.crea_reoptimize_link}
									onChange={(e) => {
										if (e.target.checked) {
											this.setState({ crea_reoptimize_link: e.target.checked })
										} else {
											this.setState({ crea_reoptimize_link: e.target.checked })
										}
									}}
								>
									<span style={{ fontSize: 14 }}>Link</span>
								</Checkbox>
							</Col>
						</Row>
						<br />
						<Row>
							<Col span={6} style={{ textAlign: 'center' }}>
								<Checkbox
									checked={this.state.crea_reoptimize_jumping}
									onChange={(e) => {
										if (e.target.checked) {
											this.setState({ crea_reoptimize_jumping: e.target.checked })
										} else {
											this.setState({ crea_reoptimize_jumping: e.target.checked })
										}
									}}
								>
									<span style={{ fontSize: 14 }}>Jumping</span>
								</Checkbox>
							</Col>
							<Col span={6} style={{ textAlign: 'center' }}>
								<Checkbox
									checked={this.state.crea_reoptimize_comment}
									onChange={(e) => {
										if (e.target.checked) {
											this.setState({ crea_reoptimize_comment: e.target.checked })
										} else {
											this.setState({ crea_reoptimize_comment: e.target.checked })
										}
									}}
									style={{ marginLeft: 27 }}
								>
									<span style={{ fontSize: 14 }}>Comment</span>
								</Checkbox>
							</Col>
						</Row>
						<br />
						<div
							style={{ textAlign: 'center', width: '50%', margin: '0 auto' }}
						>
							<Button
								loading={this.state.loadingReoptimize}
								className="btn btn-success"
								style={{ width: '50%', height: 40, fontFamily: 'Lato', fontWeight: 600, fontSize: 16 }}
								onClick={this.onReoptimizingRequests.bind(this)}
							>
								REOPTIMIZE
							</Button>
						</div>
					</div>
				</Modal>
            </div>
        );
    }
}


export default Html;