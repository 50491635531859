import React, { Component } from 'react'
import { Tabs} from 'antd';
import {base64_encode, base64_decode} from '../Utilities/Utilities'
import ScheduledOverview from './ScheduledOverview';
import InprogressOverview from './InprogressOverview';
import ImpactChecker from './ImpactChecker'
import Toolso from './Toolso';

const { TabPane } = Tabs;
class Overview extends Component {
    constructor(props) {
        super(props)

        this.state = {
            def_link_tab: "1",
			roles: base64_decode(localStorage.getItem(base64_encode('roles'))),
        }
    }

	changeTab = activeKey => {
		localStorage.setItem(base64_encode('def_link_pref_delivery'), base64_encode(activeKey))
		this.setState({
			def_link_tab: activeKey
		})
	}

	// renderImpact(){
	// 	if(this.state.roles !== 'TM' || this.state.roles !== 'TME'){
	// 		return(
	// 			<TabPane tab="IMPACT CHECKER" key="2" >
	// 				<ImpactChecker />
	// 			</TabPane>
	// 		)
	// 	}
	// }

    render() {
        return (
			<Tabs defaultActiveKey={this.state.def_link_tab} onChange={this.changeTab}>
				{
					// this.renderImpact()
				}
				<TabPane tab="TOOLSO" key="1" >
					<Toolso/>
				</TabPane>

				
				
			</Tabs>
        )
    }
}

export default Overview