import axios from 'axios'
import { base64_decode, base64_encode } from './Utilities';
import * as config from '../config/config';


export async function list_draft(user_id, apikey,base_id,message_type) {
    if(message_type === null){
        message_type = 'Newsletters'
    }
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('base_id', base_id)
        fd.append('draft_type', message_type)
        let res = await axios({
            url: config.REACT_APP_ENDPOINT_ES_DRAFT_LIST,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        if('status' in res.data && res.data['status'] === 'error'){
            return []
        }
        return res.data
    }
    catch (err) {
        console.error('error getting list draft router ' + err);
        return []
    }
}

export async function list_inprogress(base_id,message_type) {
    if(message_type === null){
        message_type = 'Newsletters'
    }
    try {
        var fd = new FormData() 
        fd.append('user_id', base64_decode(localStorage.getItem(base64_encode('user_id'))))
        fd.append('apikey', base64_decode(localStorage.getItem(base64_encode('apikey'))))
        fd.append('base_id', base_id)
        fd.append('draft_type', message_type)
        let res = await axios({
            url: config.REACT_APP_ENDPOINT_ES_INPROGRESS_LIST,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        console.log(res.data)
        return res.data
    }
    catch (err) {
        console.error('error authentification ' + err);
        return null
    }
}

export async function list_sent(base_id,message_type) {
    if(message_type === null){
        message_type = 'Newsletters'
    }
    try {
        var fd = new FormData() 
        fd.append('user_id', base64_decode(localStorage.getItem(base64_encode('user_id'))))
        fd.append('apikey', base64_decode(localStorage.getItem(base64_encode('apikey'))))
        fd.append('base_id', base_id)
        fd.append('draft_type', message_type)
        let res = await axios({
            url: config.REACT_APP_ENDPOINT_ES_SENT_LIST,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        console.log(res.data)
        return res.data
    }
    catch (err) {
        console.error('error authentification ' + err);
        return null
    }
}

export async function list_test(base_id,message_type) {
    if(message_type === null){
        message_type = 'Newsletters'
    }
    try {
        var fd = new FormData() 
        fd.append('user_id', base64_decode(localStorage.getItem(base64_encode('user_id'))))
        fd.append('apikey', base64_decode(localStorage.getItem(base64_encode('apikey'))))
        fd.append('base_id', base_id)
        fd.append('draft_type', message_type)
        let res = await axios({
            url: config.REACT_APP_ENDPOINT_ES_TEST_LIST,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        console.log(res.data)
        return res.data
    }
    catch (err) {
        console.error('error authentification ' + err);
        return null
    }
}

export async function list_scheduled(base_id,message_type) {
    if(message_type === null){
        message_type = 'Newsletters'
    }
    try {
        var fd = new FormData() 
        fd.append('user_id', base64_decode(localStorage.getItem(base64_encode('user_id'))))
        fd.append('apikey', base64_decode(localStorage.getItem(base64_encode('apikey'))))
        fd.append('base_id', base_id)
        fd.append('draft_type', message_type)
        let res = await axios({
            url: config.REACT_APP_ENDPOINT_ES_SCHEDULED_LIST,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        console.log(res.data)
        return res.data
    }
    catch (err) {
        console.error('error authentification ' + err);
        return null
    }
}

export async function save_draft(user_id, apikey,stats_apikey,base_id, crea_id,type,tag, tags_id,object,sender,preheader,fromemail,replyname,replyemail,headerid,footerid,draft_id,code,status,seedlist=null) {
    if(type === null){
        type = 'Newsletters'
    }
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('stats_apikey', stats_apikey)
        fd.append('base_id', base_id)
        fd.append('crea_id', crea_id)
        fd.append('message_type', type)
        fd.append('tag', tag)
        fd.append('tag_id', tags_id)
        fd.append('object', object)
        fd.append('sender', sender)
        fd.append('preheader', preheader)
        fd.append('fromemail',fromemail)
        fd.append('replyname',replyname)
        fd.append('replyemail',replyemail)
        fd.append('headerid',headerid)
        fd.append('footerid',footerid)
        fd.append('draft_id',draft_id)
        fd.append('code', base64_encode(code))
        if(status === 'send'){
            fd.append('status', 'send')
            fd.append('seedlist', seedlist)
        }else{
            fd.append('status', 'save')
        }
        let res = await axios({
            url: config.REACT_APP_ENDPOINT_ES_DRAFT_SAVE,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error getting list draft router ' + err);
        return []
    }
}

export async function scheduled(user_id, apikey,stats_apikey,baseid,sender, fromemail, replytoname, replyemail, object, preheader, headerid, footerid, message_id, type,code, plainText, segment_id, suppression_id, seedlist, date_schedule,time_scheduled, timezone,tag,tags_id,throttling_type, throttling_manual_count, throttling_option_choose,defaultOptimisationTime, recurring_enddate, recurring_days, recurring_hour, recurring_months, recurring_minutes, subscriber_id,crea_id, useTemplateRouter, status, dwh_id) {
    if(type === null){
        type = 'Newsletters'
    }
    try {
        var fd = new FormData()
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('stats_apikey', stats_apikey)
        fd.append('base_id', baseid)
        fd.append('sender', sender)
        fd.append('fromEmail', fromemail)
        fd.append('replyName', replytoname)
        fd.append('replyEmail', replyemail)
        fd.append('subject', object)
        fd.append('preheader', preheader)
        fd.append('header', headerid)
        fd.append('footer', footerid)
        fd.append('messageid', message_id)
        fd.append('messagetype', type)
        fd.append('html', code)
        fd.append('text', plainText)
        fd.append('segment', segment_id)
        fd.append('subscriber', subscriber_id)
        fd.append('suppression', suppression_id)
        fd.append('seedlist', seedlist)
        fd.append('delivery_date', date_schedule)
        fd.append('delivery_time',time_scheduled)
        fd.append('timezone', timezone)
        fd.append('tag', tag)
        fd.append('tags_id', tags_id)
        fd.append('throttling_type',throttling_type)
        fd.append('delay_time', throttling_manual_count)
        fd.append('delay_strategie', throttling_option_choose)
        fd.append('sendtimeOptimizationTime',defaultOptimisationTime)
        // recurring_enddate, recurring_days, recurring_hour, recurring_months, recurring_minutes
        fd.append('recurring_enddate',recurring_enddate)
        fd.append('recurring_days',recurring_days)
        fd.append('recurring_hour',recurring_hour)
        fd.append('recurring_months',recurring_months)
        fd.append('recurring_minutes',recurring_minutes)
        fd.append('crea_id',crea_id)
        fd.append('useTemplateRouter',useTemplateRouter)
        fd.append('mode',status)
        fd.append('dwh_id',dwh_id)

        let res = await axios({
            url: config.REACT_APP_ENDPOINT_ES_MESSAGE_SCHEDULED_VALIDATE,
            method: 'post',
            timeout: 30000,
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error getting list draft router ' + err);
        return []
    }
}

export async function list_scheduled_overview(service) {
    try {
        var fd = new FormData() 
        fd.append('user_id', base64_decode(localStorage.getItem(base64_encode('user_id'))))
        fd.append('apikey', base64_decode(localStorage.getItem(base64_encode('apikey'))))
        fd.append('date', base64_decode(localStorage.getItem(base64_encode('apikey'))))
        fd.append('service', "service")
        let res = await axios({
            url: config.REACT_APP_ENDPOINT_SCHEDULED_OVERVIEW,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error authentification ' + err);
        return null
    }
}

export async function list_top_object(user_id,apikey,tagsname) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('tags', tagsname)
        let res = await axios({
            url: config.REACT_APP_ENDPOINT_TOP_SUBJECT,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        return res.data['data']
    }
    catch (err) {
        console.error('error getting list of top object  ' + err);
        return null
    }
}

export async function message_details(user_id,apikey,baseid, draft_id) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('base_id', baseid)
        fd.append('draft_id', draft_id)
        let res = await axios({
            url: config.REACT_APP_ENDPOINT_ES_DRAFT_DETAIL,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error getting list of top object  ' + err);
        return null
    }
}

export async function message_full_preview(user_id,apikey,baseid, draft_id) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('base_id', baseid)
        fd.append('draft_id', draft_id)
        let res = await axios({
            url: config.REACT_APP_ENDPOINT_ES_MESSAGE_FULL_PREVIEW,
            method: 'post',
            timeout: 30000,
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error getting list of top object  ' + err);
        return null
    }
}

export async function cancel_scheduled(user_id,apikey,baseid, draft_id, type, crea_id) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('base_id', baseid)
        fd.append('draft_id', draft_id)
        fd.append('crea_id', crea_id)
        fd.append('draft_type', type)

        let res = await axios({
            url: config.REACT_APP_ENDPOINT_ES_MESSAGE_SCHEDULED_CANCEL,
            method: 'post',
            timeout: 30000,
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error getting list of top object  ' + err);
        return null
    }
}

export async function update_scheduled(data) {
    try {
        var fd = new FormData() 
        fd.append('user_id', data['user_id'])
        fd.append('apikey', data['apikey'])
        fd.append('stats_apikey', data['stats_apikey'])
        fd.append('crea_id', data['crea_id'])
        fd.append('base_id', data['base_id'])
        fd.append('sender', data['fromName'])
        fd.append('fromEmail', data['fromEmail'])
        fd.append('replyName', data['replyname'])
        fd.append('replyEmail', data['replyEmail'])
        fd.append('subject', data['subject'])
        fd.append('preheader', data['preheader'])
        fd.append('header', data['header'])
        fd.append('footer', data['footer'])
        fd.append('messageid', data['messageid'])
        fd.append('messagetype', data['messagetype'])
        fd.append('segment', data['segment'])
        fd.append('subscriber', data['subscriber'])
        fd.append('suppression', data['suppression'])
        fd.append('html', base64_encode(data['html']))
        fd.append('tag', data['tag'])
        fd.append('tags_id', data['tags_id'])
        fd.append('delivery_date', data['delivery_date'])
        fd.append('delivery_method', data['delivery_method'])
        fd.append('delivery_value', data['delivery_value'])
        fd.append('useTemplateRouter', data['useTemplateRouter'])
        fd.append('timezone', data['timezone'])

        let res = await axios({
            url: config.REACT_APP_ENDPOINT_ES_MESSAGE_SCHEDULED_UPDATE,
            method: 'post',
            timeout: 30000,
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error getting list of top object  ' + err);
        return null
    }
}

export async function list_impact(user_id,apikey) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        let res = await axios({
            url: config.REACT_APP_ENDPOINT_IMPACT_LIST,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error authentification ' + err);
        return null
    }
}

export async function calculate_segment_size(user_id,apikey, impact_id, impact_dbb, impact_file) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('impact_id', impact_id)
        fd.append('impact_dbb', impact_dbb)
        fd.append('impact_file', impact_file)

        let res = await axios({
            url: config.REACT_APP_ENDPOINT_IMPACT_CALCULATE,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error authentification ' + err);
        return null
    }
}

export async function update_impact(user_id,apikey, impact_id, impact_dbb, impact_file) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('impact_id', impact_id)
        fd.append('impact_dbb', impact_dbb)
        fd.append('impact_file', impact_file)

        let res = await axios({
            url: config.REACT_APP_ENDPOINT_IMPACT_UPDATE,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error authentification ' + err);
        return null
    }
}

export async function emergency_impact(user_id,apikey) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        let res = await axios({
            url: config.REACT_APP_ENDPOINT_IMPACT_EMERGENCY_UPDATE,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error authentification ' + err);
        return null
    }
}

export async function emergency_events(user_id,apikey,start,end) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('start', start)
        fd.append('apikey', apikey)

        let res = await axios({
            url: config.REACT_APP_ENDPOINT_IMPACT_EMERGENCY_UPDATE,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error authentification ' + err);
        return null
    }
}

export async function createSeedlists(user_id,apikey,data) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('username', data['username'])
        fd.append('base_id', data['base_id'])
        fd.append('seed_name', data['seed_name'])
        fd.append('emails', data['emails'])

        let res = await axios({
            url: config.REACT_APP_ENDPOINT_ES_SEEDLISTS_CREATE,
            method: 'post',
            timeout: 30000,
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error getting list of top object  ' + err);
        return null
    }
}

export async function createSubscriberlists(user_id,apikey,data) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('username', data['username'])
        fd.append('base_id', data['base_id'])
        fd.append('sub_name', data['seed_name'])
        fd.append('emails', data['emails'])
        fd.append('encryption', data['encryption'])

        let res = await axios({
            url: config.REACT_APP_ENDPOINT_ES_SUBSCRIBERLISTS_CREATE,
            method: 'post',
            timeout: 30000,
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error getting list of top object  ' + err);
        return null
    }
}

export async function addToSeedlists(user_id,apikey,data) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('username', data['username'])
        fd.append('base_id', data['base_id'])
        fd.append('seed_name', data['seed_name'])
        fd.append('seed_id', data['seed_id'])
        fd.append('emails', data['emails'])

        let res = await axios({
            url: config.REACT_APP_ENDPOINT_ES_SEEDLISTS_ADD,
            method: 'post',
            timeout: 30000,
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error getting list of top object  ' + err);
        return null
    }
}

export async function addToSubscriberlists(user_id,apikey,data) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('username', data['username'])
        fd.append('base_id', data['base_id'])
        fd.append('seed_name', data['seed_name'])
        fd.append('seed_id', data['seed_id'])
        fd.append('emails', data['emails'])
        fd.append('encryption', data['encryption'])

        let res = await axios({
            url: config.REACT_APP_ENDPOINT_ES_SUBSCRIBERLISTS_ADD,
            method: 'post',
            timeout: 30000,
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error getting list of top object  ' + err);
        return null
    }
}

export async function getcreatedSeedlists(user_id,apikey) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        let res = await axios({
            url: config.REACT_APP_ENDPOINT_ES_SEEDLISTS_LIST_CREATED,
            method: 'post',
            timeout: 30000,
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error getting list of top object  ' + err);
        return null
    }
}

export async function getExportedSegment(user_id,apikey) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        let res = await axios({
            url: config.REACT_APP_ENDPOINT_ES_EXPORT_LIST_CREATED,
            method: 'post',
            timeout: 30000,
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error getting list of top object  ' + err);
        return null
    }
}

export async function export_segment(user_id, apikey,data) {
    try {
        console.log(data)
        var fd = new FormData()
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        // fd.append('stats_apikey', stats_apikey)
        fd.append('username', data["username"])
        fd.append('base_id', data["base_id"])
        fd.append('encryption', data["encryption"])
        fd.append('segment_id', data["segment_id"])
        let res = await axios({
            url: config.REACT_APP_ENDPOINT_ES_SEGMENTS_EXPORT,
            method: 'post',
            timeout: 20000,
            data: fd
        })
        return res.data
    }
    catch (err) {
        console.error('error getting segment lists ' + err);
        return null
    }
}

export async function export_segment_lists(user_id, apikey) {
    try {
        var fd = new FormData()
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        let res = await axios({
            url: config.REACT_APP_ENDPOINT_ES_SEGMENTS_EXPORT_LIST,
            method: 'post',
            timeout: 20000,
            data: fd
        })
        return res.data
    }
    catch (err) {
        console.error('error getting segment lists ' + err);
        return null
    }
}
