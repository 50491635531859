import React, { Component } from 'react'
import { base64_encode, base64_decode, generateKey } from '../Utilities/Utilities';
import {Row, Col, Select,Button,Input, message} from 'antd'
import axios from 'axios'
import * as config from '../config/config'

const Option = Select
class Router extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            liste_router: [],
            userid: base64_decode(localStorage.getItem(base64_encode('user_id'))),
            username: base64_decode(localStorage.getItem(base64_encode('username'))),
            apikey: base64_decode(localStorage.getItem(base64_encode('apikey'))),
            statsapikey: base64_decode(localStorage.getItem(base64_encode('stats_apikey'))),
            endpoint: base64_decode(localStorage.getItem(base64_encode('endpoint'))),
            email: base64_decode(localStorage.getItem(base64_encode('email'))),
            roles: base64_decode(localStorage.getItem(base64_encode('roles'))),
            displayAdmin: 'none',
            name: '',
            email: '',
            firstname: '',
            lastname: '',
            zipcode: '',
            civility: '',
            birthday: '',
            displayUpdate: 'none',
            displayAdd: 'block',
            router_id: null,
            loadingUpdate: false,
            loadingDisable: false
        }
        this.onChangeRouter = this.onChangeRouter.bind(this)
    }

    componentDidMount(){
        localStorage.setItem(base64_encode('def_dash_pref'), "4")
        if(base64_decode(localStorage.getItem(base64_encode('roles'))) === 'Admin'){
            this.setState({isAdmin: true,displayAdmin: 'block'})
        }
        this.getListRouter()
    }

    getListRouter(){
        if(localStorage.getItem(base64_encode('all_router')) !== null){
            this.setState({liste_router : JSON.parse(base64_decode(localStorage.getItem(base64_encode('all_router'))))})
            return
        }else{
            var fd = new FormData()
            fd.append('user_id', this.state.userid)
            fd.append('apikey', this.state.apikey)
            axios.post(config.REACT_APP_ENDPOINT_ROUTER_LIST, fd)
            .then(res => {
                localStorage.setItem(base64_encode('all_router'), base64_encode(JSON.stringify(res.data)))
                this.setState({liste_router: res.data})
            })
        }
    }

    onChangeRouter(value){
        this.setState({router_id: value, displayUpdate: 'block', displayAdd: 'none'})
        this.state.liste_router.map(item => {
            if(parseInt(item['id']) === parseInt(value)){
                this.setState({
                    name : item['name'],
                    email: item['email'],
                    firstname: item['firstname'],
                    lastname: item['lastname'],
                    civility: item['civility'],
                    birthday: item['birthdate'],
                    zipcode: item['zipcode'],
                })
            }
        })
    }

    onUpdateRouter(){
        this.setState({loadingUpdate: true})
        var fd = new FormData()
        fd.append('user_id', this.state.userid)
        fd.append('apikey', this.state.apikey)
        fd.append('router_id', this.state.router_id)
        fd.append('router_name',this.state.name)
        fd.append('router_email',this.state.email)
        fd.append('router_firstname',this.state.firstname)
        fd.append('router_lastname',this.state.lastname)
        fd.append('router_zipcode',this.state.zipcode)
        fd.append('router_civility',this.state.civility)
        fd.append('router_birthday',this.state.birthday)
        axios.post(config.REACT_APP_ENDPOINT_ROUTER_UPDATE, fd)
        .then(res => {
            localStorage.removeItem(base64_encode('all_router'))
            setTimeout( () => {
                window.location.reload()
            }, 2000)
        })
    }

    onDisableRouter(){
        this.setState({loadingDisable: true})
        var url = this.state.endpoint + 'api/router/disable'
        var fd = new FormData()
        fd.append('user_id', this.state.userid)
        fd.append('apikey', this.state.apikey)
        fd.append('router_id', this.state.router_id)
        axios.post(config.REACT_APP_ENDPOINT_ROUTER_DISABLE, fd)
        .then(res => {
            localStorage.removeItem(base64_encode('all_router'))
            setTimeout( () => {
                window.location.reload()
            }, 2000)  
        })
    }

    onAddRouter(){
        if(this.state.name === ''){
            message.error("Field name is mandatory", 3)
            return
        }else{
            this.setState({loadingAdd: true})
            var fd = new FormData()
            fd.append('user_id', this.state.userid)
            fd.append('apikey', this.state.apikey)
            fd.append('router_name', this.state.name)
            fd.append('router_email',this.state.email)
            fd.append('router_firstname', this.state.firstname)
            fd.append('router_lastname', this.state.lastname)
            fd.append('router_zipcode', this.state.zipcode)
            fd.append('router_civility', this.state.civility)
            fd.append('router_birthday', this.state.birthday)
            axios.post(config.REACT_APP_ENDPOINT_ROUTER_CREATE, fd)
            .then(res => {
                localStorage.removeItem(base64_encode('all_router'))
                setTimeout( () => {
                    window.location.reload()
                }, 2000)
            })
        }
    }
    
    render() {
        return (
            <div style={{backgroundColor: '#fff', marginTop: '-25px', height: '93vh'}}>
                <div style={{width: '60%', margin: '0 auto'}}>
                    <div style={{marginTop: 25, marginLeft: 75}}>
                        <Row>
                            <Col span={4} style={{padding : 10}}>
                                <span style={{fontSize: 16}}>Select Router</span>
                            </Col>
                            <Col span={8}>
                                <Select
                                    size="medium"   
                                    style={{width: '100%', padding: 5, color: '#192a56',fontWeight: 700, textAlign: 'center' }}
                                    value={this.state.router_id}
                                    onChange={this.onChangeRouter.bind(this)}
                                >
                                    {
                                        this.state.liste_router.map(item => {
                                           var key = generateKey()
                                            return(
                                                <Option key={key} value={item.id}>{item['name']}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col span={4} style={{padding: 5, display: `${this.state.displayAdmin}`}}>
                                <Button
                                    className="btn btn-primary"
                                    style={{width: '100%', fontSize: 16}}
                                    onClick={() => {
                                        this.setState({
                                            router_id: null,
                                            name: '',
                                            email: '',
                                            firstname: '',
                                            lastname: '',
                                            zipcode: '',
                                            civility: '',
                                            birthday: '',
                                            displayUpdate: 'none',
                                            displayAdd: 'block'
                                        })
                                    }}
                                >
                                    +Add
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4} style={{padding : 10}}>
                            <span style={{fontSize: 16}}>Name : </span>
                            </Col>
                            <Col span={12}>
                                <Input
                                    size="medium"   
                                    style={{width: '100%', padding: 5, color: '#192a56',fontWeight: 700, textAlign: 'center' }}
                                    value={this.state.name}
                                    onChange={(e) => {
                                        this.setState({name: e.target.value})
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4} style={{padding : 10}}>
                                <span style={{fontSize: 16}}>Email : </span>

                            </Col>
                            <Col span={12}>
                                <Input
                                    size="medium"   
                                    style={{width: '100%', padding: 5, color: '#192a56',fontWeight: 700, textAlign: 'center' }}
                                    value={this.state.email}
                                    onChange={(e) => {
                                        this.setState({email: e.target.value})
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4} style={{padding : 10}}>
                                <span style={{fontSize: 16}}>Firstname : </span>

                            </Col>
                            <Col span={12}>
                                <Input
                                    value={this.state.firstname}
                                    size="medium"   
                                    style={{width: '100%', padding: 5, color: '#192a56',fontWeight: 700, textAlign: 'center' }}
                                    onChange={(e) => {
                                        this.setState({firstname: e.target.value})
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4} style={{padding : 10}}>
                             <span style={{fontSize: 16}}>Lastname : </span>

                            </Col>
                            <Col span={12}>
                                <Input
                                    value={this.state.lastname}
                                    size="medium"   
                                    style={{width: '100%', padding: 5, color: '#192a56',fontWeight: 700, textAlign: 'center' }}
                                    onChange={(e) => {
                                        this.setState({lastname: e.target.value})
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4} style={{padding : 10}}>
                            <span style={{fontSize: 16}}>Zipcode : </span>

                            </Col>
                            <Col span={12}>
                                <Input
                                    value={this.state.zipcode}
                                    size="medium"   
                                    style={{width: '100%', padding: 5, color: '#192a56',fontWeight: 700, textAlign: 'center' }}
                                    onChange={(e) => {
                                        this.setState({zipcode: e.target.value})
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4} style={{padding : 10}}>
                            <span style={{fontSize: 16}}>Civility : </span>

                            </Col>
                            <Col span={12}>
                                <Input
                                    value={this.state.civility}
                                    size="medium"   
                                    style={{width: '100%', padding: 5, color: '#192a56',fontWeight: 700, textAlign: 'center' }}
                                    onChange={(e) => {
                                        this.setState({civility: e.target.value})
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4} style={{padding : 10}}>
                            <span style={{fontSize: 16}}>Birthday : </span>

                            </Col>
                            <Col span={12}>
                                <Input
                                    value={this.state.birthday}
                                    size="medium"   
                                    style={{width: '100%', padding: 5, color: '#192a56',fontWeight: 700, textAlign: 'center' }}
                                    onChange={(e) => {
                                        this.setState({birthday: e.target.value})
                                    }}
                                />
                            </Col>
                        </Row>
                        <br/>
                        <div style={{display: `${this.state.displayAdmin}`}}>
                            <div style={{display: `${this.state.displayUpdate}`}}>
                                <Row>
                                    <Col span={4}></Col>
                                    <Col span={5}>
                                        <Button
                                            loading={this.state.loadingUpdate}
                                            style={{width: '80%',height: 50, textAlign: 'center', fontSize: 16}}
                                            className="btn btn-warning"
                                            onClick={this.onUpdateRouter.bind(this)}
                                        >
                                            UPDATE
                                        </Button>
                                    </Col>
                                    <Col span={5}>
                                        <Button
                                            loading={this.state.loadingDisable}
                                            style={{width: '80%', height: 50,textAlign: 'center', fontSize: 16}}
                                            className="btn btn-danger"
                                            onClick={this.onDisableRouter.bind(this)}
                                        >
                                            DISABLE
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{display: `${this.state.displayAdd}`}}>
                                    <Row>
                                        <Col span={4}></Col>
                                        <Col span={5}>
                                            <Button
                                                style={{width: '80%',height: 50, textAlign: 'center', fontSize: 16}}
                                                className="btn btn-info"
                                            >
                                                Cancel
                                            </Button>
                                        </Col>
                                        <Col span={5}>
                                            <Button
                                                loading={this.state.loadingAdd}
                                                style={{width: '80%', height: 50,textAlign: 'center', fontSize: 16}}
                                                className="btn btn-primary"
                                                onClick={this.onAddRouter.bind(this)}
                                            >
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Router
