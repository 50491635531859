import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { base64_encode, base64_decode, generateKey } from '../Utilities/Utilities';
import axios from 'axios';
import { Row, Col, Button, Select, message } from 'antd';
import { list_user } from '../Utilities/User';
import * as config from '../config/config'

const { Option } = Select
class Toolso extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_id: base64_decode(localStorage.getItem(base64_encode('user_id'))),
            username: base64_decode(localStorage.getItem(base64_encode('username'))),
            apikey: base64_decode(localStorage.getItem(base64_encode('apikey'))),
            stats_apikey: base64_decode(localStorage.getItem(base64_encode('stats_apikey'))),
            liste_upload: [],
            liste_user: [],
            liste_sftp_file: [],
            loading_refresh: false,
            loadingupload: false,
            upload_filename: null,
            upload_type: 'upload'

        }
    }

    componentDidMount() {
        this.onGetListUpload()
        this.ongetListUser()
        this.onGetListContactFileSftp()
    }

    onGetListUpload() {
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        axios.post(config.REACT_APP_ENDPOINT_TOOLSO_LISTE_UPLOADED, fd)
            .then(res => {
                console.log(res.data)
                this.setState({
                    liste_upload: res.data['data']
                })
            })
    }

    onGetListContactFileSftp() {
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        axios.post(config.REACT_APP_ENDPOINT_TOOLSO_LISTE_SFTP, fd)
            .then(res => {
                this.setState({ liste_sftp_file: res.data['data'] })
            })
    }

    ongetListUser() {
        Promise.resolve(list_user(this.state.user_id, this.state.apikey).then(res => {
            this.setState({ liste_user: res })
        }))
    }

    onRefreshList() {
        this.setState({ loading_refresh: true, liste_upload: [], loadingupload: false,  })
        setTimeout(() => {
            this.onGetListUpload()
            this.onGetListContactFileSftp()
            this.setState({ loading_refresh: false, loadingupload: false })
        }, 2000)
    }

    onUploadSftpFile() {
        // this.setState({ loadingupload: true })
        if (this.state.upload_filename === null) {
            message.error('Please choose file !!!', 2)
            this.setState({ loadingupload: false })
            return null
        } else {
            var fd = new FormData()
            fd.append('user_id', this.state.user_id)
            fd.append('apikey', this.state.apikey)
            fd.append('filename', this.state.upload_filename)
            fd.append('type', this.state.upload_type)
            axios.post(config.REACT_APP_ENDPOINT_TOOLSO_CREATE_CONTACT, fd)
                .then(res => {
                    // setTimeout(() => {
                    //     message.success('Uploading contact in progress !!',2)
                    //     window.location.reload()
                    // }, 2000)
                })
        }
    }

    render() {
        return (
            <div>
                <Row>
                    <Col span={14}>
                        <Row>
                            <Col span={12} style={{ textAlign: 'center' }}>
                                <Button
                                    loading={this.state.loading_refresh}
                                    className='btn btn-primary'
                                    style={{ width: '50%' }}
                                    onClick={this.onRefreshList.bind(this)}
                                >
                                    REFRESH
                                </Button>
                            </Col>
                            <Col span={12}></Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={24}>
                                <div style={{ width: '100%', height: '70vh', overflowX: 'hidden', overflowY: 'auto' }}>
                                    <table className="table table-bordered">
                                        <thead className="thead-dark">
                                            <tr style={{ textAlign: 'center' }}>
                                                <th style={{ textAlign: 'center' }}>Id</th>
                                                <th style={{ textAlign: 'center' }}>Creation date</th>
                                                <th style={{ textAlign: 'center' }}>Filename</th>
                                                <th style={{ textAlign: 'center' }}>User</th>
                                                <th style={{ textAlign: 'center' }}>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.liste_upload && this.state.liste_upload.map(item => {
                                                    var key = generateKey()
                                                    var background = '#FFFFCC'
                                                    if (item['status'] === 'Finished') {
                                                        background = '#CCFFCC'
                                                    }
                                                    return (
                                                        <tr key={key} style={{ background: `${background}` }}>
                                                            <td style={{ textAlign: 'center' }}>{item['id']}</td>
                                                            <td style={{ textAlign: 'center' }}>{item['date_creation']}</td>
                                                            <td style={{ textAlign: 'center' }}>{item['filename']}</td>
                                                            <td style={{ textAlign: 'center' }}>
                                                                {
                                                                    this.state.liste_user && this.state.liste_user.map(elem => {
                                                                        if (parseInt(elem['id']) == parseInt(item['user_id'])) {
                                                                            return (
                                                                                <p> {elem['username']}</p>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </td>
                                                            <td style={{ textAlign: 'center' }}>{item['status']}</td>

                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>

                            </Col>
                        </Row>
                    </Col>

                    <Col span={10} style={{ textAlign: 'center' }}>
                        <Row>
                            <div style={{ width: '80%', border: '1px solid #001529', margin: '0 auto', height: '35vh', marginTop: '7%' }}>
                                <div style={{ background: '#001529', height: 40, width: '100%', color: "#fff", textAlign: 'center', padding: 8 }}>
                                    <span style={{ fontSize: 16 }}><strong>CONTACT MANAGEMENT</strong></span>
                                </div>
                                <div>
                                    <Row>
                                        <Col span={7} style={{ paddingTop: '15px', textAlign: 'left', marginLeft: 8}}>
                                            <span style={{textDecoration: 'underline'}}>Path directory at SFTP:</span>
                                        </Col>
                                        <Col span={14} style={{ paddingTop: '15px', textAlign: 'left'}}>
                                            <strong>/ftp_down/deliv/Toolso</strong>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={7} style={{ paddingTop: '15px', textAlign: 'left', marginLeft: 8 }}>
                                            <span style={{textDecoration: 'underline'}}>Available fields name :</span>
                                        </Col>
                                        <Col span={14} style={{ paddingTop: '15px', textAlign: 'left', marginLeft: 8 }}>
                                            <strong>birthdate, firstname, lastname, city_ville, zipcode, adresse1, mobile, civility</strong>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={4} style={{ paddingTop: '15px' }}>
                                            <span>Choose File</span>
                                        </Col>
                                        <Col span={18} style={{ padding: '10px' }}>
                                            <Select
                                                size="medium"
                                                showSearch
                                                optionFilterProp="children"
                                                style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                                value={this.state.upload_filename}
                                                onChange={(value) => { this.setState({ upload_filename: value }) }}
                                            >
                                                {
                                                    this.state.liste_sftp_file && this.state.liste_sftp_file.map(item => {
                                                        var key = generateKey()
                                                        return (
                                                            <Option key={key} value={item}>{item}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col span={4} style={{ paddingTop: '15px' }}>
                                            <span>Type</span>
                                        </Col>
                                        <Col span={18} style={{ padding: '10px' }}>
                                            <Select
                                                size="medium"
                                                showSearch
                                                optionFilterProp="children"
                                                style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                                value={this.state.upload_type}
                                                onChange={(value) => {this.setState({upload_type: value})}}
                                            >
                                                <Option value="upload">UPLOAD</Option>
                                                <Option value="blacklist">BLACKLIST</Option>
                                                <Option value="unsuscribe">UNSUSCRIBE</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col span={4}></Col>
                                        <Col span={18} style={{ padding: 10 }}>
                                            <Button
                                                loading={this.state.loadingupload}
                                                className='btn btn-success'
                                                style={{ width: '50%  ' }}
                                                onClick={this.onUploadSftpFile.bind(this)}
                                            >
                                                SUBMIT
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                                
                            </div>
                        </Row>


                    </Col>
                </Row>
            </div>
        );
    }
}

export default Toolso;