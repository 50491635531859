
import React, { useState, useEffect, useRef } from 'react';
import { Card, Row, Col, Pagination, Select, Input, Button, DatePicker } from 'antd';
import { generateKey, base64_encode, base64_decode } from '../Utilities/Utilities'
import { base_user } from '../Utilities/User';
import axios from 'axios';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { filterCrea, filterCreaDetails } from '../Utilities/Crea';
import '../../css/Minisitev2.css';
import moment from 'moment';

const { Meta } = Card;
const { Option } = Select

const MINISITE_URL = (db) => {
  return "https://" + db + "/traffic.php";
}

const Notification = ({ message, onClose, type }) => {
  return (
    <div className={type === "danger" ? "box-notif box-notif-danger" : "box-notif"}>
      <p>{message}</p>
      <span onClick={onClose}>&times;</span>
    </div>
  );
};

const NotificationContainer = ({setNotification, notification, type = "success"}) => {
  

  const closeNotification = (index) => {
    setNotification(notifs =>
      notifs.filter((_, i) => i !== index)
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification(notifs => notifs.slice(1));
    }, 5000);
    return () => clearTimeout(timer);
  }, [notification]);

  return (
    <div className="box-notif-container">
      {notification.map((notif, index) => (
        <Notification
          key={index}
          message={notif.message}
          onClose={() => closeNotification(index)}
          type={type}
        />
      )).reverse()}
    </div>
  );
};

const Minisitev2 = () => {
    // Views
    const [cardViews, setCardViews] = useState(false)

    // Notification
    const [notification, setNotification] = useState([]);
    const [typeNotif, setTypeNotif] = useState('');
    const addNotification = (message) => {
      setNotification(notifs => [
        ...notifs,
        { message }
      ]);
    };

    // Utils
    const [images, setImages] = useState(["https://picsum.photos/id/1018/1000/600/", "https://picsum.photos/id/1015/1000/600/", "https://picsum.photos/id/1019/1000/600/"])
    const [listeDatabase, setlisteDatabase] = useState([])
    const [listeCreativities, setListeCreativities] = useState([])
    const [country, setCountry] = useState('');

    const labelAccueil = (country) => {
      const labels = {
        'FR': 'Accueil',
        'EN': 'Home',
        'AU': 'Home',
        'IT': 'Pagina iniziale',
        'ES': 'Accueil',
        'PLN': 'Accueil',
        'GB': 'Accueil',
        'NL': 'Accueil'
      };
      return labels[country] || 'Accueil';
    };

    const getCreativities = async (db) => {
      try {
        var user_id = base64_decode(localStorage.getItem(base64_encode('user_id')))
        var apikey = base64_decode(localStorage.getItem(base64_encode('apikey')))
        var res = []
        listeDatabase?.map(item => {
          if (item.url == db) {
            res.push((item.id).toString())
          }
        })
        
        Promise.resolve(filterCrea(user_id, apikey, "database", JSON.stringify(res), "250").then((value) => {
          setListeCreativities(value)
        }))
        
      } catch (error) {
        setNotification("Une erreur s'est produite lors de la récupération des données");
        setTypeNotif("danger")
        console.error('Une erreur s\'est produite lors de la récupération des données :', error);
      }
    }

    useEffect(() => {
      var user_id = localStorage.getItem(base64_encode('user_id'))
      var apikey = localStorage.getItem(base64_encode('apikey'))
      Promise.resolve(base_user(user_id, apikey).then((value) => {
        setlisteDatabase(value)
      }))
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Form
    const [database, setDatabase] = useState(null)
    const [vignette_status, setVignette_status] = useState('')
    const [crea_id, setCrea_id] = useState('')
    const [creativity, setCreativity] = useState('')
    const [object, setObject] = useState('')
    const [image_url, setImageurl] = useState('')
    const [links, setLinks] = useState('')
    const [endDate, setEndDate] = useState(null)

    const [index, setIndex] = useState('');

    const handleChangeDatabase = (value) => {
      setDatabase(value)
      getVignetteListe(value)
      getCreativities(value)
    };

    const handleChangeCreativities = async (creaId) => {
      try {
        var user_id = base64_decode(localStorage.getItem(base64_encode('user_id')))
        var apikey = base64_decode(localStorage.getItem(base64_encode('apikey')))
        setCrea_id(creaId)
        let baseid = [];
        console.log(listeDatabase)
        listeDatabase?.forEach(item => {
          if (item.url === database) {
            baseid.push((item.id).toString());
          }
        });
        console.log('-----------------------')
        console.log(baseid[0])
        console.log('-----------------------')

        Promise.resolve(filterCreaDetails(user_id, apikey, creaId, baseid[0]).then((value) => {
          setCreativity(value.brand ?? '')
          setObject(value.subject ? base64_decode(value.subject) : '')
          setLinks(value.links.length > 0 ? value.links[0] : '')
          setImages(value.images ?? ["https://picsum.photos/id/1018/1000/600/", "https://picsum.photos/id/1015/1000/600/", "https://picsum.photos/id/1019/1000/600/"])
          setEndDate(value.timestamp ?? null)
        }))
      } catch (error) {
        addNotification("Une erreur s'est produite lors de la récupération des données");
        setTypeNotif("danger")
        console.error('Une erreur s\'est produite lors de la récupération des données :', error);
      }
    }

    // Vignette paginated
    const [cards, setCards] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 12;
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const displayedCards = cards?.slice(startIndex, endIndex);

    const getVignetteListe = async (url_base) => {
      try {
        let url = MINISITE_URL(url_base)
        
        var user_id = base64_decode(localStorage.getItem(base64_encode('user_id')))
        var apikey = base64_decode(localStorage.getItem(base64_encode('apikey')))

        const response = await axios.get(url)

        if(response.data.items.length > 0) {
          setCards(response.data.items)
          setVignette_status(response.data.status)
          setCountry(response.data.country)
        } else {
          setVignette_status('')
        }
        
      } catch (error) {
        setNotification("Une erreur s'est produite lors de la récupération des données");
        setTypeNotif("danger")
        console.error('Une erreur s\'est produite lors de la récupération des données :', error);
      }
    }
    
    const handleChangePage = (page) => {
      setCurrentPage(page);
    };

    const toggleVignetteListVisibility = (database) => {
      const formData = {
        _method: "VIGNETTE_STATUS",
        status: vignette_status
      }
      if(database === null) {
        return;
      }else{
        axios.post(MINISITE_URL(database), formData)
      }
      
    }

    useEffect(() => {
      toggleVignetteListVisibility(database)
    }, [vignette_status])

    // Drag and Drop
    const detectLeftButton = (e) => {
      e = e || window.event;
      if("buttons" in e){
        return e.buttons === 1;
      }

      let button = e.which || e.button;
      return button === 1
    }
    
    const saveOrderedData = (data) => {
      const formData = {
        _method: "UPDATE_ORDER",
        data: data
      }
      axios.post(MINISITE_URL(database), formData)
    }
    
    const [isDragged, setIsDragged] = useState();
    const containerRef = useRef();

    const dragStart = (e, index) => {
      if(!detectLeftButton()) return;
      setIsDragged(index)
      const container = containerRef.current;
      const items = [...container.childNodes];
      const dragItem = items[index];
      const itemsBelowDragItem = items.slice(index + 1);
      const notDragItems = items.filter((_, i) => i !== index);
      const dragData = cards[index]
      let newData = [...cards];

      const dragRect = dragItem.getBoundingClientRect();
      dragItem.style.position = "fixed";
      dragItem.style.zIndex = 9999;
      dragItem.style.width = dragRect.width + "px";
      dragItem.style.height = dragRect.height + "px";
      dragItem.style.top = dragRect.top + "px";
      dragItem.style.left = dragRect.left + "px";
      dragItem.style.cursor = "grabbing";

      const div = document.createElement('div');
      div.id = "card-temp";
      div.style.width = dragRect.width + "px";
      div.style.height = dragRect.height + "px";
      div.style.pointerEvents = "none";
      container.appendChild(div);

      const distance = dragRect.height
      itemsBelowDragItem.forEach(item => {
        item.style.transform = `translate3D(0, ${distance}px, 0)`;
      })

      let x = e.clientX;
      let y = e.clientY;
      const dragMove = (e) => {
        const px = e.clientX - x;
        const py = e.clientY - y;

        //scroll
        const windowHeight = window.innerHeight;
        const scrollThreshold = 100;
        const scrollSpeed = 5;
        let scrollDirection = 0;

        if (e.clientY < scrollThreshold) {
          scrollDirection = -1;
        } else if (windowHeight - e.clientY < scrollThreshold) {
          scrollDirection = 1;
        }

        if (scrollDirection !== 0) {
          containerRef.current.scrollTop += scrollDirection * scrollSpeed;
        }

        dragItem.style.transform = `translate3D(${px}px, ${py}px, 0)`;

        notDragItems.forEach(item => {
          const rect1 = dragItem.getBoundingClientRect()
          const rect2 = item.getBoundingClientRect()

          let isOverlapping = rect1.y < rect2.y + (rect2.height / 2) && rect1.y + (rect1.height / 2) > rect2.y;
          if(isOverlapping) {
            if(item.getAttribute("style")){
              item.style.transform = ""
              index++
            }else{
              item.style.transform = `translate3D(0, ${distance}px, 0)`;
              index--
            }

            newData = cards.filter(item => item.index !== dragData.index);
            newData.splice(index, 0 , dragData);
          }
        })
      }
      document.onpointermove = dragMove;
      
      const dragEnd = () => {
        
        document.onpointerup = '';
        document.onpointermove = ''
        setIsDragged(undefined);
        dragItem.style = '';
        container.removeChild(div)
        items.forEach(item => item.style = "")
        setCards(newData)
        saveOrderedData(newData)
        getVignetteListe(database)
      }

      document.onpointerup = dragEnd;
    };

    // Edit Vignette
    const handleClickEditVignette = (card) => {
      
      setImageurl(card.image_url)
      setLinks(base64_decode(card.links))
      setObject(base64_decode(card.object))
      setCreativity(base64_decode(card.creativity))
      setImages([card.image_url])
      setEndDate(card.timestamp)
      setCrea_id(card.crea_id)
      setIndex(card.index)
  
    }

    // Submit (Edit Vignette or New Vignette)
    const handleSubmit = async () => {
      try {
        const formData = {
          index: index,
          creativity: creativity,
          object: object,
          crea_id: crea_id,
          image_url: image_url,
          links: links,
          timestamp: endDate
        };
        
        const response = await axios.post(MINISITE_URL(database), formData);
        
        setImageurl('')
        setLinks(base64_decode(''))
        setObject(base64_decode(''))
        setCreativity(base64_decode(''))
        setImages(["https://picsum.photos/id/1018/1000/600/", "https://picsum.photos/id/1015/1000/600/", "https://picsum.photos/id/1019/1000/600/"])
        setIndex('')
        setEndDate('')
        getVignetteListe(database)
        
        addNotification(response.data.message);
        setTypeNotif(response.data.type)
      } catch (error) {
        addNotification("Une erreur s'est produite lors de l'enregistrement des données");
        setTypeNotif("danger")
        console.error('Une erreur s\'est produite lors de l\'enregistrement des données :', error);
      }
    }

    // Delete Vignette
    const handleClickDeleteVignette = async (card) => {
      try {
        const formData = {
          _method: 'DELETE',
          index: card.index,
          creativity: card.creativity,
          object: card.object,
          crea_id: card.crea_id,
          image_url: card.image_url,
          links: card.links,
          timestamp: card.endDate
        };
        
        const response = await axios.post(MINISITE_URL(database), formData);
        
        setImageurl('')
        setLinks(base64_decode(''))
        setObject(base64_decode(''))
        setCreativity(base64_decode(''))
        setImages(["https://picsum.photos/id/1018/1000/600/", "https://picsum.photos/id/1015/1000/600/", "https://picsum.photos/id/1019/1000/600/"])
        setIndex('')
        setEndDate('')
        getVignetteListe(database)
        addNotification(response.data.message);
        setTypeNotif(response.data.type);
        
      } catch (error) {
        addNotification("Une erreur s'est produite lors de la suppréssion des données");
        setTypeNotif("danger")
        console.error('Une erreur s\'est produite lors de la suppréssion des données :', error);
      }
    }

  return (
    <div style={{ width: '100%', margin: 1, overflowX: 'hidden' }}>
      <Row>
        <Col span={10} style={{ padding: 15 }}>
          <div style={{ width: '100%', textAlign: 'center', height: 30, background: '#23bebc', padding: 5 }}>
            <h4 style={{ color: '#fff' }}><strong>TRAFFIC MANAGEMENT</strong></h4>
          </div>
          <br />
          <Row>
            <Col span={3} style={{ padding: 8 }}>
              <span>Database:</span>
            </Col>
            <Col span={10}>
              <Select
                size="medium"
                showSearch
                optionFilterProp="children"
                style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                value={database}
                onChange={handleChangeDatabase}
              >
                {
                  listeDatabase !== null && listeDatabase.map((item) => {
                    var key = generateKey()
                    return (
                      <Option key={key} value={item.url}>{item.basename}</Option>
                      //<Option key={key} value="localhost:5030">{item.basename}</Option>
                    )
                  })
                }
              </Select>
            </Col>
            <Col span={3} style={{ padding: 8 }}>
              <span>Traffic:</span>
            </Col>
            <Col span={8}>
              <Select
                size="medium"
                showSearch
                optionFilterProp="children"
                style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                value={vignette_status}
                onChange={(value) => { setVignette_status(value) }}
              >
                <Option key="active" value={labelAccueil(country)}>Enable</Option>
                <Option key="inactive" value="">Disable</Option>
              </Select>
            </Col>
          </Row>
          
          <Row>
            <Col span={3} style={{ padding: 8 }}>
              <span>Crea:</span>
            </Col>
            <Col span={16}>
              <Select
                size="medium"
                showSearch
                optionFilterProp="children"
                style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                value={crea_id}
                onChange={handleChangeCreativities}
              >
                {
                  listeCreativities !== null && listeCreativities.map((item) => {
                    var key = generateKey()
                    return (
                      <Option key={key} value={parseInt(item.id)}>{item.id}- {item.creativity}</Option>
                    )
                  })
                }
              </Select>
            </Col>

          </Row>
          <Row>
            <Col span={3} style={{ padding: 8 }}>
              <span>Brand:</span>
            </Col>
            <Col span={10}>
              <Input
                value={creativity}
                onChange={(e) => { setCreativity(e.target.value) }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={3} style={{ padding: 8 }}>
              <span>Subject:</span>
            </Col>
            <Col span={21}>
              <Input
                value={object}
                onChange={(e) => { setObject(e.target.value) }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={3} style={{ padding: 8 }}>
              <span>Links:</span>
            </Col>
            <Col span={21}>
              <Input
                value={links}
                onChange={(e) => { setLinks(e.target.value) }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={3} style={{ padding: 8 }}>
              <span>End date:</span>
            </Col>
            <Col span={21}>
            <DatePicker
              showTime={{ format: 'HH:mm:ss' }}
              format="YYYY-MM-DD HH:mm:ss"
              onChange={(date) => setEndDate(date)}
              value={endDate ? moment(endDate, 'YYYY-MM-DD HH:mm:ss') : null}
            />
            </Col>
          </Row>
          <Row>
            <Col span={3} style={{ padding: 8 }}>
              <span>Images:</span>
            </Col>
            <Col span={21}>
              <Input
                value={image_url}
                onChange={(e) => { setImageurl(e.target.value) }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ padding: 8 }}>
              <Carousel 
                style={{ border: '5px solid red' }} 
                onClickThumb={(index, item) => setImageurl(item.props.src)}
                onClickItem={(index, item) => setImageurl(item.props.src)}
                selectedItem={image_url}
              >
                {images?.map((url, index) => (
                  <div key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400 }}>
                    <img src={url} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                  </div>
                ))}
              </Carousel>

            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ padding: 8, textAlign: 'center' }}>
              <Button
                className='btn btn-warning'
                style={{ width: '60%' }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={12} style={{ padding: 8, textAlign: 'center' }}>
              <Button
                className='btn btn-success'
                style={{ width: '60%' }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={14}>
          
          <div style={{ width: '100%', margin: '15px auto 0', height: '100%', position: 'relative'}}>
            <div className="box-switch" onClick={() => setCardViews(!cardViews)} title="Changer l'affichage">
              {
                database && cardViews ? <svg fill="#000000" height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 487.3 487.3">
              <g>
                <g>
                  <path d="M487.2,69.7c0,12.9-10.5,23.4-23.4,23.4h-322c-12.9,0-23.4-10.5-23.4-23.4s10.5-23.4,23.4-23.4h322.1
                    C476.8,46.4,487.2,56.8,487.2,69.7z M463.9,162.3H141.8c-12.9,0-23.4,10.5-23.4,23.4s10.5,23.4,23.4,23.4h322.1
                    c12.9,0,23.4-10.5,23.4-23.4C487.2,172.8,476.8,162.3,463.9,162.3z M463.9,278.3H141.8c-12.9,0-23.4,10.5-23.4,23.4
                    s10.5,23.4,23.4,23.4h322.1c12.9,0,23.4-10.5,23.4-23.4C487.2,288.8,476.8,278.3,463.9,278.3z M463.9,394.3H141.8
                    c-12.9,0-23.4,10.5-23.4,23.4s10.5,23.4,23.4,23.4h322.1c12.9,0,23.4-10.5,23.4-23.4C487.2,404.8,476.8,394.3,463.9,394.3z
                    M38.9,30.8C17.4,30.8,0,48.2,0,69.7s17.4,39,38.9,39s38.9-17.5,38.9-39S60.4,30.8,38.9,30.8z M38.9,146.8
                    C17.4,146.8,0,164.2,0,185.7s17.4,38.9,38.9,38.9s38.9-17.4,38.9-38.9S60.4,146.8,38.9,146.8z M38.9,262.8
                    C17.4,262.8,0,280.2,0,301.7s17.4,38.9,38.9,38.9s38.9-17.4,38.9-38.9S60.4,262.8,38.9,262.8z M38.9,378.7
                    C17.4,378.7,0,396.1,0,417.6s17.4,38.9,38.9,38.9s38.9-17.4,38.9-38.9C77.8,396.2,60.4,378.7,38.9,378.7z"/>
                </g>
              </g>
              </svg> : <svg enableBackground="new 0 0 32 32" height="32px" id="Layer_1" version="1.1" viewBox="0 0 32 32" width="32px" xmlns="http://www.w3.org/2000/svg">
                  <g id="grid-2">
                      <path d="M10.246,4.228c0-0.547-0.443-0.991-0.99-0.991H3.914c-0.548,0-0.991,0.443-0.991,0.991V9.57   c0,0.546,0.443,0.99,0.991,0.99h5.342c0.547,0,0.99-0.444,0.99-0.99V4.228z" fill="#515151"/><path d="M19.453,4.228c0-0.547-0.443-0.991-0.991-0.991h-5.343c-0.546,0-0.99,0.443-0.99,0.991V9.57   c0,0.546,0.444,0.99,0.99,0.99h5.343c0.548,0,0.991-0.444,0.991-0.99V4.228z" fill="#515151"/><path d="M28.868,4.228c0-0.547-0.443-0.991-0.99-0.991h-5.342c-0.548,0-0.991,0.443-0.991,0.991V9.57   c0,0.546,0.443,0.99,0.991,0.99h5.342c0.547,0,0.99-0.444,0.99-0.99V4.228z" fill="#515151"/><path d="M10.246,13.224c0-0.547-0.443-0.99-0.99-0.99H3.914c-0.548,0-0.991,0.443-0.991,0.99v5.342   c0,0.549,0.443,0.99,0.991,0.99h5.342c0.547,0,0.99-0.441,0.99-0.99V13.224z" fill="#515151"/><path d="M19.453,13.224c0-0.547-0.443-0.99-0.991-0.99h-5.343c-0.546,0-0.99,0.443-0.99,0.99v5.342   c0,0.549,0.444,0.99,0.99,0.99h5.343c0.548,0,0.991-0.441,0.991-0.99V13.224z" fill="#515151"/><path d="M28.868,13.224c0-0.547-0.443-0.99-0.99-0.99h-5.342c-0.548,0-0.991,0.443-0.991,0.99v5.342   c0,0.549,0.443,0.99,0.991,0.99h5.342c0.547,0,0.99-0.441,0.99-0.99V13.224z" fill="#515151"/><path d="M10.246,22.43c0-0.545-0.443-0.99-0.99-0.99H3.914c-0.548,0-0.991,0.445-0.991,0.99v5.344   c0,0.547,0.443,0.99,0.991,0.99h5.342c0.547,0,0.99-0.443,0.99-0.99V22.43z" fill="#515151"/><path d="M19.453,22.43c0-0.545-0.443-0.99-0.991-0.99h-5.343c-0.546,0-0.99,0.445-0.99,0.99v5.344   c0,0.547,0.444,0.99,0.99,0.99h5.343c0.548,0,0.991-0.443,0.991-0.99V22.43z" fill="#515151"/><path d="M28.868,22.43c0-0.545-0.443-0.99-0.99-0.99h-5.342c-0.548,0-0.991,0.445-0.991,0.99v5.344   c0,0.547,0.443,0.99,0.991,0.99h5.342c0.547,0,0.99-0.443,0.99-0.99V22.43z" fill="#515151"/>
                  </g>
              </svg>
              }

            </div>
            
            <div className='box-container'>

              {
                cardViews && <>
                  <div className="box-card-container" >
                    {displayedCards?.map((card, index) => <div className={`box-card ${moment().isSameOrAfter(moment(card.timestamp)) ? 'box-card-disabled' : ''}`} key={index}>
                            <div className='box-card-content'>
                                <div className="box-card-img">
                                    <img src={card.image_url} alt=""/>
                                    <div className="form-group-action">
                                      <div className="form-action" onClick={() => handleClickEditVignette(card)}>&#x270E;</div>
                                      <div className="form-action" onClick={() => handleClickDeleteVignette(card)}>&times;</div>
                                    </div>
                                </div>
                                <div className="box-card-body">
                                    <h5>{base64_decode(card.creativity)}</h5>
                                    <p>{base64_decode(card.object)}</p>
                                </div>
                            </div>
                        </div>
                    )}
                  </div>
                  <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    total={cards?.length}
                    onChange={handleChangePage}
                    style={{ marginTop: '1rem', textAlign: 'center' }}
                    hideOnSinglePage={true}
                  />
                </>
              }
              {
                !cardViews && <div className="box-list-container" >
                  <ul ref={containerRef}>
                  {cards?.map((card, index) => <li className={`${isDragged === index ? 'is-dragged' : ''}`} data-position={index} key={index} onPointerDown={e => dragStart(e, index)}>
                        <figure>
                          <img src={card.image_url} alt=""/>
                        </figure>
                        <h5>
                          {card.index}  -  {base64_decode(card.creativity)}
                          {moment().isSameOrAfter(moment(card.timestamp)) ? <span className='box_alert box_alert_inactive'>Inactive</span> : <span className='box_alert box_alert_active'>Active</span>}
                          
                        </h5>
                        <ul className='box_list'>
                          <li><p>{base64_decode(card.object)}</p></li>
                        </ul>
                        <ul className='box_buttons'>
                          <li><div className="box_button box_button_edit" onClick={() => handleClickEditVignette(card)}>&#x270E; Editer</div></li>
                          <li><div className="box_button box_button_delete" onClick={() => handleClickDeleteVignette(card)}>&times; Supprimer</div></li>
                        </ul>
                    </li>
                )}
                  </ul>
                
              </div>
              }
              {
                !vignette_status && <div className='box-overlay'>
                  <h2>Cette fonctionnalité est désactivée</h2>
                </div>
              }
            </div>

          </div>

          <NotificationContainer setNotification={setNotification} notification={notification} type={typeNotif}/>
        </Col>
      </Row>
    </div>
  );
};

export default Minisitev2;